import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";

const TextToolbar = ({
  font,
  setFont,
  styles,
  belowContent,
  isDefault,
  setDefault,
  hide,
  alignment,
}) => {
  const [picker, setPicker] = useState(false);
  const inputRef = useRef(null);

  const colorPickerRef = useRef(null);
  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
  });

  const mainDivRef = useRef(null);
  useOutsideClick(mainDivRef, () => {
    hide && hide();
  });

  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    const updatedFont = {
      ...font,
      family: selectedFont,
      isDefaultFont: selectedFont === "default" ? 1 : 0,
    };
    setFont(updatedFont);
  };

  const handleAlignmentChange = (e) => {
    const selectedAlignment = e.target.value;
    const updatedFont = {
      ...font,
      direction: selectedAlignment,
    };
    setFont(updatedFont);
  };

  const incrementFontSize = () => {
    const updatedFont = { ...font, size: Number(font.size) + 1 };
    setFont(updatedFont);
  };

  const decrementFontSize = () => {
    const updatedFont = { ...font, size: Math.max(font.size - 1, 1) };
    setFont(updatedFont);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
    const updatedFont = { ...font, size: parseInt(value === "" ? 0 : value) };
    setFont(updatedFont);
    // Set cursor position before 'px'
    inputRef.current.setSelectionRange(value.length, value.length);
  };

  return (
    <div
      ref={mainDivRef}
      style={styles}
      className={`textToolBar ${belowContent ? "topPointer" : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          <h4>Text Toolbar</h4>
        </div>
        <div className="toolBarRight">
          <div className="d-flex align-tems-center justify-content-center ">
            <button className="counterBtn" onClick={decrementFontSize}>
              -
            </button>
            <input
              ref={inputRef}
              onChange={handleInputChange}
              className="fontCounter"
              type="text"
              value={`${font.size}px`}
            />
            <button className="counterBtn" onClick={incrementFontSize}>
              +
            </button>
          </div>
          <div className="fontList">
            <div className="fillselect">
              <select
                style={{ minWidth: 80 }}
                value={font.isDefaultFont == 1 ? "default" : font.family}
                onChange={handleFontChange}
              >
                <option value="default">Default</option>
                <option value="gilroymedium">Gilroy</option>
                <option value="frontageregular">Frontage</option>
                <option value="playfair display">Playfair Display</option>
                <option value="rockwellregular">Rockwell</option>
              </select>
            </div>
          </div>
          {alignment && (
            <div className="fontList">
              <div className="fillselect">
                <select
                  style={{ minWidth: 80 }}
                  value={font.direction}
                  onChange={handleAlignmentChange}
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            </div>
          )}
          <div
            onClick={() => {
              const updatedFont = { ...font, isBold: !font.isBold };
              setFont(updatedFont);
            }}
            className="boldIcon"
            style={{
              backgroundColor: font.isBold ? "dimgrey" : "",
              borderRadius: 6,
            }}
          >
            <img src={Assets.boldbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              const updatedFont = { ...font, isItalic: !font.isItalic };
              setFont(updatedFont);
            }}
            style={{
              backgroundColor: font.isItalic ? "dimgrey" : "",
              borderRadius: 6,
            }}
            className="italicIcon"
          >
            <img src={Assets.italicbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              const updatedFont = { ...font, isUnderlined: !font.isUnderlined };
              setFont(updatedFont);
            }}
            style={{
              backgroundColor: font.isUnderlined ? "dimgrey" : "",
              borderRadius: 6,
            }}
            className="TIcon"
          >
            <img src={Assets.Tbutton} alt="Edit Image" />
          </div>
          <div
            style={{
              backgroundColor: isDefault ? "dimgrey" : "",
              borderRadius: 4,
              padding: 4,
            }}
            onClick={() => {
              setDefault && setDefault(isDefault == 0 ? 1 : 0);
            }}
            className="linkIcon"
          >
            <img
              src={Assets.defaultIcon}
              style={{ height: 26, width: 26 }}
              alt="default Image"
            />
          </div>
          <div onClick={() => setPicker(!picker)} className="colorCircle">
            <span
              className="white"
              style={{ backgroundColor: font.color ? font.color : "ffffff" }}
            />
          </div>
        </div>
      </div>
      {picker && (
        <SketchPicker
          ref={colorPickerRef}
          className="colorPicker"
          color={font.color}
          onChangeComplete={(color) => {
            const updatedFont = { ...font, color: color.hex };
            setFont(updatedFont);
          }}
          // styles={{ default: { picker: { zoom: "125%" } } }}
        />
      )}
    </div>
  );
};

export default TextToolbar;
