import React, { useState, useEffect, useRef, useCallback } from "react";
// import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
//Components
import ConnectWidget from "../../pages/Widgets/connectWidget";
import GivingWidget from "../../pages/Widgets/givingWidget";
import GroupWidget from "../../pages/Widgets/groupWidget";
import QuoteWidget from "../../pages/Widgets/quoteWidget";
import GroupWidget2 from "../../pages/Widgets/groupWidget2";
import JoinWidget from "../../pages/Widgets/joinWidget";
import VideoWidget from "../../pages/Widgets/videoWidget";
import "./styles.css";
import "../../pages/home/styles.css";
import EventListWidget from "../../pages/Widgets/eventListWidget";
import {
  setEdit,
  setStopDrag,
  setStyling,
  submitRequest,
  // setCustomWidgetLayout,
  updateHomePageOrder,
  updateTracker,
} from "../../store/slices/bodyStyling";
import CustomWidget from "../../pages/Widgets/customWidget";
import { postRequest } from "../../utills/requests";
import toast from "react-hot-toast";
import ColumnWidget from "../../pages/Widgets/columnWidget";
import RowWidget from "../../pages/Widgets/rowWidget";
import MobileGivingWidget from "../../pages/Widgets/MobileGivingWidget";
import MobileConnectWidget from "../../pages/MobileConnectWidget";
import MobileEventListWidget from "../../pages/Widgets/MobileEventListWidget";

const GridLayout = WidthProvider(RGL);

const MobileHomeGrid = ({ widgetsOrder, edithandleClick }) => {
  const dispatch = useDispatch();
  const widgetsData = useSelector((state) => state.bodyStyling.styling);
  const pageId = useSelector((state) => state.login.pageId);
  const styles = useSelector((state) => state.bodyStyling.universalStyles);
  const updatedOrder = useSelector((state) => state.bodyStyling.homePageOrder);
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const stopDrag = useSelector((state) => state.bodyStyling.stopDrag);

  const [widgets, setWidgets] = useState([]);
  useEffect(() => {
    if (widgetsOrder) {
      const newLayout = transformData(widgetsOrder);
      // const data = { newLayout, pageId: widgetsOrder[0].page_id };
      const data = { newLayout, pageId: pageId };
      data && dispatch(updateHomePageOrder(data));
      setWidgets(widgetsOrder);
    }
  }, [widgetsOrder]);

  const transformData = (data) => {
    return data.map(({ widget_id, x, y, w, h }) => {
      return {
        i: `${widget_id}`,
        x,
        y,
        w,
        h,
      };
    });
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update Widgets order
  const updateWidgetsOrder = (oldOrder, newOrder) => {
    const updatedArray = oldOrder.map((item1) => {
      const item2 = newOrder.find((item2) => item2.i == item1.widget_id);
      if (item2) {
        return { ...item1, x: item2.x, y: item2.y };
      } else {
        return item1;
      }
    });
    return updatedArray;
  };
  // DEL
  const deleteWidget = async (widgetId) => {
    const data = { page_id: pageId, widget_id: [widgetId] };
    try {
      dispatch(submitRequest(true));
      const resp = await postRequest({
        endpoint: "/delete-widget",
        payload: data,
      });
      const tempData = JSON.parse(JSON.stringify(widgetsData));
      if (resp.data.success) {
        const updatedWidgets = tempData.homepageOrder.filter(
          (item) => item.widget_id.toString() !== widgetId.toString()
        );
        const newOrder = updateWidgetsOrder(
          updatedWidgets,
          updatedOrder.newLayout
        );
        const newArray = { ...tempData, homepageOrder: newOrder };
        dispatch(setStyling(newArray));
        toast.success(resp.data.msg, { id: "delete" });
      } else {
        const updatedWidgets = tempData.homepageOrder.filter(
          (item) => item.widget_id.toString() !== widgetId.toString()
        );
        const newOrder = updateWidgetsOrder(
          updatedWidgets,
          updatedOrder.newLayout
        );
        const newArray = { ...tempData, homepageOrder: newOrder };
        dispatch(setStyling(newArray));
        // toast.error(resp.data.msg, { id: "delete" });
      }
    } catch (error) {
      console.log("DEL ERR>", error);
    } finally {
      dispatch(submitRequest(false));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setEdit({}));
    }
    setTimeout(() => {
      dispatch(setStopDrag("no"));
    }, 500);
  };

  const [playerHeight, setPlayerHeight] = useState(
    window.innerWidth > 2048 && window.innerWidth <= 2304 ? 6.5 : 5.5
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1940 && window.innerWidth > 1740) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth >= 1536 && window.innerWidth < 1707) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth == 1707) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth > 2304 && window.innerWidth <= 3702) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth == 2048) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth <= 1490 && window.innerWidth > 1291) {
        setPlayerHeight(5);
      } else if (window.innerWidth <= 1290 && window.innerWidth > 981) {
        setPlayerHeight(4);
      } else if (window.innerWidth <= 990 && window.innerWidth > 891) {
        setPlayerHeight(3);
      } else if (window.innerWidth <= 750 && window.innerWidth > 540) {
        setPlayerHeight(3);
      } else if (window.innerWidth <= 540 && window.innerWidth > 360) {
        setPlayerHeight(2);
      } else if (window.innerWidth <= 360) {
        setPlayerHeight(1.5);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRowHeight = () => {
    if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
      return 60;
    } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
      return 60;
    } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
      return 65;
    } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
      return 110;
    } else if (window.innerWidth > 2304 && window.innerWidth <= 3072) {
      return 140;
    } else if (window.innerWidth > 3072 && window.innerWidth <= 4608) {
      return 190;
    }
    return 60;
  };  

  const handleUpdateHomePageOrder = useCallback(
    (newData) => {
      const { newLayout, pageId } = newData;
      const updatedData = newLayout.map((item) => ({
        ...item,
        y: Math.floor(item.y),
      }));
      dispatch(updateHomePageOrder({ newLayout: updatedData, pageId }));
    },
    [dispatch]
  );
  
  // console.log("H>>", playerHeight);
  // console.log("WID>", window.innerWidth);
  return (
    <GridLayout
      onLayoutChange={(newLayout) => {
        setWidgets((prev) => {
          const newArray = prev.map((widget) => {
            const newX = newLayout.find((item) => item.i == widget.widget_id);
            return { ...widget, x: newX.x };
          });
          return newArray;
        });
      }}
      onDragStop={(newLayout) => {
        if (widgets.length > 0) {
          handleUpdateHomePageOrder({ newLayout, pageId });
        }
        dispatch(updateTracker(true));
      }}
      className="layout"
      // layout={layout} only needed if no data-grid is provided
      cols={1}
      // rowHeight={100}
      rowHeight={getRowHeight()}
      width={screenSize.width - screenSize.width * 0.37} // subtract 10%
      autoSize
      isBounded
      isResizable={false}
      allowOverlap={false}
      margin={styles.corners === "sharp" ? [0, 0] : [20, 20]}
      containerPadding={[0, 0]}
      isDraggable={draggable.isEditMode || stopDrag === "no" ? true : false}
    >
      {widgets &&
        widgets.map((item) => {
          return (
            <div
              style={{
                zIndex: item.widget_id == draggable.widgtId ? 3 : 2,
                position: "relative",
                width: "100%",
                // ...(item.widgetInfo.name === "player" && {
                //   overflow: "hidden",
                // }),
                ...(item.widgetInfo.name === "player" && {
                  borderRadius: styles.corners === "sharp" ? 0 : 20,
                }),
              }}
              key={item.widget_id}
              data-grid={{
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.widgetInfo.name === "player" ? 3 : item.h,
              }}
            >
              {item.widgetInfo.name === "player" ? (
                <VideoWidget
                  widgetId={Number(item.widget_id)}
                  deleteWidget={deleteWidget}
                />
              ) : // item.widget_id == "2"
              item.widgetInfo.name === "connect" ? (
                <MobileConnectWidget
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "3"
              item.widgetInfo.name === "giving" ? (
                <MobileGivingWidget
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "6"
              item.widgetInfo.name === "ourcode" ? (
                <QuoteWidget
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "4"
              item.widgetInfo.name === "map" ? (
                <GroupWidget
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "5"
              item.widgetInfo.name === "serve" ? (
                <GroupWidget2
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "7"
              item.widgetInfo.name === "groups" ? (
                <JoinWidget
                  widgetId={Number(item.widget_id)}
                  height={item.h}
                  width={item.w}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : // item.widget_id == "8"
              item.widgetInfo.name === "eventList" ? (
                <MobileEventListWidget
                  widgetId={Number(item.widget_id)}
                  itemStyles={item.widget_styles[0]}
                  edithandleClick={edithandleClick}
                  height={item.h}
                  width={item.w}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : item.widgetInfo.name === "column" ? (
                <ColumnWidget
                  widgetId={Number(item.widget_id)}
                  itemStyles={item.widget_styles[0]}
                  height={item.h}
                  width={item.w}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : item.widgetInfo.name === "row" ? (
                <RowWidget
                  widgetId={Number(item.widget_id)}
                  itemStyles={item.widget_styles[0]}
                  height={item.h}
                  width={item.w}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              ) : (
                <CustomWidget
                  widgetId={Number(item.widget_id)}
                  itemStyles={item.widget_styles[0]}
                  height={item.h}
                  width={item.w}
                  deleteWidget={deleteWidget}
                  x={item.x}
                />
              )}
            </div>
          );
        })}
    </GridLayout>
  );
};

export default MobileHomeGrid;
