import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DarkModeContext = createContext();

function DarkModeProvider(props) {
  const [darkMode, setDarkMode] = useState(true);
  const [borderCorners, setCorners] = useState("round");
  const themeMode = useSelector(
    (state) => state.bodyStyling.styling.universal_styles
  );
  const corners = useSelector(
    (state) => state.bodyStyling.styling.universal_styles
  );

  useEffect(() => {
    if (themeMode && themeMode.theme) {
      if (themeMode.theme === "light") {
        setDarkMode(false);
      }
    }
    if (corners && corners.corners) {
      setCorners(corners.corners);
    }
  }, [themeMode, corners]);

  const handleLightMode = () => setDarkMode(false);

  const handleDarkMode = () => setDarkMode(true);

  return (
    <div>
      <DarkModeContext.Provider
        value={{ darkMode, handleLightMode, handleDarkMode, borderCorners }}
      >
        {props.children}
      </DarkModeContext.Provider>
    </div>
  );
}

export { DarkModeContext, DarkModeProvider };
