import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";
import { ColorPicker } from "react-color-gradient-picker";
import "react-color-gradient-picker/dist/index.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ButtonToolbar = ({
  setBtnTxt,
  setBtnBg,
  btnBg,
  btnTxt,
  setTxtColor,
  txtColor,
  styles,
  setBtnLink,
  btnLink,
  hide,
  isDefault,
  setDefault,
  font,
  setFont,
}) => {
  const [showInput, setInput] = useState(false);
  const [picker, setPicker] = useState(false);
  const [txtPicker, setTxtPicker] = useState(false);
  const [linkInput, showLinkInput] = useState(false);
  const [colorType, setColorType] = useState("solid");

  const inputRef = useRef(null);
  const colorPickerRef = useRef(null);
  const ref = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
    setTxtPicker(false);
  });

  const [miniToolbar, setMiniToolbar] = useState(false);

  const gradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const mainDivRef = useRef(null);
  // useOutsideClick(mainDivRef, () => {
  //   hide && hide();
  // });
  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    const updatedFont = {
      ...font,
      family: selectedFont,
      isDefaultFont: selectedFont === "default" ? 1 : 0,
    };
    setFont(updatedFont);
  };
  const incrementFontSize = () => {
    const updatedFont = { ...font, size: Number(font.size) + 1 };
    setFont(updatedFont);
  };

  const decrementFontSize = () => {
    const updatedFont = { ...font, size: Math.max(font.size - 1, 1) };
    setFont(updatedFont);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
    const updatedFont = { ...font, size: parseInt(value === "" ? 0 : value) };
    setFont(updatedFont);
    inputRef.current.setSelectionRange(value.length, value.length);
  };

  return (
    <div
      ref={mainDivRef}
      style={styles}
      className="buttonToolBar"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          {showInput ? (
            <input
              placeholder="type here"
              className="btnInput"
              style={{ width: 110 }}
              type="text"
              value={btnTxt}
              maxLength={15}
              onChange={(e) => setBtnTxt(e.target.value)}
            />
          ) : !linkInput ? (
            <h4>Button Toolbar</h4>
          ) : (
            <input
              style={{ width: 110 }}
              placeholder="enter link"
              className="btnInput"
              type="text"
              value={btnLink}
              onChange={(e) => setBtnLink(e.target.value)}
            />
          )}
        </div>
        <div className="toolBarRight">
          <div
            onClick={() => {
              // setPicker(!picker);
              setTxtPicker(false);
              setMiniToolbar(!miniToolbar);
            }}
            className="bucketIcon"
          >
            <img src={Assets.paintbucket1} alt="Edit Image" />
          </div>
          {miniToolbar && (
            <div
              className="d-flex align-items-center"
              style={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: -20,
                backgroundColor: "#383939",
                padding: 4,
                borderRadius: 6,
                left: "20%",
              }}
            >
              <div className="fontList">
                <div style={{ cursor: "pointer" }} className="fillselect">
                  <p
                    onClick={handleClick}
                    style={{
                      color: "#ffffff",
                      fontSize: 13,
                      padding: 0,
                      margin: 0,
                      marginTop: 3,
                      paddingInline: 8,
                      borderRadius: 4,
                      backgroundColor: isDefault == 0 ? "gray" : "transparent",
                    }}
                  >
                    Custom
                  </p>
                </div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  selected={colorType === "solid" ? true : false}
                  onClick={() => {
                    setDefault(0);
                    setPicker(true);
                    setColorType("solid");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "solid" ? "dimgrey" : "transparent",
                  }}
                >
                  solid
                </MenuItem>
                <MenuItem
                  selected={colorType === "linear" ? true : false}
                  onClick={() => {
                    setDefault(0);
                    setPicker(true);
                    setColorType("linear");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "linear" ? "dimgrey" : "transparent",
                  }}
                >
                  gradient
                </MenuItem>
              </Menu>
            </div>
          )}
          <div
            onClick={() => {
              setInput(!showInput);
              showLinkInput(false);
            }}
            className="linkIcon"
            style={{
              backgroundColor: showInput ? "dimgrey" : "",
              borderRadius: 4,
            }}
          >
            <img src={Assets.editText} alt="Edit Image" />
          </div>
          {/* <Popup
            // lockscroll works only with modal
            // lockScroll={true}
            // modal
            ref={ref}
            position="bottom center"
            onOpen={() => {
              setPicker(!picker);
            }}
            // modal={true}
            trigger={
              <div
                onClick={() => {
                  setPicker(true);
                }}
                className="bucketIcon"
              >
                <img src={Assets.paintbucket1} alt="Edit Image" />
              </div>
            }
          >
            {(close) => (
              <div className="fontList">
                <div className="fillselect">
                  <select
                    style={{ minWidth: 80 }}
                    value={colorType}
                    onChange={(e) => {
                      close();
                      setPicker(true);
                      setColorType(e.target.value);
                    }}
                  >
                    <option value="solid">solid</option>
                    <option value="linear">linear</option>
                  </select>
                </div>
              </div>
            )}
          </Popup> */}
          <div className="d-flex align-tems-center justify-content-center ">
            <button className="counterBtn" onClick={decrementFontSize}>
              -
            </button>
            <input
              ref={inputRef}
              onChange={handleInputChange}
              className="fontCounter"
              type="text"
              value={`${font.size}px`}
            />
            <button className="counterBtn" onClick={incrementFontSize}>
              +
            </button>
          </div>
          <div className="fontList">
            <div className="fillselect">
              <select
                style={{ minWidth: 80 }}
                value={font.isDefaultFont == 1 ? "default" : font.family}
                onChange={handleFontChange}
              >
                <option value="default">Default</option>
                <option value="gilroymedium">Gilroy</option>
                <option value="frontageregular">Frontage</option>
                <option value="playfair display">Playfair Display</option>
                <option value="rockwellregular">Rockwell</option>
                <option value="Sigmar">
                  Sigmar
                </option>
                <option value="Dancing Script">Dancing Script</option>
                <option value="Eater">Eater</option>
                <option value="Kablammo">Kablammo</option>
                <option value="Splash">Splash</option>
                <option value="Rubik Burned">Rubik Burned</option>
              </select>
            </div>
          </div>
          <div
            onClick={() => {
              const updatedFont = { ...font, isBold: !font.isBold };
              setFont(updatedFont);
            }}
            className="boldIcon"
            style={{
              backgroundColor: font.isBold ? "dimgrey" : "",
              borderRadius: 6,
            }}
          >
            <img src={Assets.boldbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              const updatedFont = { ...font, isItalic: !font.isItalic };
              setFont(updatedFont);
            }}
            style={{
              backgroundColor: font.isItalic ? "dimgrey" : "",
              borderRadius: 6,
            }}
            className="italicIcon"
          >
            <img src={Assets.italicbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              const updatedFont = { ...font, isUnderlined: !font.isUnderlined };
              setFont(updatedFont);
            }}
            style={{
              backgroundColor: font.isUnderlined ? "dimgrey" : "",
              borderRadius: 6,
            }}
            className="TIcon"
          >
            <img src={Assets.Tbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setInput(false);
              showLinkInput(!linkInput);
            }}
            className="linkIcon"
            style={{
              backgroundColor: linkInput ? "dimgrey" : "",
              borderRadius: 4,
              // border:'2px solid grey',
            }}
          >
            <img style={{ height: 28 }} src={Assets.link} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setTxtPicker(!txtPicker);
              setPicker(false);
            }}
            className="colorCircle"
          >
            <span style={{ backgroundColor: txtColor }} className="blue"></span>
          </div>
        </div>
      </div>

      {picker && (
        <div ref={colorPickerRef}>
          <ColorPicker
            onStartChange={(e) => {
              setBtnBg(e);
            }}
            onChange={(e) => {
              setBtnBg(e);
            }}
            onEndChange={(e) => {
              setBtnBg(e);
            }}
            gradient={colorType === "solid" ? btnBg : gradient}
            isGradient={colorType === "linear" ? true : false}
          />
        </div>
        // <SketchPicker
        //   ref={colorPickerRef}
        //   className="colorPicker"
        //   color={btnBg}
        //   onChangeComplete={(color) => setBtnBg(color.hex)}
        // />
      )}
      {txtPicker && (
        <SketchPicker
          ref={colorPickerRef}
          className="colorPicker"
          color={txtColor}
          // styles={{ default: { picker: { zoom: "125%" } } }}
          onChangeComplete={(color) => setTxtColor(color.hex)}
        />
      )}
    </div>
  );
};

export default ButtonToolbar;
