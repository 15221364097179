import React, { useState, useEffect, useCallback, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import FileDrop from "../../../components/Toolbars/FileDrop";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import mapDiv from "../../../assets/images/mapDiv.png";
import map from "../../../assets/images/map.png";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import { getGradient } from "../../../utills/getGradient";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import MapSettingsModal from "../../../components/MapModal";

const GroupWidget = ({
  edithandleClick,
  itemStyles,
  height,
  width,
  widgetId,
  deleteWidget,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const [fileBox, showFileBox] = useState(false);
  const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const [imgFile, setImgFile] = useState(mapDiv);

  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const [link, setLink] = useState("");
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const [mapModalVisible, setMapModalVisible] = useState(false);

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const { default_widget_colors, mainColor } = universalStyles;

  const handleSaveCoordinates = (newCoords) => {
    const { lat, lng, address } = newCoords;
    setCenter({ lat, lng });
    setAddress(address);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: process.env.GOOGLE_MAP_KEY,
    googleMapsApiKey: "AIzaSyCHV5NUMj_-IAtVbOIs3--vayPR3E60dp8",
    libraries: ["places"],
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
  };

  const [center, setCenter] = useState({
    lat: 31.52855536504707,
    lng: 74.343926645336,
  });

  const [formatted_address, setAddress] = useState(null);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
    },
    [center]
  );

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
        // setImgFile("");
      } else {
        if (itemStyles.background_image) {
          setImage(itemStyles.background_image);
          setImgFile("");
        }
      }
      if (itemStyles.latitude && itemStyles.longitude) {
        setCenter({
          lat: parseFloat(itemStyles.latitude),
          lng: parseFloat(itemStyles.longitude),
        });
      }
      if (itemStyles.place_address) {
        setAddress(itemStyles.place_address);
      }

      // setImage(itemStyles.background_image);
      setLink(itemStyles.widget_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
      // Picker colors states
      if (itemStyles.picker_colors) {
        try {
          const pickerColors = JSON.parse(itemStyles.picker_colors);
          setPickerColors(pickerColors);
        } catch (error) {
          const pickerColors = itemStyles.picker_colors;
          setPickerColors(pickerColors);
        }
      }
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const defaultGradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const defaultSolidColor = {
    red: 255,
    green: 0,
    blue: 0,
    alpha: 1,
  };

  const [pickerColors, setPickerColors] = useState({
    solid: defaultSolidColor,
    gradient: defaultGradient,
  });

  useEffect(() => {
    if (editingMode.widgtId !== widgetId) {
      showFileBox(false);
    }
  }, [editingMode.widgtId]);

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  const [colorFromPicker, setColorFromPicker] = useState(false);
  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile("");
    }
  }, [colorFromPicker]);

  const handleEditClick = (e) => {
    setMapVisible(false);
    dispatch(updateTracker(true));

    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      setIsHovered(false);
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      dispatch(setEdit({}));
      showFileBox(false);
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image;
    widgetData["background_image"] = imgFile ? imgFile : null;
    widgetData["background_color"] = color;
    widgetData["border"] = border ? border : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;
    widgetData["title_color"] = null;
    widgetData["title"] = null;
    widgetData["title_font"] = null;
    widgetData["title_font_size"] = null;
    widgetData["title_font_style"] = null;

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = center.lat;
    widgetData["longitude"] = center.lng;
    widgetData["place_address"] = formatted_address;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    widgetData["picker_colors"] = pickerColors;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    link,
    border,
    defaultCorners,
    isDefault,
    imagePosition,
    imageRepeat,
    imgFile,
    center,
    pickerColors,
  ]);

  // Hover Edit
  const [editHovered, setEditHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setEditHovered(true), 300);
  const handleMouseLeave = () => setEditHovered(false);

  // console.log("Colo>", color);
  // console.log("PATH>", image);
  // console.log("FILE>", imgFile);

  const [mapVisible, setMapVisible] = useState(false);

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          e.stopPropagation();
        }
      }}
      className={`purplegradientmain ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      } `}
      style={{
        ...((image == "0" || image == null || image == false) &&
          imgFile == "" && {
            background: `${
              isDefault && default_widget_colors.map
                ? default_widget_colors.map.is_default === "0"
                  ? default_widget_colors.map.color
                  : default_widget_colors.map.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
            ...(isHovered && {
              backgroundImage: `url(${map}`,
            }),
          }),
        ...(((image !== "0" && image !== null && image !== false) ||
          imgFile !== "") && {
          backgroundImage: isHovered
            ? `url(${map}`
            : imgFile
            ? `url(${imgFile}`
            : `url(${process.env.REACT_APP_BASE_URL}${image}`,
          backgroundPosition: "center",
          backgroundRepeat: imageRepeat,
          backgroundSize: imagePosition,
        }),
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        padding: 0,
      }}
      onMouseEnter={() => {
        handleMouseEnter();
        if (editingMode.widgtId !== widgetId && !color) {
          setIsHovered(true);
          setMapVisible(true);
        }
      }}
      onMouseLeave={() => {
        setTimeout(() => {
          if (editingMode.widgtId !== widgetId) {
            handleMouseLeave();
            setIsHovered(false);
            setMapVisible(false);
          }
        }, 300);
      }}
    >
      <div
        className={`editdeletebtn  hover-div ${editHovered ? "hovered" : ""}`}
        style={{ zIndex: 2 }}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>

      {/* Map */}
      {isLoaded && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: mapVisible ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: mapVisible ? "auto" : "none",
            zIndex: 1,
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            options={{
              disableDoubleClickZoom: true,
              zoomControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              controlSize: false,
              scaleControl: false,
              draggableCursor: false,
              draggable: false,
              draggingCursor: false,
              gestureHandling: "none",
              zoom: 15,
            }}
          >
            {center && <Marker position={center} />}
          </GoogleMap>
        </div>
      )}

      {isLoaded && (
        <MapSettingsModal
          isOpen={mapModalVisible}
          onClose={() => setMapModalVisible(false)}
          onSave={handleSaveCoordinates}
          initialCoords={center}
          formatted_address={formatted_address}
        />
      )}

      {editingMode.widgtId == widgetId && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          color={color}
          setColor={setColor}
          //
          setPickerColors={setPickerColors}
          pickerColors={pickerColors}
          //
          showFileBox={showFileBox}
          fileBox={fileBox}
          styles={{
            // top: height !== 8 ? "-10%" : "-5%",
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
            // width: "80%",
            left: "10%",
          }}
          link={link}
          setLink={setLink}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setColorFromPicker={setColorFromPicker}
          colorFromPicker={colorFromPicker}
          editMap={true}
          mapModalVisible={mapModalVisible}
          setMapModalVisible={(e) => {
            setMapModalVisible(e);
            setMapVisible(true);
          }}
          // imageRepeat={imageRepeat}
          // setImgRepeat={setImgRepeat}
        />
      )}
      {editingMode.widgtId == widgetId && fileBox && (
        <FileDrop
          height={height}
          width={width}
          setImage={setImage}
          setImgFile={setImgFile}
          borderRadius={border === "round" ? 20 : border === "sharp" ? 0 : null}
        />
      )}
    </div>
  );
};

export default GroupWidget;
