import React, { useState, useEffect } from "react";
import "./styles.css";
import { Modal } from "react-bootstrap";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const MapSettingsModal = ({
  isOpen,
  onClose,
  onSave,
  initialCoords,
  formatted_address
}) => {
  const [lat, setLat] = useState(initialCoords.lat);
  const [lng, setLng] = useState(initialCoords.lng);
  const [address, setAddress] = useState(formatted_address);

  const handleSave = () => {
    onSave({ lat, lng,address });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal
      size={"unset"}
      centered
      show={isOpen}
      onHide={onClose}
      backdrop="static"
    >
      <Modal.Body className="login-modal">
        <h2 style={{ color: "#fff" }}>Map Coordinates</h2>
        <input
          type="number"
          value={lat}
          onChange={(e) => setLat(parseFloat(e.target.value))}
          placeholder="Latitude"
        />
        <input
          type="number"
          value={lng}
          onChange={(e) => setLng(parseFloat(e.target.value))}
          placeholder="Longitude"
        />
        <ReactGoogleAutocomplete
          apiKey={"AIzaSyCHV5NUMj_-IAtVbOIs3--vayPR3E60dp8"}
          onPlaceSelected={(place) => {
            const { lat, lng } = place.geometry.location;
            console.log("PLACE>",place);
            setLat(lat());
            setLng(lng());
            setAddress(place.formatted_address)
          }}
          defaultValue={address}
          options={{ types: "locality" }}
        />
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </Modal.Body>
    </Modal>
  );
};

export default MapSettingsModal;
