import React from "react";
import "../../home/styles.css";

const Player = ({borderRadius,width}) => {
  return (
    <div
      className={"videoDiv"}
      style={{
        borderRadius: borderRadius?borderRadius:0,
        width:width,
        height:'20%'
      }}
    />
  );
};

export default Player;
