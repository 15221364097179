import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../../assets/images";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import TextToolbar from "../../../components/Toolbars/TextToolbar";
import ButtonToolbar from "../../../components/Toolbars/ButtonToolbar";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import { getGradient } from "../../../utills/getGradient";

const GivingWidget = ({
  edithandleClick,
  itemStyles,
  width,
  height,
  widgetId,
  deleteWidget,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const [count, setCount] = useState(0);
  const [border, setBorder] = useState("");
  const [editBtn, setEditBtn] = useState(false);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const { default_widget_colors, mainColor } = universalStyles;
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (itemStyles.background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      }
      setBtnTxt(itemStyles.button_text);
      // setBtnBg(itemStyles.button_background_color);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }
      setTxtColor(itemStyles.button_text_color);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }

      setLink(itemStyles.widget_link);
      setBtnLink(itemStyles.button_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      // Picker colors states
      if (itemStyles.picker_colors) {
        try {
          const pickerColors = JSON.parse(itemStyles.picker_colors);
          setPickerColors(pickerColors);
        } catch (error) {
          const pickerColors = itemStyles.picker_colors;
          setPickerColors(pickerColors);
        }
      }
    }
  }, [itemStyles]);

  const defaultGradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const defaultSolidColor = {
    red: 255,
    green: 0,
    blue: 0,
    alpha: 1,
  };

  const [pickerColors, setPickerColors] = useState({
    solid: defaultSolidColor,
    gradient: defaultGradient,
  });

  const [isDefaultButton, setDefaultButton] = useState(0);
  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  const [isDefault, setDefault] = useState(0);
  const [isEditText, setEditText] = useState(false);
  const [color, setColor] = useState("#f8acac");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 22,
    isDefaultFont: 1,
  });
  const [btnBg, setBtnBg] = useState("");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [link, setLink] = useState("");
  const [btnlink, setBtnLink] = useState("");

  useEffect(() => {
    if (editingMode.widgtId !== widgetId) {
      setEditText(false);
    }
  }, [editingMode.widgtId]);

  const handleEditClick = (e) => {
    dispatch(updateTracker(true));

    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      dispatch(setEdit({}));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      setEditText(false);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    setEditBtn(false);
    e.stopPropagation();
  };

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("repeat");

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // setCount(count + 1);
    widgetData["background_image"] = null;
    widgetData["background_color"] = color;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = btnBg;
    widgetData["button_text"] = btnTxt;
    widgetData["button_text_color"] = txtColor;
    widgetData["button_link"] = btnlink;
    widgetData["button_font_style"] = {
      isBold: buttonFont.isBold,
      isUnderlined: buttonFont.isUnderlined,
      isItalic: buttonFont.isItalic,
      isDefaultFont: buttonFont.isDefaultFont,
      isDefaultButton: isDefaultButton,
    };
    widgetData["button_font_size"] = buttonFont.size ? buttonFont.size : null;
    widgetData["button_font"] = buttonFont.family ? buttonFont.family : null;

    widgetData["text_alignmnet"] = null;
    widgetData["title_color"] = font.color;
    widgetData["title"] = null;
    widgetData["title_font"] = font.family;
    widgetData["title_font_size"] = font.size;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
    };

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;
    widgetData["border"] = border ? border : null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    widgetData["picker_colors"] = pickerColors;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    color,
    btnBg,
    font,
    txtColor,
    btnTxt,
    btnlink,
    link,
    border,
    defaultCorners,
    isDefault,
    buttonFont,
    isDefaultButton,
    imagePosition,
    imageRepeat,
    pickerColors
  ]);

  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          setEditText(false);
          setEditBtn(false);
          e.stopPropagation();
        }
      }}
      className={`pinkgradient ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      } `}
      style={{
        background: `${
          isDefault && default_widget_colors.giving
            ? default_widget_colors.giving.is_default === "0"
              ? default_widget_colors.giving.color
              : default_widget_colors.giving.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
        // backgroundColor:
        //   isDefault && default_widget_colors.giving
        //     ? default_widget_colors.giving.is_default === "0"
        //       ? default_widget_colors.giving.color
        //       : default_widget_colors.giving.is_default === "1"
        //       ? mainColor
        //       : color
        //     : color,
        justifyContent: height == 8 ? "space-around" : "space-between",
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
    >
      {/* {editingMode.widgtId == widgetId && ( */}
      <div
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/* )} */}
      <div className="pinkTop">
        <div className="d-flex ">
          <h2
            onClick={(e) => {
              setEditText(true);
              e.stopPropagation();
            }}
            style={{
              color: font.color ? font.color : "white",
              textDecorationLine: font.isUnderlined ? "underline" : "none",
              fontWeight: font.isBold ? "bold" : "normal",
              fontStyle: font.isItalic ? "italic" : "normal",
              fontFamily: font.family,
              fontSize: Number(font.size),
              outline: isEditText ? "dotted" : "unset",
              pointerEvents: editingMode.widgtId == widgetId ? "all" : "none",
            }}
            className="maintitle"
          >
            Give
          </h2>
          {/* {editingMode.widgtId == widgetId && (
            <div className="editbtn" onClick={() => setEditText(!isEditText)}>
              <img
                style={{ height: 25 }}
                src={Assets.editicon}
                alt="Edit Image"
              />
            </div>
          )} */}
        </div>
        <div className="welcomForm give-form" style={{ marginTop: 0 }}>
          <div className="d-flex justify-content-between align-items-center">
            {/* <div className="fullfield"> */}
            <div
              style={{
                pointerEvents: "none",
              }}
              className="formField halffield"
            >
              <input disabled type="text" placeholder="Amount" />
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                justifyContent: height == 8 ? "center" : "flex-end",
              }}
            >
              <div
                className="d-flex align-items-center submit-btn-give halffield"
                style={{
                  justifyContent: height == 8 ? "center" : "flex-end",
                  // paddingInline: editingMode.widgtId == widgetId ? 0 : 20,
                }}
                onClick={(e) => {
                  setEditBtn(true);
                  e.stopPropagation();
                }}
              >
                <input
                  style={{
                    // backgroundColor: btnBg,
                    background: getGradient(btnBg),
                    color: txtColor,
                    fontSize: Number(buttonFont.size),
                    fontFamily:
                      buttonFont.family === "default"
                        ? universalStyles.font
                        : buttonFont.family
                        ? buttonFont.family
                        : "sans-serif",
                    fontStyle: buttonFont.isItalic ? "italic" : "normal",
                    textDecorationLine: buttonFont.isUnderlined
                      ? "underline"
                      : "none",
                    fontWeight: buttonFont.isBold ? "bold" : "normal",
                  }}
                  type="submit"
                  value={btnTxt ? btnTxt : "Give Now"}
                />
              </div>
              {/* {editingMode.widgtId == widgetId && (
                <div
                  onClick={() => setEditBtn(!editBtn)}
                  className="editbtn"
                  style={{ marginRight: 10 }}
                >
                  <img
                    style={{ height: 25 }}
                    src={Assets.editicon}
                    alt="Edit Image"
                  />
                </div>
              )} */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <div className="d-flex flex-column"> */}

      {/* </div> */}

      <div
        className="whitebottom"
        style={{
          borderBottomLeftRadius: border === "round" ? 20 : 0,
          borderBottomRightRadius: border === "round" ? 20 : 0,
        }}
      >
        <div className="progessbar"></div>
        <div className="contentcenter">
          <h2 style={{ fontSize: 40, lineHeight: "unset" }}>80%</h2>
          <h3 style={{ lineHeight: "unset", fontSize: 25 }}>$160 | $200</h3>
          <h6 style={{ marginTop: 0, fontSize: 16, fontWeight: "bold" }}>
            Your Giving Goal
          </h6>
        </div>
      </div>

      {editingMode.widgtId == widgetId && !isEditText && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          imageDisable={true}
          color={color}
          setColor={setColor}
          //
          setPickerColors={setPickerColors}
          pickerColors={pickerColors}
          //
          styles={{
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
            // width: "70%",
            left: "15%",
          }}
          link={link}
          setLink={setLink}
          pickerStyles={{ right: "20%", zIndex: 2 }}
        />
      )}
      {isEditText && (
        <TextToolbar
          styles={{
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-6%",
            left: "4%",
            position: "absolute",
          }}
          font={font}
          setFont={setFont}
        />
      )}
      {editingMode.widgtId == widgetId && editBtn && (
        <ButtonToolbar
          styles={{
            bottom: height == 6 ? "0%" : height == 2 ? "-40%" : "-25%",
            right: (height == 6 || height == 3) && width == 2 ? "4%" : "unset",
            // right: "2%",
          }}
          btnBg={btnBg}
          btnTxt={btnTxt}
          txtColor={txtColor}
          setTxtColor={setTxtColor}
          setBtnTxt={setBtnTxt}
          setBtnBg={setBtnBg}
          btnLink={btnlink}
          setBtnLink={setBtnLink}
          setDefault={setDefaultButton}
          isDefault={isDefaultButton}
          font={buttonFont}
          setFont={setButtonFont}
        />
      )}
    </div>
  );
};

export default GivingWidget;
