import React, { useState } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setEdit, setSideBar, setStopDrag } from "../../../store/slices/bodyStyling";

const VideoWidget = ({ widgetId, deleteWidget }) => {
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="videoDiv"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
      // style={{
      //   width:'100%',
      //   height: `${(9 / 16) * 100}%`,
      //   aspectRatio:16/9
      // }}
    >
      <div className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
       onMouseEnter={() => {
        dispatch(setStopDrag("yes"));
      }}
      onMouseLeave={() => {
        if (editingMode.widgtId !== widgetId) {
          dispatch(setStopDrag("no"));
        }
      }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(
                setEdit({
                })
              );
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
      </div>
    </div>
    // <div className="col-md-12">
    //   <video
    //     className="videoCont"
    //     src={require("../../../assets/video/video.mp4")}
    //     controls
    //   />
    // </div>
  );
};

export default VideoWidget;

// import React, { useState } from "react";
// import "../../home/styles.css";
// import Assets from "../../../assets/images";
// import { useSelector } from "react-redux";

// const VideoWidget = ({ widgetId, deleteWidget }) => {
//   const editingMode = useSelector((state) => state.bodyStyling.isEdit);

//   const [isHovered, setIsHovered] = useState(false);
//   const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
//   const handleMouseLeave = () => setIsHovered(false);

//   return (
//     <div
//       className="aspect-ratio-box"
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={() => {
//         editingMode.widgtId !== widgetId && handleMouseLeave();
//       }}
//     >
//       <div className="aspect-ratio-box-inside"></div>
//       <div className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}>
//         <div className="deletebtn" onClick={() => deleteWidget(widgetId)}>
//           <img src={Assets.deletebtn} alt="Delete Image" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoWidget;
