import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  hasError,
  loginSuccess,
  requestLogin,
} from "../../store/slices/loginSlice";
import { postRequest } from "../../utills/requests";
import toast from "react-hot-toast";
import { Circles } from "react-loader-spinner";

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.isLoading);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (username.trim().length > 0 && password.trim().length > 0) {
      const data = {
        username,
        password,
      };
      dispatch(requestLogin(true));
      try {
        const resp = await postRequest({
          endpoint: "/loginBuilder",
          payload: data,
        });
        if (resp.data.status !== false) {
          localStorage.setItem("accessToken", resp.data.api_token);
          dispatch(loginSuccess(resp.data));
          toast.success("Logged In");
          navigate("/home");
        } else {
          toast.error(resp.data.message, { id: "loginErr" });
        }
      } catch (error) {
        dispatch(hasError(error));
      } finally {
        dispatch(requestLogin(false));
      }
    } else {
      toast.error("Please fill all fields!", {
        id: "fieldErr",
      });
    }
  };

  return (
    <div className="container-fluid login-page">
      <div className="card col-md-4 ">
        <h2 style={{ alignSelf: "center" }}>Redefine Church</h2>
        <form>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {loading ? (
              <Circles
                height="50"
                width="50"
                color="#4b6cb7"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <button type="button" onClick={handleLogin}>
                Login
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
