import React, { useState } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { useSelector } from "react-redux";

const FileDrop = ({
  setImage,
  borderRadius,
  height,
  width,
  styles,
  setImgFile,
  setBgVideoLocalFile,
}) => {
  const pageType = useSelector((state) => state.login.pageType);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // setImage(event.target.result);
        setImgFile(event.target.result);
        setBgVideoLocalFile &&
          setBgVideoLocalFile({ base64: "no_video", url: null });
      };
      reader.readAsDataURL(file);
      // setImgFile(file)
      // console.log("FILE>",file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="fileDrop"
      style={{ borderRadius: borderRadius, ...styles }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="d-flex flex-column align-items-center justify-content-center ">
        <img
          src={Assets.placeholder2}
          alt="Edit Image"
          style={{ height: pageType === "web" ? 98 : 38 }}
        />
        <p
          style={{ color: "#C0BBBB", textAlign: "center" }}
          className={pageType === "mobile" ? "file-drop-text" : ""}
        >
          Drag and Drop Photo
        </p>
        <p
          style={{ color: "rgba(138 133 133 / 65%)", textAlign: "center" }}
          className={pageType === "mobile" ? "file-drop-text" : ""}
        >
          Preferred Size:{" "}
          {(height == 3 && width == 1) || (height == 6 && width == 2)
            ? "16 : 9"
            : height == 3 && width == 2
            ? "16:5"
            : height == 6 && width == 1
            ? "2:3"
            : "16:3"}
        </p>
        {/* <img src={Assets.uploadplaceholder} alt="Edit Image" /> */}
      </div>
    </div>
  );
};

export default FileDrop;
