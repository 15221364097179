import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  updateWigetsStyles,
  updateTracker,
  setSideBar,
  setStopDrag,
} from "../../../store/slices/bodyStyling";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChevronDown, Trash2 } from "lucide-react";
import Draggable from "react-draggable";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";
import { useDroppable } from "@dnd-kit/core";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import AccordionToolbar from "../../../components/Toolbars/AccordionToolbar";
import FileDrop from "../../../components/Toolbars/FileDrop";
import SpanComp from "../../../components/SpanComp";
import ColTextToolbar from "../../../components/Toolbars/ColumnTextToolbar";
import ColBtnToolbar from "../../../components/Toolbars/ColBtnToolbar";

const ColumnWidget = ({
  height,
  width,
  widgetId,
  itemStyles,
  deleteWidget,
  x,
}) => {
  const { setNodeRef: setSecondDroppaleRef1 } = useDroppable({
    id: `${widgetId}-colDroppable1`,
  });
  const { setNodeRef: setSecondDroppaleRef2 } = useDroppable({
    id: `${widgetId}-colDroppable2`,
  });
  const { setNodeRef: setSecondDroppaleRef3 } = useDroppable({
    id: `${widgetId}-colDroppable3`,
  });

  const [tool1, setTool1] = useState(true);
  const [tool2, setTool2] = useState(true);
  const [tool3, setTool3] = useState(true);

  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const pageId = useSelector((state) => state.login.pageId);

  const { mainColor } = universalStyles;
  const [default_widget_colors, setDefaultWidgetColors] = useState({});

  useEffect(() => {
    if (universalStyles.default_widget_colors) {
      try {
        const default_widget_colors = JSON.parse(
          universalStyles.default_widget_colors
        );
        setDefaultWidgetColors(default_widget_colors);
      } catch (error) {
        const default_widget_colors = universalStyles.default_widget_colors;
        setDefaultWidgetColors(default_widget_colors);
      }
    }
  }, [universalStyles]);

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setImage(itemStyles.background_image);
      // setImage2(itemStyles.background_image2);
      // setImage3(itemStyles.background_image3);
      // setColor(itemStyles.background_color);
      // for col 1
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          setImage(itemStyles.background_image);
        }
      }
      if (itemStyles.accordion1) {
        try {
          const items = JSON.parse(itemStyles.accordion1);
          setAccordion1(items);
        } catch (error) {
          const items = itemStyles.accordion1;
          setAccordion1(items);
        }
      }
      // for col 2
      if (
        itemStyles.background_color2 !== "0" &&
        itemStyles.background_color2 !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color2);
          setColor2(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color2;
          setColor2(bgstyle);
        }
      } else {
        if (itemStyles.background_image2) {
          setImage2(itemStyles.background_image2);
        }
      }
      if (itemStyles.accordion2) {
        try {
          const items = JSON.parse(itemStyles.accordion2);
          setAccordion2(items);
        } catch (error) {
          const items = itemStyles.accordion2;
          setAccordion2(items);
        }
      }
      // for col 3
      if (
        itemStyles.background_color3 !== "0" &&
        itemStyles.background_color3 !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color3);
          setColor3(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color3;
          setColor3(bgstyle);
        }
      } else {
        if (itemStyles.background_image3) {
          setImage3(itemStyles.background_image3);
        }
      }
      if (itemStyles.accordion3) {
        try {
          const items = JSON.parse(itemStyles.accordion3);
          setAccordion3(items);
        } catch (error) {
          const items = itemStyles.accordion3;
          setAccordion3(items);
        }
      }
      // Accordian coords
      if (itemStyles.accordionCoords1) {
        try {
          const coords = JSON.parse(itemStyles.accordionCoords1);
          setAccordionCoords1(coords);
        } catch (error) {
          const coords = itemStyles.accordionCoords1;
          setAccordionCoords1(coords);
        }
      }
      if (itemStyles.accordionCoords2) {
        try {
          const coords = JSON.parse(itemStyles.accordionCoords2);
          setAccordionCoords2(coords);
        } catch (error) {
          const coords = itemStyles.accordionCoords2;
          setAccordionCoords2(coords);
        }
      }
      if (itemStyles.accordionCoords3) {
        try {
          const coords = JSON.parse(itemStyles.accordionCoords3);
          setAccordionCoords3(coords);
        } catch (error) {
          const coords = itemStyles.accordionCoords3;
          setAccordionCoords3(coords);
        }
      }
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImagePosition2(itemStyles.image_position2);
      setImagePosition3(itemStyles.image_position3);
      setImgRepeat(itemStyles.image_repeat);
      // texts array
      if (itemStyles.text1) {
        try {
          const items = JSON.parse(itemStyles.text1);
          setText1(items);
        } catch (error) {
          const items = itemStyles.text1;
          setText1(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.text2) {
        try {
          const items = JSON.parse(itemStyles.text2);
          setText2(items);
        } catch (error) {
          const items = itemStyles.text2;
          setText2(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.text3) {
        try {
          const items = JSON.parse(itemStyles.text3);
          setText3(items);
        } catch (error) {
          const items = itemStyles.text3;
          setText3(items);
          console.error("Error parsing text1:", error);
        }
      }
      // heading array
      if (itemStyles.heading1) {
        try {
          const items = JSON.parse(itemStyles.heading1);
          setHeading1(items);
        } catch (error) {
          const items = itemStyles.heading1;
          setHeading1(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.heading2) {
        try {
          const items = JSON.parse(itemStyles.heading2);
          setHeading2(items);
        } catch (error) {
          const items = itemStyles.heading2;
          setHeading2(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.heading3) {
        try {
          const items = JSON.parse(itemStyles.heading3);
          setHeading3(items);
        } catch (error) {
          const items = itemStyles.heading3;
          setHeading3(items);
          console.error("Error parsing text1:", error);
        }
      }
      // paragraph array
      if (itemStyles.paragraph1) {
        try {
          const items = JSON.parse(itemStyles.paragraph1);
          setPara1(items);
        } catch (error) {
          const items = itemStyles.paragraph1;
          setPara1(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.paragraph2) {
        try {
          const items = JSON.parse(itemStyles.paragraph2);
          setPara2(items);
        } catch (error) {
          const items = itemStyles.paragraph2;
          setPara2(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.paragraph3) {
        try {
          const items = JSON.parse(itemStyles.paragraph3);
          setPara3(items);
        } catch (error) {
          const items = itemStyles.paragraph3;
          setPara3(items);
          console.error("Error parsing text1:", error);
        }
      }
      // button array
      if (itemStyles.button1) {
        try {
          const items = JSON.parse(itemStyles.button1);
          setButton1(items);
        } catch (error) {
          const items = itemStyles.button1;
          setButton1(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.button2) {
        try {
          const items = JSON.parse(itemStyles.button2);
          setButton2(items);
        } catch (error) {
          const items = itemStyles.button2;
          setButton2(items);
          console.error("Error parsing text1:", error);
        }
      }
      if (itemStyles.button3) {
        try {
          const items = JSON.parse(itemStyles.button3);
          setButton3(items);
        } catch (error) {
          const items = itemStyles.button3;
          setButton3(items);
          console.error("Error parsing text1:", error);
        }
      }
    }
  }, [itemStyles]);

  const [accordion1, setAccordion1] = useState([]);
  const [accordion2, setAccordion2] = useState([]);
  const [accordion3, setAccordion3] = useState([]);

  const [text1, setText1] = useState([]);
  const [text2, setText2] = useState([]);
  const [text3, setText3] = useState([]);

  const [heading1, setHeading1] = useState([]);
  const [heading2, setHeading2] = useState([]);
  const [heading3, setHeading3] = useState([]);

  const [para1, setPara1] = useState([]);
  const [para2, setPara2] = useState([]);
  const [para3, setPara3] = useState([]);

  const [button1, setButton1] = useState([]);
  const [button2, setButton2] = useState([]);
  const [button3, setButton3] = useState([]);

  const [accordionIndexAndType, setAccordionIndexAndType] = useState({
    index: 0,
    type: "title",
  });
  const [itemIndexTypeCol, setItemIndexTypeCol] = useState({
    index: "",
    type: "",
    col: "",
  });

  const [columnClicked, setColumnClicked] = useState("");

  const [isDefault, setDefault] = useState(0);
  const [isDefault2, setDefault2] = useState(0);
  const [isDefault3, setDefault3] = useState(0);

  const dispatch = useDispatch();

  const [accordionCoords1, setAccordionCoords1] = useState({ x: 0, y: 25 });
  const [accordionCoords2, setAccordionCoords2] = useState({ x: 0, y: 25 });
  const [accordionCoords3, setAccordionCoords3] = useState({ x: 0, y: 25 });

  const [border, setBorder] = useState("");

  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  const [imgFile1, setImgFile1] = useState("");
  const [imgFile2, setImgFile2] = useState("");
  const [imgFile3, setImgFile3] = useState("");

  const [link, setLink] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  const [fileBox, showFileBox] = useState(false);
  const [fileBox2, showFileBox2] = useState(false);
  const [fileBox3, showFileBox3] = useState(false);

  useEffect(() => {
    if (fileBox) {
      fileBox2 && showFileBox2(false);
      fileBox3 && showFileBox3(false);
    }
  }, [fileBox]);

  useEffect(() => {
    if (fileBox2) {
      fileBox && showFileBox(false);
      fileBox3 && showFileBox3(false);
    }
  }, [fileBox2]);

  useEffect(() => {
    if (fileBox3) {
      fileBox2 && showFileBox2(false);
      fileBox && showFileBox(false);
    }
  }, [fileBox3]);

  const [textIsDashed, setTextDashed] = useState(false);
  const [headingIsDashed, setHeadDashed] = useState(false);
  const [paraIsDashed, setParaDashed] = useState(false);

  const [colorFromPicker, setColorFromPicker] = useState(false);
  const [colorFromPicker2, setColorFromPicker2] = useState(false);
  const [colorFromPicker3, setColorFromPicker3] = useState(false);

  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile1("");
    }
  }, [colorFromPicker]);

  useEffect(() => {
    if (
      colorFromPicker2 &&
      (image2 !== "0" || image2 !== null || image2 !== false)
    ) {
      setImage2(false);
      setImgFile2("");
    }
  }, [colorFromPicker2]);

  useEffect(() => {
    if (
      colorFromPicker3 &&
      (image3 !== "0" || image3 !== null || image3 !== false)
    ) {
      setImage3(false);
      setImgFile3("");
    }
  }, [colorFromPicker3]);

  const gradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  // const [color, setColor] = useState(gradient);
  // const [color2, setColor2] = useState(gradient);
  // const [color3, setColor3] = useState("#343765");

  const [color, setColor] = useState(false);
  const [color2, setColor2] = useState(false);
  const [color3, setColor3] = useState(false);

  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (itemStyles === undefined) {
      setColor("#3478ff");
      setColor2(gradient);
      setColor3("#343765");
    }
  }, [itemStyles]);

  // useEffect(() => {
  //   fileBox && showFileBox(false);
  //   fileBox2 && showFileBox2(false);
  //   fileBox3 && showFileBox3(false);
  // }, [image, image2, image3]);

  useEffect(() => {
    fileBox && showFileBox(false);
    fileBox2 && showFileBox2(false);
    fileBox3 && showFileBox3(false);
    if (imgFile1 !== "") {
      setColor("");
    }
    if (imgFile2 !== "") {
      setColor2("");
    }
    if (imgFile3 !== "") {
      setColor3("");
    }
  }, [imgFile1, imgFile2, imgFile3]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  useEffect(() => {
    if (editingMode.widgtId !== localStorage.getItem("customId")) {
      showFileBox(false);
    }
  }, [editingMode.widgtId]);

  const handleEditClick = (e) => {
    dispatch(updateTracker(true));
    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      setTool1(true);
      setTool2(true);
      setTool3(true);
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      localStorage.setItem("widgetName", "column");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      setTool1(false);
      setTool2(false);
      setTool3(false);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imagePosition2, setImagePosition2] = useState("100% 100%");
  const [imagePosition3, setImagePosition3] = useState("100% 100%");

  // console.log("IMG1>",imagePosition);
  // console.log("IMG2>",imagePosition2);
  // console.log("IMG3>",imagePosition3);

  const [imageRepeat, setImgRepeat] = useState("no-repeat");
  const [imageRepeat2, setImgRepeat2] = useState("no-repeat");
  const [imageRepeat3, setImgRepeat3] = useState("no-repeat");

  let widgetData = { widget_id: widgetId };

  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // col1
    // widgetData["background_image"] = image ? image : null;
    widgetData["background_image"] = imgFile1 ? imgFile1 : null;
    widgetData["background_color"] = color ? color : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = text1;
    widgetData["heading1"] = heading1;
    widgetData["paragraph1"] = para1;
    widgetData["button1"] = button1;
    // col2
    // widgetData["background_image2"] = image2 ? image2 : null;
    widgetData["background_image2"] = imgFile2 ? imgFile2 : null;
    widgetData["background_color2"] = color2 ? color2 : null;
    widgetData["is_default2"] = isDefault2;
    widgetData["image_position2"] = imagePosition2;
    widgetData["image_repeat2"] = imageRepeat2;
    widgetData["text2"] = text2;
    widgetData["heading2"] = heading2;
    widgetData["paragraph2"] = para2;
    widgetData["button2"] = button2;
    // col3
    // widgetData["background_image3"] = image3 ? image3 : null;
    widgetData["background_image3"] = imgFile3 ? imgFile3 : null;
    widgetData["background_color3"] = color3 ? color3 : null;
    widgetData["is_default3"] = isDefault3;
    widgetData["image_position3"] = imagePosition3;
    widgetData["image_repeat3"] = imageRepeat3;
    widgetData["text3"] = text3;
    widgetData["heading3"] = heading3;
    widgetData["paragraph3"] = para3;
    widgetData["button3"] = button3;
    //accordion
    widgetData["accordion1"] = accordion1;
    widgetData["accordion2"] = accordion2;
    widgetData["accordion3"] = accordion3;
    // Accordion group coords
    widgetData["accordionCoords1"] = accordionCoords1;
    widgetData["accordionCoords2"] = accordionCoords2;
    widgetData["accordionCoords3"] = accordionCoords3;
    //
    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;

    widgetData["title"] = null;
    widgetData["title_color"] = null;
    widgetData["title_font"] = null;
    widgetData["title_font_size"] = null;
    widgetData["title_font_style"] = null;

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link ? link : null;

    widgetData["button_alignmnet"] = null;
    border ? (widgetData["border"] = border) : (widgetData["border"] = null);

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;
    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    widgetData["defaultCorners"] = defaultCorners;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    widgetData["picker_colors"] = null;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    link,
    border,
    defaultCorners,
    isDefault,
    imagePosition,
    imageRepeat,
    image2,
    image3,
    color2,
    color3,
    imagePosition2,
    imagePosition3,
    imageRepeat2,
    imageRepeat3,
    accordion1,
    accordion2,
    accordion3,
    accordionCoords1,
    accordionCoords2,
    accordionCoords3,
    text1,
    text2,
    text3,
    heading1,
    heading2,
    heading3,
    para1,
    para2,
    para3,
    button1,
    button2,
    button3,
    imgFile1,
    imgFile2,
    imgFile3,
  ]);

  const [isVisible, setVisible] = useState({
    text: true,
    heading: true,
    para: true,
    // button: false,
  });

  const [isShowing, setShow] = useState({
    widget: true,
    button: false,
    text: false,
  });

  //Span height
  function handleSpanChange(event, col, index, type) {
    const span = event.target;
    const width = span.offsetWidth;
    const height = span.offsetHeight;
    if (col === "col1") {
      if (type === "text") {
        setText1((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      } else if (type === "heading") {
        setHeading1((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      } else if (type === "paragraph") {
        setPara1((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      }
    } else if (col === "col2") {
      if (type === "text") {
        setText2((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      } else if (type === "heading") {
        setHeading2((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      } else if (type === "paragraph") {
        setPara2((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      }
    } else if (col === "col3") {
      if (type === "text") {
        setText3((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      } else if (type === "heading") {
        setText3((prev) => {
          return prev.map((item, idx) => {
            if (idx === index) {
              return { ...item, height, width };
            }
            return item;
          });
        });
      }
    }
  }
  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  const mainRef1 = useRef(null);

  const mainDimensions1 = useResizeObserver({ ref: mainRef1 });

  const imgStyles = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundRepeat: imageRepeat,
    backgroundSize: imagePosition,
  };

  const commonStyles = {
    position: "relative",
    height: "100%",
    padding: 10,
  };

  const backgroundStyles = (
    image,
    default_widget_colors,
    mainColor,
    color,
    imageRepeat,
    imagePosition,
    imgFile
  ) => {
    if ((image == "0" || image == null || image == false) && imgFile == "") {
      return {
        background: `${
          isDefault && default_widget_colors.custom
            ? default_widget_colors.custom.is_default === "0"
              ? default_widget_colors.custom.color
              : default_widget_colors.custom.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
      };
    } else {
      return {
        backgroundImage: imgFile
          ? `url(${imgFile}`
          : `url(${process.env.REACT_APP_BASE_URL}${image}`,
        // backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: imageRepeat,
        backgroundSize: imagePosition,
      };
    }
  };

  const styles = {
    ...commonStyles,
    borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
    // borderTopLeftRadius:
    //   border === "round" ? 20 : border === "sharp" ? 0 : null,
    // borderBottomLeftRadius:
    //   border === "round" ? 20 : border === "sharp" ? 0 : null,
    ...backgroundStyles(
      image,
      default_widget_colors,
      mainColor,
      color,
      imageRepeat,
      imagePosition,
      imgFile1
    ),
  };
  const styles2 = {
    ...commonStyles,
    borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
    ...backgroundStyles(
      image2,
      default_widget_colors,
      mainColor,
      color2,
      imageRepeat,
      imagePosition2,
      imgFile2
    ),
  };
  const styles3 = {
    ...commonStyles,
    borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
    // borderTopRightRadius:
    //   border === "round" ? 20 : border === "sharp" ? 0 : null,
    // borderBottomRightRadius:
    //   border === "round" ? 20 : border === "sharp" ? 0 : null,
    ...backgroundStyles(
      image3,
      default_widget_colors,
      mainColor,
      color3,
      imageRepeat,
      imagePosition3,
      imgFile3
    ),
  };

  // console.log("ONE>", styles);
  // console.log("TWO>", styles2);
  // console.log("THREE>", styles3);

  return (
    <div
      ref={mainRef1}
      className={`d-flex ${editingMode.widgtId == widgetId ? "edit-mode" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
      style={{
        height: "100%",
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        justifyContent: "space-between",
        columnGap: border === "round" ? 10 : 0,
      }}
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          e.stopPropagation();
        }
      }}
    >
      <div
        style={{ zIndex: 1, top: 10 }}
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/* 1st */}
      <div
        ref={setSecondDroppaleRef1}
        className={`firstCol ${border === "round" ? "firstColRound" : ""} `}
        // className="firstCol col-md-4"
        style={styles}
        onClick={() => {
          setItemIndexTypeCol({ index: "", type: "", col: "" });
        }}
      >
        {tool1 && widgetId == localStorage.getItem("customId") && (
          <div
            style={{ zIndex: 1, position: "absolute", top: 0 }}
            className={`editdeletebtn}`}
          >
            <div
              className="editbtn"
              onClick={() => {
                setItemIndexTypeCol({ index: "", type: "", col: "" });
                setTool1(false);
                setTool2(true);
                setTool3(true);
              }}
            >
              <img
                src={Assets.editicon}
                alt="Edit Image"
                style={{ height: 25 }}
              />
            </div>
          </div>
        )}
        {/* TEXT */}
        {text1.length > 0 &&
          text1.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setText1((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setText1((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setText1}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col1"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="text"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}

        {/* HEADING */}
        {heading1.length > 0 &&
          heading1.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setHeading1((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                // onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setHeading1((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setHeading1}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col1"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="heading"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* PARAGRAPH */}
        {para1.length > 0 &&
          para1.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setPara1((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setPara1((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setPara1}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col1"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="paragraph"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* BUTTON */}
        {button1.length > 0 &&
          button1.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                scale={1}
                bounds="parent"
                onStop={(e, ui) =>
                  setButton1((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  })
                }
              >
                <div
                  className={`aligned-button`}
                  style={{
                    justifyContent: height == 8 ? "center" : "flex-end",
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                  }}
                  onFocus={() => {
                    setShow((prev) => ({
                      ...prev,
                      button: true,
                      widget: false,
                    }));
                  }}
                  onClick={(e) => {
                    setItemIndexTypeCol({ index, type: "button", col: "col1" });
                    e.stopPropagation();
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle "
                        style={{
                          cursor: "move",
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setButton1((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => parseInt(itm.id) !== parseInt(item.id)
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <div className="submit-btn-give">
                    <input
                      style={{
                        // backgroundColor: item.bgColor,
                        background: getGradient(item.bgColor),
                        color: item.txtColor,
                        fontSize: Number(item.fontSize),
                        fontFamily:
                          item.family === "default"
                            ? universalStyles.font
                            : item.family
                            ? item.family
                            : "sans-serif",
                        fontStyle: item.isItalic ? "italic" : "normal",
                        textDecorationLine: item.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: item.isBold ? "bold" : "normal",
                      }}
                      type="submit"
                      value={item.text ? item.text : "Send"}
                    />
                  </div>
                </div>
              </Draggable>
            );
          })}

        {accordion1.length > 0 && (
          <Draggable
            axis="both"
            handle=".handle"
            defaultPosition={{ x: accordionCoords1.x, y: accordionCoords1.y }}
            position={null}
            grid={[25, 25]}
            scale={1}
            // disabled={isResizing}
            bounds="parent"
            onStop={(e, ui) => {
              setAccordionCoords1({ x: ui.x, y: ui.y });
            }}
            onDrag={() => {}}
          >
            <div className={`d-flex flex-column`}>
              {editingMode.widgtId == widgetId && (
                <div
                  className="d-flex align-items-center justify-content-center gap-2"
                  style={{
                    position: "absolute",
                    top: -44, // Align to the top of the parent div
                    left: "50%", // Center horizontally
                    transform: "translateX(-50%)",
                    backgroundColor: "rgb(255, 255, 255,0.8)",
                    borderRadius: 20,
                    padding: 6,
                    paddingInline: 10,
                  }}
                >
                  <div
                    className="handle"
                    style={{
                      cursor: "move",
                    }}
                    onMouseOver={() => {
                      // setParaDashed(true);
                    }}
                    onMouseLeave={() => {
                      // setParaDashed(false);
                    }}
                  >
                    <img
                      src={Assets.dragIcon}
                      style={{
                        height: 16,
                        width: 16,
                      }}
                    />
                  </div>
                  <Trash2
                    onClick={() => {
                      setAccordion1([]);
                    }}
                    style={{ cursor: "pointer" }}
                    size={20}
                    color="#000"
                  />
                </div>
              )}
              {accordion1.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    style={{
                      // header bgColor
                      backgroundColor: item.titleBgColor,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{
                        width: "100%",
                        // header font color
                        color: item.titleTextColor,
                      }}
                      expandIcon={<ChevronDown color="white" />}
                      aria-controls="panel1-content"
                      id={`panel${index}-header`}
                    >
                      <span
                        suppressContentEditableWarning
                        contentEditable={
                          widgetId == localStorage.getItem("customId")
                        }
                        data-placeholder="title..."
                        role="textbox"
                        // onFocus={() => {
                        //   setAccordionIndexAndType({ index, type: "title" });
                        //   setColumnClicked("1st");
                        //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                        // }}
                        onBlur={(e) => {
                          setAccordion1((prev) => {
                            const updatedItem = prev.map((item, i) => {
                              if (index == i) {
                                return { ...item, title: e.target.innerText };
                              } else {
                                return item;
                              }
                            });
                            return updatedItem;
                          });
                        }}
                        onClick={(e) => {
                          setAccordionIndexAndType({ index, type: "title" });
                          setColumnClicked("1st");
                          setItemIndexTypeCol({ index: "", type: "", col: "" });
                          e.stopPropagation();
                        }}
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                          outline: "unset",
                          color: item.titleTextColor,
                          fontSize: item.titleFontSize,
                          fontWeight: item.isTitleBold ? "bold" : "normal",
                          fontStyle: item.isTitleItalic ? "italic" : "normal",
                          textDecorationLine: item.isTitleUnderlined
                            ? "underline"
                            : "none",
                          fontFamily:
                            item.defaultTitleFamily == 1
                              ? universalStyles.font
                              : item.titleFamily
                              ? item.titleFamily
                              : "sans-serif",
                        }}
                      >
                        {item.title}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails
                      contentEditable={
                        widgetId == localStorage.getItem("customId")
                      }
                      style={{
                        backgroundColor: item.bodyBgColor,
                        color: item.bodyTextColor,
                        outline: "unset",
                        fontSize: item.bodyFontSize,
                        fontWeight: item.isBodyBold ? "bold" : "normal",
                        fontStyle: item.isBodyItalic ? "italic" : "normal",
                        textDecorationLine: item.isBodyUnderlined
                          ? "underline"
                          : "none",
                        fontFamily:
                          item.defaultBodyFamily == 1
                            ? universalStyles.font
                            : item.bodyFamily
                            ? item.bodyFamily
                            : "sans-serif",
                      }}
                      placeholder="detail..."
                      // onFocus={() => {
                      //   setAccordionIndexAndType({ index, type: "detail" });
                      //   setColumnClicked("1st");
                      //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                      // }}
                      onBlur={(e) => {
                        setAccordion1((prev) => {
                          const updatedItem = prev.map((item, i) => {
                            if (index == i) {
                              return { ...item, bodyText: e.target.innerText };
                            } else {
                              return item;
                            }
                          });
                          return updatedItem;
                        });
                      }}
                      onClick={(e) => {
                        setAccordionIndexAndType({ index, type: "detail" });
                        setColumnClicked("1st");
                        setItemIndexTypeCol({ index: "", type: "", col: "" });
                        e.stopPropagation();
                      }}
                    >
                      {item.bodyText}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Draggable>
        )}
        {/* FileBox */}
        {widgetId == localStorage.getItem("customId") && fileBox && (
          <FileDrop
            setImage={setImage}
            setImgFile={setImgFile1}
            height={height}
            width={width}
            borderRadius={0}
            styles={{
              borderTopLeftRadius:
                border === "round" ? 20 : border === "sharp" ? 0 : null,
              borderBottomLeftRadius:
                border === "round" ? 20 : border === "sharp" ? 0 : null,
            }}
          />
        )}
      </div>
      {/* 2nd */}
      <div
        ref={setSecondDroppaleRef2}
        // className="secondCol"
        className={`secondCol ${border === "round" ? "secondColRound" : ""} `}
        // className="secondCol col-md-4"
        style={styles2}
        onClick={() => {
          setItemIndexTypeCol({ index: "", type: "", col: "" });
        }}
      >
        {tool2 && widgetId == localStorage.getItem("customId") && (
          <div
            style={{ zIndex: 1, position: "absolute", top: 0 }}
            className={`editdeletebtn}`}
          >
            <div
              className="editbtn"
              onClick={() => {
                setItemIndexTypeCol({ index: "", type: "", col: "" });
                setTool1(true);
                setTool2(false);
                setTool3(true);
              }}
            >
              <img
                src={Assets.editicon}
                alt="Edit Image"
                style={{ height: 25 }}
              />
            </div>
          </div>
        )}
        {/* TEXT */}
        {text2.length > 0 &&
          text2.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setText2((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setText2((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setText2}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col2"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type={"text"}
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* HEADING */}
        {heading2.length > 0 &&
          heading2.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setHeading2((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setHeading2((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setHeading2}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col2"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="heading"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* PARAGRAPH */}
        {para2.length > 0 &&
          para2.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setPara2((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                        <Trash2
                          onClick={() => {
                            setPara2((prev) => {
                              const temp = [...prev];
                              const updated = temp.filter(
                                (itm) => itm.id !== item.id
                              );
                              return updated;
                            });
                          }}
                          style={{ cursor: "pointer" }}
                          size={20}
                          color="#000"
                        />
                      </div>
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setPara2}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col2"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="paragraph"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* BUTTON */}
        {button2.length > 0 &&
          button2.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                scale={1}
                bounds="parent"
                onStop={(e, ui) =>
                  setButton2((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  })
                }
              >
                <div
                  className={`aligned-button`}
                  style={{
                    justifyContent: height == 8 ? "center" : "flex-end",
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                  }}
                  onFocus={() => {
                    setShow((prev) => ({
                      ...prev,
                      button: true,
                      widget: false,
                    }));
                  }}
                  onClick={(e) => {
                    setItemIndexTypeCol({ index, type: "button", col: "col2" });
                    e.stopPropagation();
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle "
                        style={{
                          cursor: "move",
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setButton2((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <div className="submit-btn-give">
                    <input
                      style={{
                        // backgroundColor: item.bgColor,
                        background: getGradient(item.bgColor),
                        color: item.txtColor,
                        fontSize: Number(item.fontSize),
                        fontFamily:
                          item.family === "default"
                            ? universalStyles.font
                            : item.family
                            ? item.family
                            : "sans-serif",
                        fontStyle: item.isItalic ? "italic" : "normal",
                        textDecorationLine: item.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: item.isBold ? "bold" : "normal",
                      }}
                      type="submit"
                      value={item.text ? item.text : "Send"}
                    />
                  </div>
                </div>
              </Draggable>
            );
          })}
        {accordion2.length > 0 && (
          <Draggable
            axis="both"
            handle=".handle"
            defaultPosition={{ x: accordionCoords2.x, y: accordionCoords2.y }}
            position={null}
            grid={[25, 25]}
            scale={1}
            // disabled={isResizing}
            bounds="parent"
            onStop={(e, ui) => {
              setAccordionCoords2({ x: ui.x, y: ui.y });
            }}
            onDrag={() => {}}
          >
            <div className={`d-flex flex-column`} style={{}} onFocus={() => {}}>
              {editingMode.widgtId == widgetId && (
                <div
                  className="d-flex align-items-center justify-content-center gap-2"
                  style={{
                    position: "absolute",
                    top: -44, // Align to the top of the parent div
                    left: "50%", // Center horizontally
                    transform: "translateX(-50%)",
                    backgroundColor: "rgb(255, 255, 255,0.8)",
                    borderRadius: 20,
                    padding: 6,
                    paddingInline: 10,
                  }}
                >
                  <div
                    className="handle"
                    style={{
                      cursor: "move",
                    }}
                    onMouseOver={() => {
                      // setParaDashed(true);
                    }}
                    onMouseLeave={() => {
                      // setParaDashed(false);
                    }}
                  >
                    <img
                      src={Assets.dragIcon}
                      style={{
                        height: 16,
                        width: 16,
                      }}
                    />
                  </div>
                  <Trash2
                    onClick={() => {
                      setAccordion2([]);
                    }}
                    style={{ cursor: "pointer" }}
                    size={20}
                    color="#000"
                  />
                </div>
              )}
              {accordion2.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    style={{
                      // header bgColor
                      backgroundColor: item.titleBgColor,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{
                        width: "100%",
                        // header font color
                        color: item.titleTextColor,
                      }}
                      expandIcon={<ChevronDown color="white" />}
                      aria-controls="panel1-content"
                      id={`panel${index}-header`}
                    >
                      <span
                        suppressContentEditableWarning
                        contentEditable={
                          widgetId == localStorage.getItem("customId")
                        }
                        data-placeholder="title..."
                        role="textbox"
                        // onFocus={() => {
                        //   setAccordionIndexAndType({ index, type: "title" });
                        //   setColumnClicked("2nd");
                        //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                        // }}
                        onBlur={(e) => {
                          setAccordion2((prev) => {
                            const updatedItem = prev.map((item, i) => {
                              if (index == i) {
                                return { ...item, title: e.target.innerText };
                              } else {
                                return item;
                              }
                            });
                            return updatedItem;
                          });
                        }}
                        onClick={(e) => {
                          setAccordionIndexAndType({ index, type: "title" });
                          setColumnClicked("2nd");
                          setItemIndexTypeCol({ index: "", type: "", col: "" });
                          e.stopPropagation();
                        }}
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                          outline: "unset",
                          fontSize: item.titleFontSize,
                          fontWeight: item.isTitleBold ? "bold" : "normal",
                          fontStyle: item.isTitleItalic ? "italic" : "normal",
                          textDecorationLine: item.isTitleUnderlined
                            ? "underline"
                            : "none",
                          fontFamily:
                            item.defaultTitleFamily == 1
                              ? universalStyles.font
                              : item.titleFamily
                              ? item.titleFamily
                              : "sans-serif",
                        }}
                      >
                        {item.title}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails
                      contentEditable={
                        widgetId == localStorage.getItem("customId")
                      }
                      style={{
                        backgroundColor: item.bodyBgColor,
                        color: item.bodyTextColor,
                        outline: "unset",
                        fontSize: item.bodyFontSize,
                        fontWeight: item.isBodyBold ? "bold" : "normal",
                        fontStyle: item.isBodyItalic ? "italic" : "normal",
                        textDecorationLine: item.isBodyUnderlined
                          ? "underline"
                          : "none",
                        fontFamily:
                          item.defaultBodyFamily == 1
                            ? universalStyles.font
                            : item.bodyFamily
                            ? item.bodyFamily
                            : "sans-serif",
                      }}
                      placeholder="detail..."
                      // onFocus={() => {
                      //   setAccordionIndexAndType({ index, type: "detail" });
                      //   setColumnClicked("2nd");
                      //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                      // }}
                      onBlur={(e) => {
                        setAccordion2((prev) => {
                          const updatedItem = prev.map((item, i) => {
                            if (index == i) {
                              return { ...item, bodyText: e.target.innerText };
                            } else {
                              return item;
                            }
                          });
                          return updatedItem;
                        });
                      }}
                      onClick={(e) => {
                        setAccordionIndexAndType({ index, type: "detail" });
                        setColumnClicked("2nd");
                        setItemIndexTypeCol({ index: "", type: "", col: "" });
                        e.stopPropagation();
                      }}
                    >
                      {item.bodyText}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Draggable>
        )}
        {widgetId == localStorage.getItem("customId") && fileBox2 && (
          <FileDrop
            setImage={setImage2}
            setImgFile={setImgFile2}
            height={height}
            width={width}
            borderRadius={0}
          />
        )}
      </div>
      {/* 3rd */}
      <div
        ref={setSecondDroppaleRef3}
        // className="thirdCol"
        className={`thirdCol ${border === "round" ? "thirdColRound" : ""} `}
        // className="thirdCol col-md-4"
        style={styles3}
        onClick={() => {
          setItemIndexTypeCol({ index: "", type: "", col: "" });
        }}
      >
        {tool3 && widgetId == localStorage.getItem("customId") && (
          <div
            style={{ zIndex: 1, position: "absolute", top: 0 }}
            className={`editdeletebtn}`}
          >
            <div
              className="editbtn"
              onClick={() => {
                setItemIndexTypeCol({ index: "", type: "", col: "" });
                setTool1(true);
                setTool2(true);
                setTool3(false);
              }}
            >
              <img
                src={Assets.editicon}
                alt="Edit Image"
                style={{ height: 25 }}
              />
            </div>
          </div>
        )}
        {/* TEXT */}
        {text3.length > 0 &&
          text3.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                // key={index}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setText3((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setText3((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setText3}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col3"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type={"text"}
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* HEADING */}
        {heading3.length > 0 &&
          heading3.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                // key={index}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setHeading3((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>

                      <Trash2
                        onClick={() => {
                          setHeading3((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setHeading3}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col3"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="heading"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {/* PARAGRAPH */}
        {para3.length > 0 &&
          para3.map((item, index) => {
            return (
              <Draggable
                // key={index}
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                // grid={[25, 25]}
                scale={1}
                // disabled={isResizing}
                bounds="parent"
                onStop={(e, ui) => {
                  setPara3((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  });
                }}
                onDrag={() => {}}
              >
                <div
                  className={`d-flex align-items-end aligned-input`}
                  style={{
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                    ...(headingIsDashed && {
                      outlineStyle: "dashed",
                      outlineColor: "whitesmoke",
                    }),
                  }}
                  onFocus={() => {
                    setVisible({ text: true, heading: false, para: true });
                    setShow({
                      widget: false,
                      button: false,
                      text: true,
                    });
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle"
                        style={{
                          cursor: "move",
                        }}
                        onMouseOver={() => {
                          setHeadDashed(true);
                        }}
                        onMouseLeave={() => {
                          setHeadDashed(false);
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                        
                      </div>
                      <Trash2
                          onClick={() => {
                            setPara3((prev) => {
                              const temp = [...prev];
                              const updated = temp.filter(
                                (itm) => itm.id !== item.id
                              );
                              return updated;
                            });
                          }}
                          style={{ cursor: "pointer" }}
                          size={20}
                          color="#000"
                        />
                    </div>
                  )}
                  <SpanComp
                    item={item}
                    setText={setPara3}
                    widgetId={widgetId}
                    index={index}
                    handleSpanChange={handleSpanChange}
                    col={"col3"}
                    default_widget_colors={default_widget_colors}
                    mainDimensions1={mainDimensions1}
                    isVisible={isVisible}
                    universalStyles={universalStyles}
                    type="paragraph"
                    setItemIndexTypeCol={setItemIndexTypeCol}
                  />
                </div>
              </Draggable>
            );
          })}
        {button3.length > 0 &&
          button3.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                axis="both"
                handle=".handle"
                defaultPosition={{ x: item.x, y: item.y }}
                position={null}
                scale={1}
                bounds="parent"
                onStop={(e, ui) =>
                  setButton3((prev) => {
                    return prev.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, x: ui.x, y: ui.y };
                      }
                      return item;
                    });
                  })
                }
              >
                <div
                  className={`aligned-button`}
                  style={{
                    justifyContent: height == 8 ? "center" : "flex-end",
                    pointerEvents:
                      widgetId == localStorage.getItem("customId")
                        ? ""
                        : "none",
                  }}
                  onFocus={() => {
                    setShow((prev) => ({
                      ...prev,
                      button: true,
                      widget: false,
                    }));
                  }}
                  onClick={(e) => {
                    setItemIndexTypeCol({ index, type: "button", col: "col3" });
                    e.stopPropagation();
                  }}
                >
                  {editingMode.widgtId == widgetId && (
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                      style={{
                        position: "absolute",
                        top: -44, // Align to the top of the parent div
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)",
                        backgroundColor: "rgb(255, 255, 255,0.8)",
                        borderRadius: 20,
                        padding: 6,
                        paddingInline: 10,
                      }}
                    >
                      <div
                        className="handle "
                        style={{
                          cursor: "move",
                        }}
                      >
                        <img
                          src={Assets.dragIcon}
                          style={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                      <Trash2
                        onClick={() => {
                          setButton3((prev) => {
                            const temp = [...prev];
                            const updated = temp.filter(
                              (itm) => itm.id !== item.id
                            );
                            return updated;
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        size={20}
                        color="#000"
                      />
                    </div>
                  )}
                  <div className="submit-btn-give">
                    <input
                      style={{
                        // backgroundColor: item.bgColor,
                        background: getGradient(item.bgColor),
                        color: item.txtColor,
                        fontSize: Number(item.fontSize),
                        fontFamily:
                          item.family === "default"
                            ? universalStyles.font
                            : item.family
                            ? item.family
                            : "sans-serif",
                        fontStyle: item.isItalic ? "italic" : "normal",
                        textDecorationLine: item.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: item.isBold ? "bold" : "normal",
                      }}
                      type="submit"
                      value={item.text ? item.text : "Send"}
                    />
                  </div>
                </div>
              </Draggable>
            );
          })}
        {accordion3.length > 0 && (
          <Draggable
            axis="both"
            handle=".handle"
            defaultPosition={{ x: accordionCoords3.x, y: accordionCoords3.y }}
            position={null}
            grid={[25, 25]}
            scale={1}
            style={{ marginTop: 50 }}
            // disabled={isResizing}
            bounds="parent"
            onStop={(e, ui) => {
              setAccordionCoords3({ x: ui.x, y: ui.y });
            }}
            onDrag={() => {}}
          >
            <div className={`d-flex flex-column`}>
              {editingMode.widgtId == widgetId && (
                <div
                  className="d-flex align-items-center justify-content-center gap-2"
                  style={{
                    position: "absolute",
                    top: -44, // Align to the top of the parent div
                    left: "50%", // Center horizontally
                    transform: "translateX(-50%)",
                    backgroundColor: "rgb(255, 255, 255,0.8)",
                    borderRadius: 20,
                    padding: 6,
                    paddingInline: 10,
                  }}
                >
                  <div
                    className="handle"
                    style={{
                      cursor: "move",
                    }}
                    onMouseOver={() => {}}
                    onMouseLeave={() => {}}
                  >
                    <img
                      src={Assets.dragIcon}
                      style={{
                        height: 16,
                        width: 16,
                      }}
                    />
                  </div>
                  <Trash2
                    onClick={() => {
                      setAccordion3([]);
                    }}
                    style={{ cursor: "pointer" }}
                    size={20}
                    color="#000"
                  />
                </div>
              )}
              {accordion3.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    style={{
                      // header bgColor
                      backgroundColor: item.titleBgColor,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{
                        width: "100%",
                        // header font color
                        color: item.titleTextColor,
                      }}
                      expandIcon={<ChevronDown color="white" />}
                      aria-controls="panel1-content"
                      id={`panel${index}-header`}
                    >
                      <span
                        suppressContentEditableWarning
                        contentEditable={
                          widgetId == localStorage.getItem("customId")
                        }
                        data-placeholder="title..."
                        role="textbox"
                        // onFocus={() => {
                        //   setAccordionIndexAndType({ index, type: "title" });
                        //   setColumnClicked("3rd");
                        //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                        // }}
                        onBlur={(e) => {
                          setAccordion3((prev) => {
                            const updatedItem = prev.map((item, i) => {
                              if (index == i) {
                                return { ...item, title: e.target.innerText };
                              } else {
                                return item;
                              }
                            });
                            return updatedItem;
                          });
                        }}
                        onClick={(e) => {
                          setAccordionIndexAndType({ index, type: "title" });
                          setColumnClicked("3rd");
                          setItemIndexTypeCol({ index: "", type: "", col: "" });
                          e.stopPropagation();
                        }}
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                          outline: "unset",
                          fontSize: item.titleFontSize,
                          fontWeight: item.isTitleBold ? "bold" : "normal",
                          fontStyle: item.isTitleItalic ? "italic" : "normal",
                          textDecorationLine: item.isTitleUnderlined
                            ? "underline"
                            : "none",
                          fontFamily:
                            item.defaultTitleFamily == 1
                              ? universalStyles.font
                              : item.titleFamily
                              ? item.titleFamily
                              : "sans-serif",
                        }}
                      >
                        {item.title}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails
                      contentEditable={
                        widgetId == localStorage.getItem("customId")
                      }
                      style={{
                        backgroundColor: item.bodyBgColor,
                        color: item.bodyTextColor,
                        outline: "unset",
                        fontSize: item.bodyFontSize,
                        fontWeight: item.isBodyBold ? "bold" : "normal",
                        fontStyle: item.isBodyItalic ? "italic" : "normal",
                        textDecorationLine: item.isBodyUnderlined
                          ? "underline"
                          : "none",
                        fontFamily:
                          item.defaultBodyFamily == 1
                            ? universalStyles.font
                            : item.bodyFamily
                            ? item.bodyFamily
                            : "sans-serif",
                      }}
                      placeholder="detail..."
                      // onFocus={() => {
                      //   setAccordionIndexAndType({ index, type: "detail" });
                      //   setColumnClicked("3rd");
                      //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                      // }}
                      onBlur={(e) => {
                        setAccordion3((prev) => {
                          const updatedItem = prev.map((item, i) => {
                            if (index == i) {
                              return { ...item, bodyText: e.target.innerText };
                            } else {
                              return item;
                            }
                          });
                          return updatedItem;
                        });
                      }}
                      onClick={(e) => {
                        setAccordionIndexAndType({ index, type: "detail" });
                        setColumnClicked("3rd");
                        setItemIndexTypeCol({ index: "", type: "", col: "" });
                        e.stopPropagation();
                      }}
                    >
                      {item.bodyText}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Draggable>
        )}
        {widgetId == localStorage.getItem("customId") && fileBox3 && (
          <FileDrop
            setImage={setImage3}
            setImgFile={setImgFile3}
            height={height}
            width={width}
            borderRadius={0}
            styles={{
              borderTopRightRadius:
                border === "round" ? 20 : border === "sharp" ? 0 : null,
              borderBottomRightRadius:
                border === "round" ? 20 : border === "sharp" ? 0 : null,
            }}
          />
        )}
      </div>
      {/* Widget Toolbar */}
      {widgetId == localStorage.getItem("customId") &&
        (!tool1 || !tool2 || !tool3) &&
        columnClicked == "" &&
        itemIndexTypeCol.type == "" && (
          <WidgetTool
            isColumnOrRow={true}
            isDefault={!tool1 ? isDefault : !tool2 ? isDefault2 : isDefault3}
            setDefault={
              !tool1 ? setDefault : !tool2 ? setDefault2 : setDefault3
            }
            color={!tool1 ? color : !tool2 ? color2 : color3}
            setColor={!tool1 ? setColor : !tool2 ? setColor2 : setColor3}
            showFileBox={
              !tool1 ? showFileBox : !tool2 ? showFileBox2 : showFileBox3
            }
            fileBox={!tool1 ? fileBox : !tool2 ? fileBox2 : fileBox3}
            link={!tool1 ? link : !tool2 ? link2 : link3}
            setLink={!tool1 ? setLink : !tool2 ? setLink2 : setLink3}
            styles={{
              width: "auto",
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              right:
                width == "4" && pageId == 2 && !tool3
                  ? 10
                  : width == "2" && pageId == 1 && !tool3
                  ? 10
                  : (width == "2" && pageId == 2 && !tool2) ||
                    (width == "1" && pageId == 1 && !tool2)
                  ? 0
                  : (width == "4" && pageId == 2 && !tool2) ||
                    (width == "2" && pageId == 1 && !tool2)
                  ? "30%"
                  : (width == "2" && !tool3 && pageId == 2 && x !== 2) ||
                    (width == "1" && pageId == 1 && !tool3)
                  ? "-30%"
                  : width == "2" && !tool3 && pageId == 2 && x == 2
                  ? -100
                  : "unset",
              // left: !tool1 ? 10 : x == "2" && !tool3 ? 0 : "unset",
              left: !tool1 ? 10 : "unset",
            }}
            imagePosition={
              !tool1 ? imagePosition : !tool2 ? imagePosition2 : imagePosition3
            }
            setImagePosition={
              !tool1
                ? setImagePosition
                : !tool2
                ? setImagePosition2
                : setImagePosition3
            }
            setColorFromPicker={
              !tool1
                ? setColorFromPicker
                : !tool2
                ? setColorFromPicker2
                : setColorFromPicker3
            }
            colorFromPicker={
              !tool1
                ? colorFromPicker
                : !tool2
                ? colorFromPicker2
                : colorFromPicker3
            }
          />
        )}

      {/* Accordion Toolbar */}
      {widgetId == localStorage.getItem("customId") &&
        (columnClicked === "1st" ||
          columnClicked === "2nd" ||
          columnClicked === "3rd") &&
        itemIndexTypeCol.index == "" && (
          <AccordionToolbar
            itemIndexAndType={accordionIndexAndType}
            accordion={
              columnClicked === "1st"
                ? accordion1
                : columnClicked === "2nd"
                ? accordion2
                : accordion3
            }
            setAccordion={
              columnClicked === "1st"
                ? setAccordion1
                : columnClicked === "2nd"
                ? setAccordion2
                : setAccordion3
            }
            hide={() => {
              setColumnClicked("");
              setItemIndexTypeCol({ index: "", type: "", col: "" });
            }}
            styles={{
              // top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              // left:
              //   columnClicked === "3rd"
              //     ? "40%"
              //     : columnClicked === "2nd"
              //     ? 0
              //     : "25%",
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              right:
                width == "4" && pageId == 2 && columnClicked === "3rd"
                  ? 10
                  : width == "2" && pageId == 1 && columnClicked === "3rd"
                  ? 10
                  : (width == "2" && pageId == 2 && columnClicked === "2nd") ||
                    (width == "1" && pageId == 1 && columnClicked === "2nd")
                  ? 0
                  : (width == "4" && pageId == 2 && columnClicked === "2nd") ||
                    (width == "2" && pageId == 1 && columnClicked === "2nd")
                  ? "30%"
                  : (width == "2" &&
                      columnClicked === "3rd" &&
                      pageId == 2 &&
                      x !== 2) ||
                    (width == "1" && pageId == 1 && columnClicked === "3rd")
                  ? "-30%"
                  : width == "2" &&
                    columnClicked === "3rd" &&
                    pageId == 2 &&
                    x == 2
                  ? -100
                  : "unset",
              // left: !tool1 ? 10 : x == "2" && !tool3 ? 0 : "unset",
              left: columnClicked === "1st" ? 10 : "unset",
            }}
          />
        )}

      {/* TextToolbar */}
      {widgetId == localStorage.getItem("customId") &&
        itemIndexTypeCol.type !== "button" &&
        itemIndexTypeCol.index !== "" && (
          <ColTextToolbar
            itemIndexTypeCol={itemIndexTypeCol}
            text={
              itemIndexTypeCol.col === "col1" &&
              itemIndexTypeCol.type === "text"
                ? text1
                : itemIndexTypeCol.col === "col1" &&
                  itemIndexTypeCol.type === "heading"
                ? heading1
                : itemIndexTypeCol.col === "col1" &&
                  itemIndexTypeCol.type === "paragraph"
                ? para1
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "text"
                ? text2
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "heading"
                ? heading2
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "paragraph"
                ? para2
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "text"
                ? text3
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "heading"
                ? heading3
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "paragraph"
                ? para3
                : null
            }
            setText={
              itemIndexTypeCol.col === "col1" &&
              itemIndexTypeCol.type === "text"
                ? setText1
                : itemIndexTypeCol.col === "col1" &&
                  itemIndexTypeCol.type === "heading"
                ? setHeading1
                : itemIndexTypeCol.col === "col1" &&
                  itemIndexTypeCol.type === "paragraph"
                ? setPara1
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "text"
                ? setText2
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "heading"
                ? setHeading2
                : itemIndexTypeCol.col === "col2" &&
                  itemIndexTypeCol.type === "paragraph"
                ? setPara2
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "text"
                ? setText3
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "heading"
                ? setHeading3
                : itemIndexTypeCol.col === "col3" &&
                  itemIndexTypeCol.type === "paragraph"
                ? setPara3
                : null
            }
            styles={{
              // top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              // left:
              //   columnClicked === "3rd"
              //     ? "40%"
              //     : columnClicked === "2nd"
              //     ? 0
              //     : "25%",
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              right:
                width == "4" && pageId == 2 && itemIndexTypeCol.col === "col3"
                  ? 10
                  : width == "2" &&
                    pageId == 1 &&
                    itemIndexTypeCol.col === "col3"
                  ? 10
                  : (width == "2" &&
                      pageId == 2 &&
                      itemIndexTypeCol.col === "col2") ||
                    (width == "1" &&
                      pageId == 1 &&
                      itemIndexTypeCol.col === "col2")
                  ? 0
                  : (width == "4" &&
                      pageId == 2 &&
                      itemIndexTypeCol.col === "col2") ||
                    (width == "2" &&
                      pageId == 1 &&
                      itemIndexTypeCol.col === "col2")
                  ? "30%"
                  : (width == "2" &&
                      itemIndexTypeCol.col === "col3" &&
                      pageId == 2 &&
                      x !== 2) ||
                    (width == "1" &&
                      pageId == 1 &&
                      itemIndexTypeCol.col === "col3")
                  ? "-30%"
                  : width == "2" &&
                    itemIndexTypeCol.col === "col3" &&
                    pageId == 2 &&
                    x == 2
                  ? -100
                  : "unset",
              // left: !tool1 ? 10 : x == "2" && !tool3 ? 0 : "unset",
              left: itemIndexTypeCol.col === "col1" ? 10 : "unset",
            }}
            alignment={true}
          />
        )}
      {/* Button Toolbar */}
      {widgetId == localStorage.getItem("customId") &&
        itemIndexTypeCol.type === "button" && (
          <ColBtnToolbar
            itemIndexTypeCol={itemIndexTypeCol}
            button={
              itemIndexTypeCol.col === "col1"
                ? button1
                : itemIndexTypeCol.col === "col2"
                ? button2
                : button3
            }
            setButton={
              itemIndexTypeCol.col === "col1"
                ? setButton1
                : itemIndexTypeCol.col === "col2"
                ? setButton2
                : setButton3
            }
            styles={{
              top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
              bottom: "unset",
              right: x == 1 ? "2%" : "unset",
              left: x == 0 ? "20%" : "unset",
            }}
          />
        )}
    </div>
  );
};

export default ColumnWidget;
