import React, { useState, useEffect, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import TextToolbar from "../../../components/Toolbars/TextToolbar";
import FileDrop from "../../../components/Toolbars/FileDrop";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";

const AnnualBudgetWidget = ({
  edithandleClick,
  itemStyles,
  height,
  width,
  widgetId,
  deleteWidget,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const mainRef = useRef(null);
  const mainDimensions = useResizeObserver({ ref: mainRef });

  const joinRef = useRef(null);
  const joinDimensions = useResizeObserver({ ref: joinRef });

  const data = [
    { id: 1, name: "Outreach", percentage: "10" },
    { id: 2, name: "Operations", percentage: "60" },
    { id: 3, name: "Personnel", percentage: "10" },
    { id: 4, name: "Growth", percentage: "20" },
  ];

  useEffect(() => {
    if (joinDimensions) {
      setTextHeight(joinDimensions.height);
      setTextWidth(joinDimensions.width);
    }
  }, [joinDimensions]);

  const [fileBox, showFileBox] = useState(false);
  const [isEditText, setEditText] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [image, setImage] = useState("");
  const [imgFile, setImgFile] = useState("");

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [color, setColor] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
  });
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [border, setBorder] = useState("");
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const [defaultCorners, setDefaultCorners] = useState("true");
  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      // setImage(itemStyles.background_image);
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setDefaultCorners(itemStyles.defaultCorners);
      setLink(itemStyles.widget_link);
      setTextWidth(itemStyles.text_width);
      setTextHeight(itemStyles.text_height);
      setBorder(itemStyles.border);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const handleEditClick = (e) => {
    dispatch(updateTracker(true));
    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      setIsEditMode(true);
      setEditText(false);
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      dispatch(setEdit({}));
      setIsEditMode(false);
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };
  useEffect(() => {
    if (editingMode.widgtId !== widgetId) {
      showFileBox(false);
      setEditText(false);
      setIsEditMode(false);
    }
  }, [editingMode.widgtId]);

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  const [colorFromPicker, setColorFromPicker] = useState(false);
  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile("");
    }
  }, [colorFromPicker]);

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image;
    widgetData["background_image"] = imgFile ? imgFile : null;
    widgetData["background_color"] = color;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;
    widgetData["title_color"] = font.color;
    widgetData["title"] = title;
    widgetData["title_font"] = font.family;
    widgetData["title_font_size"] = font.size;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
    };

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;
    widgetData["border"] = border ? border : null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = textHeight;
    widgetData["text_width"] = textWidth;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    font,
    title,
    link,
    border,
    defaultCorners,
    isDefault,
    imagePosition,
    imageRepeat,
    textHeight,
    textWidth,
    imgFile,
  ]);

  //Span height
  function handleSpanChange(event, type) {
    const span = event.target;
    const width = span.offsetWidth;
    const height = span.offsetHeight;
    if (type === "text") {
      setTextWidth(width);
      setTextHeight(height);
    }
  }

  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  // console.log("Colo>", color);
  // console.log("PATH>", image);
  // console.log("FILE>", imgFile);

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          setEditText(false);
          e.stopPropagation();
        }
      }}
      id="joinGroup"
      ref={mainRef}
      className={`joinGroup ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      }`}
      style={{
        flexDirection: "column",
        ...((image == "0" || image == null || image == false) &&
          imgFile == "" && {
            background: `${
              isDefault && default_widget_colors.groups
                ? default_widget_colors.groups.is_default === "0"
                  ? default_widget_colors.groups.color
                  : default_widget_colors.groups.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
          }),
        // backgroundColor:
        //   isDefault && default_widget_colors.groups
        //     ? default_widget_colors.groups.is_default === "0"
        //       ? default_widget_colors.groups.color
        //       : default_widget_colors.groups.is_default === "1"
        //       ? mainColor
        //       : color
        //     : color,
        ...(((image !== "0" && image !== null && image !== false) ||
          imgFile !== "") && {
          // backgroundImage: `url(${image})`,
          backgroundImage: imgFile
            ? `url(${imgFile}`
            : `url(${process.env.REACT_APP_BASE_URL}${image}`,
          backgroundPosition: "center",
          backgroundRepeat: imageRepeat,
          backgroundSize: imagePosition,
        }),
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
    >
      {/* EDIT */}
      <div
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/*  */}
      <h2 style={{ color: "#fff" }}>Annual Budget</h2>
      {/* <span style={{color:'#fff',fontSize:22,fontWeight:'bold'}} >Annual Budget</span> */}
      <div className="d-flex col-md-12 align-items-center justify-content-center gap-2">
        {data &&
          data.map((item) => {
            return (
              <div
                className="d-flex flex-column col-md-3 align-items-center justify-content-center"
                style={{
                  border: "2px solid #fff",
                  borderRadius: 5,
                  padding: 20,
                }}
              >
                <h3 style={{ color: "#fff", fontWeight: "500" }}>
                  {item.name}
                </h3>
                <h3 style={{ color: "#fff", fontWeight: "500" }}>
                  {item.percentage}%
                </h3>
              </div>
            );
          })}
      </div>
      {editingMode.widgtId == widgetId && !isEditText && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          styles={{
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
            width: "unset",
          }}
          color={color}
          setColor={setColor}
          showFileBox={showFileBox}
          fileBox={fileBox}
          link={link}
          setLink={setLink}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setColorFromPicker={setColorFromPicker}
          colorFromPicker={colorFromPicker}
        />
      )}
      {isEditText && (
        <TextToolbar
          styles={{
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
          }}
          font={font}
          setFont={setFont}
        />
      )}
      {editingMode.widgtId == widgetId && fileBox && (
        <FileDrop
          height={height}
          width={width}
          borderRadius={border === "round" ? 20 : border === "sharp" ? 0 : null}
          setImage={setImage}
          setImgFile={setImgFile}
        />
      )}
    </div>
  );
};

export default AnnualBudgetWidget;
