import React from "react";
import "../../home/styles.css";

const Notes = ({borderRadius}) => {

  return (
    <div
      className={`chatBox`}
      style={{
        borderRadius: borderRadius?borderRadius:0,
        width:'75%',
        height:'100%'
      }}
    >
      <p style={{ color: "grey", fontSize: 16, margin: 20 }}>Take notes...</p>
      <div
        className="align-items-center justify-content-center"
        style={{
          backgroundColor: "#3478ff",
          alignSelf: "flex-end",
          margin: 20,
          borderRadius: 6,
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#ffffff",
            paddingInline: 20,
            paddingBlock: 10,
          }}
        >
          Watch Now
        </p>
      </div>
    </div>
  );
};

export default Notes;
