import { combineReducers } from "redux";
import bodyStylingReducer from "./slices/bodyStyling";
import loginReducer from "./slices/loginSlice";

const rootReducer = combineReducers({
  bodyStyling: bodyStylingReducer,
  login:loginReducer
});

export { rootReducer };
