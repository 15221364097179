import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";
import { ColorPicker } from "react-color-gradient-picker";
import "react-color-gradient-picker/dist/index.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ColBtnToolbar = ({ itemIndexTypeCol, button, setButton, styles }) => {
  const [showInput, setInput] = useState(false);
  const [picker, setPicker] = useState(false);
  const [txtPicker, setTxtPicker] = useState(false);
  const [linkInput, showLinkInput] = useState(false);
  const inputRef = useRef(null);
  const colorPickerRef = useRef(null);
  const [colorType, setColorType] = useState("solid");

  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
    setTxtPicker(false);
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [miniToolbar, setMiniToolbar] = useState(false);

  const gradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const mainDivRef = useRef(null);

  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    setButton((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            family: selectedFont,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };
  const incrementFontSize = () => {
    setButton((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            fontSize: Number(item.fontSize) + 1,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  const decrementFontSize = () => {
    setButton((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            fontSize: Math.max(item.fontSize - 1, 1),
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  return (
    <div ref={mainDivRef} style={styles} className="buttonToolBar">
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          {showInput ? (
            <input
              placeholder="type here"
              className="btnInput"
              style={{ width: 110 }}
              type="text"
              value={button[itemIndexTypeCol.index].text}
              maxLength={15}
              onChange={(e) =>
                setButton((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexTypeCol.index) {
                      return {
                        ...item,
                        text: e.target.value,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                })
              }
            />
          ) : !linkInput ? (
            <h4>Button Toolbar</h4>
          ) : (
            <input
              style={{ width: 110 }}
              placeholder="enter link"
              className="btnInput"
              type="text"
              value={button[itemIndexTypeCol.index].link}
              onChange={(e) =>
                setButton((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexTypeCol.index) {
                      return {
                        ...item,
                        link: e.target.value,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                })
              }
            />
          )}
        </div>
        <div className="toolBarRight">
          <div
            onClick={() => {
              // setPicker(!picker);
              setTxtPicker(false);
              setMiniToolbar(!miniToolbar);
            }}
            className="bucketIcon"
          >
            <img src={Assets.paintbucket1} alt="Edit Image" />
          </div>
          {miniToolbar && (
            <div
              className="d-flex align-items-center"
              style={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: -20,
                backgroundColor: "#383939",
                padding: 4,
                borderRadius: 6,
                left: "20%",
              }}
            >
              <div className="fontList">
                <div style={{ cursor: "pointer" }} className="fillselect">
                  <p
                    onClick={handleClick}
                    style={{
                      color: "#ffffff",
                      fontSize: 13,
                      padding: 0,
                      margin: 0,
                      marginTop: 3,
                      paddingInline: 8,
                      borderRadius: 4,
                      backgroundColor: "gray",
                    }}
                  >
                    Custom
                  </p>
                </div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  selected={colorType === "solid" ? true : false}
                  onClick={() => {
                    setPicker(true);
                    setColorType("solid");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "solid" ? "dimgrey" : "transparent",
                  }}
                >
                  solid
                </MenuItem>
                <MenuItem
                  selected={colorType === "linear" ? true : false}
                  onClick={() => {
                    setPicker(true);
                    setColorType("linear");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "linear" ? "dimgrey" : "transparent",
                  }}
                >
                  gradient
                </MenuItem>
              </Menu>
            </div>
          )}
          <div
            onClick={() => {
              setInput(!showInput);
              showLinkInput(false);
            }}
            className="linkIcon"
            style={{
              backgroundColor: showInput ? "dimgrey" : "",
              borderRadius: 4,
            }}
          >
            <img src={Assets.editText} alt="Edit Image" />
          </div>
          {/* <Popup
            // lockscroll works only with modal
            // lockScroll={true}
            // modal
            ref={ref}
            position="bottom center"
            onOpen={() => {
              setPicker(!picker);
            }}
            // modal={true}
            trigger={
              <div
                onClick={() => {
                  setPicker(true);
                }}
                className="bucketIcon"
              >
                <img src={Assets.paintbucket1} alt="Edit Image" />
              </div>
            }
          >
            {(close) => (
              <div className="fontList">
                <div className="fillselect">
                  <select
                    style={{ minWidth: 80 }}
                    value={colorType}
                    onChange={(e) => {
                      close();
                      setPicker(true);
                      setColorType(e.target.value);
                    }}
                  >
                    <option value="solid">solid</option>
                    <option value="linear">linear</option>
                  </select>
                </div>
              </div>
            )}
          </Popup> */}
          <div className="d-flex align-tems-center justify-content-center ">
            <button className="counterBtn" onClick={decrementFontSize}>
              -
            </button>
            <input
              ref={inputRef}
              //   onChange={handleInputChange}
              className="fontCounter"
              type="text"
              value={`${
                button[itemIndexTypeCol.index] &&
                button[itemIndexTypeCol.index].fontSize
                  ? button[itemIndexTypeCol.index].fontSize
                  : 0
              }px`}
            />
            <button className="counterBtn" onClick={incrementFontSize}>
              +
            </button>
          </div>
          <div className="fontList">
            <div className="fillselect">
              <select
                style={{ minWidth: 80 }}
                value={
                  button[itemIndexTypeCol.index]
                    ? button[itemIndexTypeCol.index].family
                    : "default"
                }
                onChange={handleFontChange}
              >
                <option value="default">Default</option>
                <option value="gilroymedium">Gilroy</option>
                <option value="frontageregular">Frontage</option>
                <option value="playfair display">Playfair Display</option>
                <option value="rockwellregular">Rockwell</option>
              </select>
            </div>
          </div>
          <div
            onClick={() => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isBold: !item.isBold,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            className="boldIcon"
            style={{
              backgroundColor:
                button[itemIndexTypeCol.index] &&
                button[itemIndexTypeCol.index].isBold
                  ? "dimgrey"
                  : "",
              borderRadius: 6,
            }}
          >
            <img src={Assets.boldbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isItalic: !item.isItalic,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            style={{
              backgroundColor:
                button[itemIndexTypeCol.index] &&
                button[itemIndexTypeCol.index].isItalic
                  ? "dimgrey"
                  : "",
              borderRadius: 6,
            }}
            className="italicIcon"
          >
            <img src={Assets.italicbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isUnderlined: !item.isUnderlined,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            style={{
              backgroundColor:
                button[itemIndexTypeCol.index] &&
                button[itemIndexTypeCol.index].isUnderlined
                  ? "dimgrey"
                  : "",
              borderRadius: 6,
            }}
            className="TIcon"
          >
            <img src={Assets.Tbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setInput(false);
              showLinkInput(!linkInput);
            }}
            className="linkIcon"
            style={{
              backgroundColor: linkInput ? "dimgrey" : "",
              borderRadius: 4,
              // border:'2px solid grey',
            }}
          >
            <img style={{ height: 28 }} src={Assets.link} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setTxtPicker(!txtPicker);
              setPicker(false);
            }}
            className="colorCircle"
          >
            <span
              style={{
                backgroundColor: button[itemIndexTypeCol.index]
                  ? button[itemIndexTypeCol.index].txtColor
                  : "transparent",
              }}
              className="blue"
            ></span>
          </div>
        </div>
      </div>
      {picker && (
        <div ref={colorPickerRef}>
          <ColorPicker
            onStartChange={(e) => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      bgColor: e,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            onChange={(e) => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      bgColor: e,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            onEndChange={(e) => {
              setButton((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      bgColor: e,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            gradient={
              colorType === "solid"
                ? button[itemIndexTypeCol.index].bgColor
                : gradient
            }
            isGradient={colorType === "linear" ? true : false}
          />
        </div>
        // <SketchPicker
        //   ref={colorPickerRef}
        //   className="colorPicker"
        //   color={button[itemIndexTypeCol.index].bgColor}
        //   onChangeComplete={(color) => {
        //     setButton((prev) => {
        //       const updatedArray = prev.map((item, index) => {
        //         if (index === itemIndexTypeCol.index) {
        //           return {
        //             ...item,
        //             bgColor: color.hex,
        //           };
        //         }
        //         return item;
        //       });
        //       return updatedArray;
        //     });
        //   }}
        // />
      )}
      {txtPicker && (
        <SketchPicker
          ref={colorPickerRef}
          className="colorPicker"
          color={button[itemIndexTypeCol.index].txtColor}
          onChangeComplete={(color) => {
            setButton((prev) => {
              const updatedArray = prev.map((item, index) => {
                if (index === itemIndexTypeCol.index) {
                  return {
                    ...item,
                    txtColor: color.hex,
                  };
                }
                return item;
              });
              return updatedArray;
            });
          }}
          // styles={{ default: { picker: { zoom: "125%" } } }}
        />
      )}
    </div>
  );
};

export default ColBtnToolbar;
