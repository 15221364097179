import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";
import { PaintBucket } from "lucide-react";

const AccordionToolbar = ({
  itemIndexAndType,
  accordion,
  setAccordion,
  styles,
  hide,
}) => {
  const [picker, setPicker] = useState(false);
  const inputRef = useRef(null);

  const colorPickerRef = useRef(null);
  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
  });

  const mainDivRef = useRef(null);
  useOutsideClick(mainDivRef, () => {
    hide && hide();
  });

  // const handleFontChange = (e) => {
  //   const selectedFont = e.target.value;
  // };

  const incrementFontSize = () => {
    if (itemIndexAndType.type === "title") {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              titleFontSize: Number(item.titleFontSize) + 1,
            };
          }
          return item;
        });
        return updatedArray;
      });
    } else {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              bodyFontSize: Number(item.bodyFontSize) + 1,
            };
          }
          return item;
        });
        return updatedArray;
      });
    }
  };

  const decrementFontSize = () => {
    if (itemIndexAndType.type === "title") {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              titleFontSize: Math.max(item.titleFontSize - 1, 1),
            };
          }
          return item;
        });
        return updatedArray;
      });
    } else {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              bodyFontSize: Math.max(item.bodyFontSize - 1, 1),
            };
          }
          return item;
        });
        return updatedArray;
      });
    }
  };

  // const handleInputChange = (e) => {
  // const value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
  // const updatedFont = { ...font, size: parseInt(value === "" ? 0 : value) };
  // setFont(updatedFont);
  // Set cursor position before 'px'
  // inputRef.current.setSelectionRange(value.length, value.length);
  // };

  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    if (itemIndexAndType.type === "title") {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              titleFamily: selectedFont,
              defaultTitleFamily: selectedFont === "default" ? 1 : 0,
            };
          }
          return item;
        });
        return updatedArray;
      });
    } else {
      setAccordion((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === itemIndexAndType.index) {
            return {
              ...item,
              bodyFamily: selectedFont,
              defaultBodyFamily: selectedFont === "default" ? 1 : 0,
            };
          }
          return item;
        });
        return updatedArray;
      });
    }
  };

  return (
    <div
      ref={mainDivRef}
      style={styles}
      // className={`textToolBar`}
      className={`accordionToolbar`}
    >
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          <h4>Toolbar</h4>
        </div>
        <div className="toolBarRight">
          {/* font size */}
          <div className="d-flex align-tems-center justify-content-center ">
            <button className="counterBtn" onClick={decrementFontSize}>
              -
            </button>
            <input
              ref={inputRef}
              // onChange={handleInputChange}
              className="fontCounter"
              disabled
              type="text"
              value={
                itemIndexAndType.type === "title"
                  ? `${accordion[itemIndexAndType.index].titleFontSize}px`
                  : `${accordion[itemIndexAndType.index].bodyFontSize}px`
              }
            />
            <button className="counterBtn" onClick={incrementFontSize}>
              +
            </button>
          </div>
          {/* FONT FAMILY */}
          <div className="fontList">
            <div className="fillselect">
              <select
                style={{ minWidth: 80 }}
                value={
                  itemIndexAndType.type === "title" &&
                  accordion[itemIndexAndType.index].defaultTitleFamily == 1
                    ? "default"
                    : itemIndexAndType.type === "title" &&
                      accordion[itemIndexAndType.index].defaultTitleFamily == 0
                    ? accordion[itemIndexAndType.index].titleFamily
                    : itemIndexAndType.type === "detail" &&
                      accordion[itemIndexAndType.index].defaultBodyFamily == 1
                    ? "default"
                    : accordion[itemIndexAndType.index].bodyFamily
                }
                onChange={handleFontChange}
              >
                <option value="default">Default</option>
                <option value="gilroymedium">Gilroy</option>
                <option value="frontageregular">Frontage</option>
                <option value="playfair display">Playfair Display</option>
                <option value="rockwellregular">Rockwell</option>
              </select>
            </div>
          </div>
          {/* BOLD */}
          <div
            onClick={() => {
              if (itemIndexAndType.type === "title") {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isTitleBold: !item.isTitleBold,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              } else {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isBodyBold: !item.isBodyBold,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              }
            }}
            className="boldIcon"
            style={{
              backgroundColor:
                itemIndexAndType.type === "title" &&
                accordion[itemIndexAndType.index].isTitleBold
                  ? "dimgrey"
                  : itemIndexAndType.type === "detail" &&
                    accordion[itemIndexAndType.index].isBodyBold
                  ? "dimgrey"
                  : "",

              borderRadius: 6,
            }}
          >
            <img src={Assets.boldbutton} alt="Edit Image" />
          </div>
          {/* ITALIC */}
          <div
            onClick={() => {
              if (itemIndexAndType.type === "title") {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isTitleItalic: !item.isTitleItalic,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              } else {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isBodyItalic: !item.isBodyItalic,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              }
            }}
            style={{
              backgroundColor:
                itemIndexAndType.type === "title" &&
                accordion[itemIndexAndType.index].isTitleItalic
                  ? "dimgrey"
                  : itemIndexAndType.type === "detail" &&
                    accordion[itemIndexAndType.index].isBodyItalic
                  ? "dimgrey"
                  : "",
              borderRadius: 6,
            }}
            className="italicIcon"
          >
            <img src={Assets.italicbutton} alt="Edit Image" />
          </div>
          {/* UNDERLINED */}
          <div
            onClick={() => {
              if (itemIndexAndType.type === "title") {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isTitleUnderlined: !item.isTitleUnderlined,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              } else {
                setAccordion((prev) => {
                  const updatedArray = prev.map((item, index) => {
                    if (index === itemIndexAndType.index) {
                      return {
                        ...item,
                        isBodyUnderlined: !item.isBodyUnderlined,
                      };
                    }
                    return item;
                  });
                  return updatedArray;
                });
              }
            }}
            style={{
              backgroundColor:
                itemIndexAndType.type === "title" &&
                accordion[itemIndexAndType.index].isTitleUnderlined
                  ? "dimgrey"
                  : itemIndexAndType.type === "detail" &&
                    accordion[itemIndexAndType.index].isBodyUnderlined
                  ? "dimgrey"
                  : "",
              borderRadius: 6,
            }}
            className="TIcon"
          >
            <img src={Assets.Tbutton} alt="Edit Image" />
          </div>
          <div onClick={() => setPicker("text")} className="colorCircle">
            <span
              className="white"
              style={{
                backgroundColor:
                  itemIndexAndType.type === "title"
                    ? accordion[itemIndexAndType.index].titleTextColor
                    : accordion[itemIndexAndType.index].bodyTextColor,
              }}
            />
          </div>
          <div
            onClick={() => {
              setPicker("background");
            }}
            className="bucketIcon"
          >
            <PaintBucket
              size={30}
              color={
                itemIndexAndType.type === "title"
                  ? accordion[itemIndexAndType.index].titleBgColor
                  : accordion[itemIndexAndType.index].bodyBgColor
              }
            />
            {/* <img src={Assets.paintbucket1} alt="Edit Image" /> */}
          </div>
        </div>
      </div>
      {(picker === "text" || picker === "background") && (
        <SketchPicker
          // styles={{ default: { picker: { zoom: "125%" } } }}
          ref={colorPickerRef}
          className="colorPicker"
          color={
            picker === "text" && itemIndexAndType.type === "title"
              ? accordion[itemIndexAndType.index].titleTextColor
              : picker === "text" && itemIndexAndType.type === "detail"
              ? accordion[itemIndexAndType.index].bodyTextColor
              : picker === "background" && itemIndexAndType.type === "title"
              ? accordion[itemIndexAndType.index].titleBgColor
              : accordion[itemIndexAndType.index].bodyBgColor
          }
          onChangeComplete={(color) => {
            if (picker === "text") {
              setAccordion((prev) => {
                const updated = prev.map((item, index) => {
                  if (index == itemIndexAndType.index) {
                    if (itemIndexAndType.type === "title") {
                      return { ...prev[index], titleTextColor: color.hex };
                    } else {
                      return { ...prev[index], bodyTextColor: color.hex };
                    }
                  } else {
                    return item;
                  }
                });
                return updated;
              });
            } else {
              setAccordion((prev) => {
                const updated = prev.map((item, index) => {
                  if (index == itemIndexAndType.index) {
                    if (itemIndexAndType.type === "title") {
                      return { ...prev[index], titleBgColor: color.hex };
                    } else {
                      return { ...prev[index], bodyBgColor: color.hex };
                    }
                  } else {
                    return item;
                  }
                });
                return updated;
              });
            }
          }}
        />
      )}
    </div>
  );
};

export default AccordionToolbar;
