import axios from "axios";
const API_SERVER_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

export const postRequest = async ({ endpoint, payload }) => {
  const token = localStorage.getItem("accessToken");
  let response = await axios.post(`${API_SERVER_URL}${endpoint}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getRequest = async ({ endpoint }) => {
  const token = localStorage.getItem("accessToken");
  let response = await axios.get(`${API_SERVER_URL}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const putRequest = async ({ endpoint, payload }) => {
  const token = localStorage.getItem("accessToken");
  let response = await axios.put(`${API_SERVER_URL}${endpoint}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const patchRequest = async ({ endpoint, payload }) => {
  const token = localStorage.getItem("accessToken");
  let response = await axios.patch(`${API_SERVER_URL}${endpoint}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteRequest = async ({ endpoint }) => {
  const token = localStorage.getItem("accessToken");
  let response = await axios.delete(`${API_SERVER_URL}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
