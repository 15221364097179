import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
//Component
import ProtectedRoute from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";
//Pages
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Watch from "./pages/watch/Watch";
import WatchPage from "./pages/watchPage/WatchPage";
import Giving from "./pages/giving/Giving";
import GroupPage from "./pages/groupPage/GroupPage";
import TeamPage from "./pages/TeamPage/TeamPage";
import ServePage from "./pages/ServePage/ServePage";
import KidsPage from "./pages/KidsPage";
import GivingLogout from "./pages/giving-logout/GivingLogout";
import MobileHomePage from "./pages/MobileHomePage";
import MobileWatchPage from "./pages/MobileWatchPage";
import MobileGivingPage from "./pages/MobileGivingPage";
import MobileGroupPage from "./pages/MobileGroupPage";
import MobileTeamPage from "./pages/MobileTeamPage";
import MobileServePage from "./pages/MobileServePage";
import MobileKidsPage from "./pages/MobileKidsPage";
import MobileGuestGiving from "./pages/MobileGuestGivingPage";

const AppRoutes = () => {
  //  To Remove customId from localStorage
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the item from local storage
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
    };
    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Remove event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<PublicRoute component={Login} />} />
      <Route path="/home" element={<ProtectedRoute component={Home} />} />
      <Route path="/watch" element={<ProtectedRoute component={WatchPage} />} />
      <Route path="/giving" element={<ProtectedRoute component={Giving} />} />
      <Route path="/group" element={<ProtectedRoute component={GroupPage} />} />
      <Route path="/team" element={<ProtectedRoute component={TeamPage} />} />
      <Route path="/serve" element={<ProtectedRoute component={ServePage} />} />
      <Route path="/kids" element={<ProtectedRoute component={KidsPage} />} />
      <Route
        path="/userGiving"
        element={<ProtectedRoute component={GivingLogout} />}
      />
      {/* Mobile Routes */}
      <Route
        path="/mobile-home"
        element={<ProtectedRoute component={MobileHomePage} />}
      />
      <Route
        path="/mobile-watch"
        element={<ProtectedRoute component={MobileWatchPage} />}
      />
      <Route
        path="/mobile-giving"
        element={<ProtectedRoute component={MobileGivingPage} />}
      />
      <Route
        path="/mobile-group"
        element={<ProtectedRoute component={MobileGroupPage} />}
      />
      <Route
        path="/mobile-team"
        element={<ProtectedRoute component={MobileTeamPage} />}
      />
      <Route
        path="/mobile-serve"
        element={<ProtectedRoute component={MobileServePage} />}
      />
      <Route
        path="/mobile-kids"
        element={<ProtectedRoute component={MobileKidsPage} />}
      />
      <Route
        path="/mobileUserGiving"
        element={<ProtectedRoute component={MobileGuestGiving} />}
      />
    </Routes>
  );
};

export default AppRoutes;
