import React, { useState } from "react";
import Assets from "../../assets/images";

function Watch(props) {
  const { darkMode } = props;
  const [iseditMode, setIseditMode] = useState(false);
  const edithandleClick = () => {
    setIseditMode(!iseditMode);
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.add("hide");
    sidebaroption2.classList.add("hide");
    widgetMain.classList.add("show");
  };
  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="container">
        <div className="row m-2 my-4">
          <div className="col-md-8 video-home">
            <div className="video-banner-bg">
              <div className="editdeletebtn">
                <div className="deletebtn">
                  <img src={Assets.deletebtn} alt="Delete Image" />
                </div>
                <div className="editbtn" onClick={edithandleClick}>
                  <img src={Assets.editicon} alt="Edit Image" />
                </div>
              </div>
              <div className="play-btn">
                <img src={Assets.playimg} alt="PLay Image" />
              </div>
              <div className="video-content-has">
                <div className="banner-title">
                  <h2>
                    Watch the<br></br>
                    whole experience
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 msg-main-box">
            <div className="msg-inner-box">
              <div className="editdeletebtn">
                <div className="deletebtn">
                  <img src={Assets.deletebtn} alt="Delete Image" />
                </div>
                <div className="editbtn" onClick={edithandleClick}>
                  <img src={Assets.editicon} alt="Edit Image" />
                </div>
              </div>
              <div className="messages-list-dark">
                <ul>
                  <li>
                    <span>Emily Brown</span>Great Show
                  </li>
                  <li>
                    <span>Sarah Collin</span>Hello Guys. I am sending the link
                  </li>
                  <li>
                    <span>Hamdan S.</span>Lorem ipsum dolor sit amet, consetetur
                    sadipscing elitr, sed diam nonumy eirmod tempor. invidunt ut
                    labore.
                  </li>
                  <li>
                    <span>Alicia John</span>sed diam nonumy eirmod tempor
                    invidunt ut labore. Lorem ipsum dolor sit amet, consetetur
                    sadipscing elitr.
                  </li>
                </ul>
              </div>
              <div className="typing-msg">
                <div className="typbox">
                  <input type="text" placeholder="Type Something" />
                </div>
                <div className="btn-submit">
                  <input type="submit" value="Send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Watch;
