import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import { getGradient } from "../../../utills/getGradient";
import { Play, Volume2 } from "lucide-react";

const TeamDetailWidget = ({
  edithandleClick,
  itemStyles,
  height,
  width,
  widgetId,
  deleteWidget,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const [fileBox, showFileBox] = useState(false);
  const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const [imgFile, setImgFile] = useState("");

  const [isEditText, setEditText] = useState(false);
  const [isEditDate, setEditDate] = useState(false);
  const [isEditSubText, setEditSubText] = useState(false);
  const [link, setLink] = useState("");
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const songsData = [
    { id: 1, name: "Unto The Lamb" },
    { id: 2, name: "Battle Belongs" },
    { id: 3, name: "Worthy" },
    { id: 4, name: "Holy Ground" },
    { id: 5, name: "Faithful" },
    { id: 6, name: "Same God" },
    { id: 7, name: "Welcome Resurrection" },
    { id: 11, name: "Unto The Lamb" },
    { id: 23, name: "Battle Belongs" },
    { id: 34, name: "Worthy" },
    { id: 44, name: "Holy Ground" },
    { id: 53, name: "Faithful" },
    { id: 62, name: "Same God" },
    { id: 71, name: "Welcome Resurrection" },
  ];

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      subfont.isDefaultFont == 1 &&
        setSubFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      dateFont.isDefaultFont == 1 &&
        setDateFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setImage(itemStyles.background_image);
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      if (itemStyles.date_font_style) {
        try {
          const dateStyle = JSON.parse(itemStyles.date_font_style);
          setDateFont({
            ...dateStyle,
            color: itemStyles.date_font_color,
            family: itemStyles.date_font,
            size: itemStyles.date_font_size,
          });
        } catch (error) {
          const dateStyle = itemStyles.date_font_style;
          setDateFont({
            ...dateStyle,
            color: itemStyles.date_font_color,
            family: itemStyles.date_font,
            size: itemStyles.date_font_size,
          });
        }
      }
      if (itemStyles.description_font_style) {
        try {
          const descriptionStyle = JSON.parse(
            itemStyles.description_font_style
          );
          setSubFont({
            ...descriptionStyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        } catch (error) {
          const descriptionStyle = itemStyles.description_font_style;
          setSubFont({
            ...descriptionStyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        }
      }
      setLink(itemStyles.widget_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 30,
    isDefaultFont: 1,
  });
  const [subfont, setSubFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 10,
    isDefaultFont: 1,
  });
  const [dateFont, setDateFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 10,
    isDefaultFont: 1,
  });

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  useEffect(() => {
    if (editingMode.widgtId !== widgetId.toString()) {
      showFileBox(false);
      setEditText(false);
      setEditDate(false);
      setEditSubText(false);
    }
  }, [editingMode.widgtId]);

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  const handleEditClick = (e) => {
    dispatch(updateTracker(true))

    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(
        setEdit({
        })
      );
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      dispatch(setEdit({}));
      showFileBox(false);
      setEditText(false);
      setEditDate(false);
      setEditSubText(false);
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image;
    widgetData["background_image"] = imgFile ? imgFile : null;
    widgetData["background_color"] = color;
    widgetData["border"] = border ? border : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;
    widgetData["title_color"] = font.color;
    widgetData["title"] = null;
    widgetData["title_font"] = font.family;
    widgetData["title_font_size"] = font.size;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
    };

    widgetData["date_font"] = dateFont.family;
    widgetData["date_font_color"] = dateFont.color;
    widgetData["date_font_size"] = dateFont.size;
    widgetData["date_font_style"] = {
      isBold: dateFont.isBold,
      isUnderlined: dateFont.isUnderlined,
      isItalic: dateFont.isItalic,
      isDefaultFont: dateFont.isDefaultFont,
    };

    widgetData["description"] = null;
    widgetData["description_color"] = subfont.color;
    widgetData["description_font"] = subfont.family;
    widgetData["description_font_size"] = subfont.size;
    widgetData["description_font_style"] = {
      isBold: subfont.isBold,
      isUnderlined: subfont.isUnderlined,
      isItalic: subfont.isItalic,
      isDefaultFont: subfont.isDefaultFont,
    };

    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    font,
    link,
    subfont,
    dateFont,
    border,
    defaultCorners,
    isDefault,
    imagePosition,
    imageRepeat,
    imgFile,
  ]);

  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          e.stopPropagation();
        }
      }}
      className={`d-flex team-detail ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      } `}
      style={{
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
    >
      {/* Edit */}
      <div className={`editdeletebtn hover-div ${isHovered ? "hovered" : ""}`}
       onMouseEnter={() => {
        dispatch(setStopDrag("yes"));
      }}
      onMouseLeave={() => {
        if (editingMode.widgtId !== widgetId) {
          dispatch(setStopDrag("no"));
        }
      }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(
                setEdit({
                })
              );
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/*  */}
      <div
        className="d-flex col-md-8 flex-column"
        style={{ backgroundColor: "#171717", borderRadius: 15, padding: 15 }}
      >
        <div className="d-flex row">
          {/* img */}
          <div className="col-md-4">
            <img
              src={Assets.Event1}
              style={{
                height: 200,
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
          </div>
          {/* details */}
          <div className="d-flex col-md-8 flex-column gap-2">
            <h6 className="title-text">Redefine Nights On Tour</h6>
            <p className="sub-text">
              Redefine Nights on Tour is a time where we can connect with our
              community.
            </p>
            <p className="sub-text">08-Aug-2025</p>
          </div>
        </div>
        {/* songs */}
        <div className="d-flex flex-column gap-2 mt-4" style={{overflow:'hidden'}} >
          {songsData.map((item) => {
            return (
              <div
                key={item.id}
                style={{
                  backgroundColor: "#1d1e1e",
                  paddingBlock: 15,
                  paddingLeft: 20,
                }}
                className="d-flex align-items-center"
              >
                <Play fill="#343434" color="#343434" size={26} />
                <span
                  style={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: "sans-serif",
                    marginInline: 30,
                  }}
                >
                  {item.name}
                </span>
                <img src={Assets.Speaker} style={{width:20,height:20}} />
                {/* <Volume2 fill='#1d1e1e' color="#fff" size={25} strokeWidth={2}/> */}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="team-right-col d-flex col-md-4 flex-column gap-3"
        style={{ overflow: "scroll",paddingLeft:10 }}
      >
        {/* 1st */}
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            borderRadius: 15,
            padding: 10,
            backgroundColor: "#171717",
            height:240
          }}
        >
          <p style={{color:'#fff',fontWeight:'bold',margin:0,marginBottom:4}} >Blessed and Blessing</p>
          <p style={{color:'#a5a5a5'}}>2025-03-12 | 12:30 PM</p>
          <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
            <button style={{color:'#fff',backgroundColor:'#28a830',padding:8}} >
                Accept
            </button>
            <button style={{color:'#fff',backgroundColor:'#ff3434',padding:8}}>
                Decline
            </button>
          </div>
        </div>
        {/* 2nd */}
        <div
          className="selected-event"
          style={{
            borderRadius: 15,
            padding: 10,
            backgroundColor: "#171717",
            position: "relative",
            borderTopLeftRadius:0,
            borderBottomLeftRadius:0
          }}
        >
          <img src={Assets.Event1} style={{ height: 220, width: "100%" }} />
          <div className="imageInnerText">
            <h4>Redefine Nights On Tour</h4>
          </div>
        </div>
        {/* 3rd */}
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            borderRadius: 15,
            padding: 10,
            backgroundColor: "#171717",
            height:240
          }}
        >
          <p style={{color:'#fff',fontWeight:'bold',margin:0,marginBottom:4}} >Blessed and Blessing</p>
          <p style={{color:'#a5a5a5'}}>2025-03-12 | 12:30 PM</p>
          <div className="d-flex align-items-center justify-content-center gap-3 mt-2">
            <button style={{color:'#fff',backgroundColor:'#28a830',padding:8}} >
                Accept
            </button>
            <button style={{color:'#fff',backgroundColor:'#ff3434',padding:8}}>
                Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailWidget;
