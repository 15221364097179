import React, { useRef, useEffect } from "react";
import useResizeObserver from "use-resize-observer";

const SpanComp = ({
  item,
  setText,
  widgetId,
  index,
  handleSpanChange,
  col,
  default_widget_colors,
  mainDimensions1,
  isVisible,
  universalStyles,
  type,
  setItemIndexTypeCol,
}) => {
  const spanRef = useRef(null);
  const textDimensions = useResizeObserver({ ref: spanRef });

  useEffect(() => {
    if (textDimensions) {
      const { height, width } = textDimensions;
      setText((prev) => {
        const updatedItem = prev.map((item, i) => {
          if (index == i) {
            return { ...item, height, width };
          } else {
            return item;
          }
        });
        return updatedItem;
      });
    }
  }, [textDimensions]);

  return (
    <span
      ref={spanRef}
      id={`${widgetId.toString()}_headingSpan`}
      role="textbox"
      data-placeholder={`${type}...`}
      contentEditable
      onBlur={(e) => {
        setText((prev) => {
          const updatedItem = prev.map((item, i) => {
            if (index == i) {
              return { ...item, text: e.target.innerText };
            } else {
              return item;
            }
          });
          return updatedItem;
        });
      }}
      onInput={(e) => handleSpanChange(e, col, index, type)}
      onClick={(e) => {
        setItemIndexTypeCol({ index, type, col });
        e.stopPropagation();
      }}
      suppressContentEditableWarning
      style={{
        fontSize: Number(item.fontSize),
        color:
          item.isDefaultColor && type === "text"
            ? default_widget_colors.text.color
            : item.isDefaultColor && type === "heading"
            ? default_widget_colors.heading.color
            : item.isDefaultColor && type === "paragraph"
            ? default_widget_colors.paragraph.color
            : item.color,
        textDecorationLine: item.isUnderlined ? "underline" : "none",
        fontWeight: item.isBold ? "bold" : "normal",
        fontStyle: item.isItalic ? "italic" : "normal",
        fontFamily:
          item.family === "default"
            ? universalStyles.font
            : item.family
            ? item.family
            : "sans-serif",
        borderColor:
          widgetId == localStorage.getItem("customId")
            ? "#BE9494"
            : "transparent",
        resize:
          widgetId == localStorage.getItem("customId") && !isVisible.heading
            ? "both"
            : "none",
        backgroundColor: "transparent",
        overflow: "hidden",
        maxHeight: mainDimensions1.height,
        maxWidth: mainDimensions1.width / 3 - 30,
        height: item.height,
        width: item.width,
        pointerEvents:
          widgetId == localStorage.getItem("customId") ? "" : "none",
        textAlign: item.direction ? item.direction : "left",
      }}
    >
      {item.text}
    </span>
  );
};

export default SpanComp;
