import React, { useState } from "react";
import "../../home/styles.css";
import { Send } from "lucide-react";

const Chat = ({borderRadius}) => {
  const [border, setBorder] = useState("");

  const messages = [
    { id: 1, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
    { id: 2, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 3, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
    { id: 4, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
    { id: 5, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 6, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
    { id: 1, name: "Admin", msg: "Hi, how are you?", time: "5 min" },
    { id: 7, name: "Jane doe", msg: "I'm good, what about u?", time: "1 hour" },
    { id: 8, name: "Jack Garet", msg: "ola, como estas?", time: "2 days" },
  ];

  return (
    <div
      className={`chatBox`}
      style={{
        borderRadius: borderRadius?borderRadius:0,
        width:'25%',
        height:'100%'
      }}
    >
      <div style={{ overflow: "hidden" }}>
        {messages.map((item) => {
          return (
            <div className="mb-2">
              <div
                className="d-flex justify-content-between"
                style={{ width: "100%" }}
              >
                <p style={{ color: "#3478ff", margin: 0, fontSize: 14 }}>
                  {item.name}
                </p>
                <p style={{ color: "grey", margin: 0, fontSize: 12 }}>
                  {item.time}
                </p>
              </div>
              <div
                className=""
                style={{
                  backgroundColor: "#272727",
                  padding: 6,
                  borderRadius: 10,
                  width: "fit-content",
                  marginTop: 4,
                }}
              >
                <p style={{ color: "#ffffff", margin: 0, fontSize: 14 }}>
                  {item.msg}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="d-flex align-items-center justify-content-between"
        style={{ backgroundColor: "#212121", padding: 10 }}
      >
        <p style={{ fontSize: 14, color: "#ffffff", margin: 0 }}>
          Type something
        </p>
        <Send color="#3578ff" />
      </div>
    </div>
  );
};

export default Chat;
