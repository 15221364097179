import React, { useRef } from "react"; // Add useRef here
import "../Toolbars/styles.css";
import { useSelector } from "react-redux";
import { Video } from "lucide-react";
import toast from "react-hot-toast";

const VideoDrop = ({
  videoFile,
  borderRadius,
  styles,
  setVideoFile,
  showVideoBox,
  setImgFile,
}) => {
  const pageType = useSelector((state) => state.login.pageType);
  const fileInputRef = useRef(null); // Add this ref

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file) {
      handleFileUpload(e, file);
      e.target.value = null;
    }
  };

  const handleFileUpload = (e, file) => {
    if (!file || !file.type.startsWith("video/")) {
      toast.error("Please upload a video file");
      return;
    }

    if (file.size > 12000000) {
      toast.error("File size should be less than 12MB");
      return;
    }

    const videoUrl = URL.createObjectURL(file);
    // console.log("Object URL:", videoUrl);

    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      setVideoFile({ base64: result, url: videoUrl });
      setImgFile && setImgFile("no_image");

      // console.log("BASE64>", result);

      setTimeout(() => {
        // setVideoFile(result);
        showVideoBox(false);
      }, 300);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      toast.error("Error processing the video file");
    };

    reader.readAsDataURL(file);
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(e, file);
      e.target.value = null;
    }
  };

  return (
    <div
      className="fileDrop"
      style={{ borderRadius: borderRadius, ...styles }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        type="file"
        accept="video/*"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        id="videoUploadInput"
        ref={fileInputRef}
      />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Video size={100} color="grey" />
        <p
          style={{ color: "#C0BBBB", textAlign: "center" }}
          className={pageType === "mobile" ? "file-drop-text" : ""}
        >
          Drag and Drop a Video file
        </p>
        <label htmlFor="videoUploadInput" className="btn btn-secondary mt-2">
          Or Click to Upload
        </label>
      </div>
    </div>
  );
};

export default VideoDrop;
