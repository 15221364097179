import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import FileDrop from "../../../components/Toolbars/FileDrop";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import { getGradient } from "../../../utills/getGradient";
import TextToolbar from "../../../components/Toolbars/TextToolbar";

const GroupWidget2 = ({
  edithandleClick,
  itemStyles,
  height,
  width,
  widgetId,
  deleteWidget,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const [fileBox, showFileBox] = useState(false);
  const [image, setImage] = useState(false);
  const [imgFile, setImgFile] = useState("");

  const [color, setColor] = useState("");
  const [link, setLink] = useState("");
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const [title, setTitle] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
  });
  const [descFont, setDescFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });
  const [isEditText, setEditText] = useState(false);
  const [isEditText2, setEditText2] = useState(false);
  const [description, setDescription] = useState("");
  const [isDefault, setDefault] = useState(0);

  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
      if (universalStyles.font) {
        font.isDefaultFont == 1 &&
          setFont((prevFont) => ({
            ...prevFont,
            family: universalStyles.font,
          }));
      }
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          setImage(itemStyles.background_image);
        }
      }
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setDescription(itemStyles.description);
      if (itemStyles.description_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.description_font_style);
          setDescFont({
            ...fontstyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.description_font_style;
          setDescFont({
            ...fontstyle,
            color: itemStyles.description_color,
            family: itemStyles.description_font,
            size: itemStyles.description_font_size,
          });
        }
      }

      // setImage(itemStyles.background_image);
      setLink(itemStyles.widget_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
      // Picker colors states
      if (itemStyles.picker_colors) {
        try {
          const pickerColors = JSON.parse(itemStyles.picker_colors);
          setPickerColors(pickerColors);
        } catch (error) {
          const pickerColors = itemStyles.picker_colors;
          setPickerColors(pickerColors);
        }
      }
    }
  }, [itemStyles]);

  const defaultGradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const defaultSolidColor = {
    red: 255,
    green: 0,
    blue: 0,
    alpha: 1,
  };

  const [pickerColors, setPickerColors] = useState({
    solid: defaultSolidColor,
    gradient: defaultGradient,
  });

  const handleEditClick = (e) => {
    dispatch(updateTracker(true));

    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    setEditText2(false);
    setEditText(false);
    e.stopPropagation();
  };
  useEffect(() => {
    if (editingMode.widgtId !== widgetId) {
      showFileBox(false);
      setEditText(false);
      setEditText2(false);
    }
  }, [editingMode.widgtId]);

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  const [colorFromPicker, setColorFromPicker] = useState(false);
  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile("");
    }
  }, [colorFromPicker]);

  let widgetData = { widget_id: widgetId };
  useEffect(() => {
    // count > 1 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image;
    widgetData["background_image"] = imgFile ? imgFile : null;

    widgetData["background_color"] = color;
    widgetData["border"] = border ? border : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;
    widgetData["title_color"] = font.color ? font.color : "#ffffff";
    widgetData["title"] = title;
    widgetData["title_font"] = font.family;
    widgetData["title_font_size"] = font.size;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
    };

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = description;
    widgetData["description_color"] = descFont.color;
    widgetData["description_font"] = descFont.family;
    widgetData["description_font_size"] = descFont.size;
    widgetData["description_font_style"] = {
      isBold: descFont.isBold,
      isUnderlined: descFont.isUnderlined,
      isItalic: descFont.isItalic,
      isDefaultFont: descFont.isDefaultFont,
    };
    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    widgetData["picker_colors"] = pickerColors;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    link,
    border,
    defaultCorners,
    isDefault,
    imagePosition,
    imageRepeat,
    title,
    description,
    descFont,
    font,
    imgFile,
    pickerColors,
  ]);
  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  // console.log("PATH>", image);
  // console.log("File>", imgFile);
  // console.log("color>", color);

  const commonStyles = {
    borderRadius:
      border === "round"
        ? 20
        : border === "sharp"
        ? 0
        : universalStyles.corners === "round"
        ? 20
        : 0,
  };

  const backgroundStyles = (
    image,
    default_widget_colors,
    mainColor,
    color,
    imageRepeat,
    imagePosition,
    imgFile
  ) => {
    if ((image == "0" || image == null || image == false) && imgFile == "") {
      return {
        background: `${
          isDefault && default_widget_colors.custom
            ? default_widget_colors.custom.is_default === "0"
              ? default_widget_colors.custom.color
              : default_widget_colors.custom.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
      };
    } else {
      return {
        backgroundImage: imgFile
          ? `url(${imgFile}`
          : `url(${process.env.REACT_APP_BASE_URL}${image}`,
        backgroundPosition: "center",
        backgroundRepeat: imageRepeat,
        backgroundSize: imagePosition,
      };
    }
  };

  const styles = {
    ...commonStyles,
    ...backgroundStyles(
      image,
      default_widget_colors,
      mainColor,
      color,
      imageRepeat,
      imagePosition,
      imgFile
    ),
  };

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          setEditText(false);
          setEditText2(false);
          e.stopPropagation();
        }
      }}
      className={`d-flex orangegradientmain  ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      }`}
      style={styles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() =>
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300)
      }
    >
      {/* <div className="orangeimage">
        <img src={Assets.orangeImage} alt="OrangeText Image" />
      </div> */}
      {/* {editingMode.widgtId ==widgetId && ( */}
      <div
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/* )} */}
      <div className="groupmainwrap col-md-12 d-flex flex-column justify-content-around ">
        <div
          style={{
            pointerEvents: editingMode.widgtId !== widgetId ? "none" : "",
          }}
          className="d-flex align-items-end"
          onClick={(e) => {
            setEditText(true);
            setEditText2(false);
            e.stopPropagation();
          }}
        >
          <span
            onBlur={(e) => setTitle(e.target.innerText)}
            onFocus={(e) => {
              setEditText(true);
            }}
            onClick={(e) => e.stopPropagation()}
            suppressContentEditableWarning
            data-placeholder="text..."
            contentEditable
            role="textbox"
            className={`connectTextarea ${isEditText ? "editText" : ""}  `}
            placeholder="type here "
            disabled={editingMode.widgtId !== widgetId}
            style={{
              fontSize: Number(font.size),
              color: font.color ? font.color : "white",
              textDecorationLine: font.isUnderlined ? "underline" : "none",
              fontWeight: font.isBold ? "bold" : "normal",
              fontStyle: font.isItalic ? "italic" : "normal",
              fontFamily:
                font.family === "default"
                  ? universalStyles.font
                  : font.family
                  ? font.family
                  : "sans-serif",
              padding: 0,
            }}
          >
            {title}
          </span>

          {/* <textarea
            rows="2"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={`connectTextarea ${isEditText ? "editText" : ""}`}
            placeholder="enter title"
            disabled={editingMode.widgtId !== widgetId}
            style={{
              fontSize: Number(font.size),
              color: font.color ? font.color : "white",
              textDecorationLine: font.isUnderlined ? "underline" : "none",
              fontWeight: font.isBold ? "bold" : "normal",
              fontStyle: font.isItalic ? "italic" : "normal",
              fontFamily:
                font.family === "default"
                  ? universalStyles.font
                  : font.family
                  ? font.family
                  : "sans-serif",
            }}
          /> */}
          {/* {editingMode.widgtId == widgetId && (
            <div
              onClick={() => {
                setEditText(!isEditText);
                setEditText2(false);
              }}
              className="editbtn"
            >
              <img
                style={{ height: 25 }}
                src={Assets.editicon}
                alt="Edit Image"
              />
            </div>
          )} */}
        </div>
        <div
          style={{
            pointerEvents: editingMode.widgtId !== widgetId ? "none" : "",
          }}
          className="d-flex align-items-end"
          onClick={(e) => {
            setEditText(false);
            setEditText2(true);
            e.stopPropagation();
          }}
        >
          <textarea
            rows="2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`connectTextarea ${isEditText2 ? "editText" : ""}  `}
            placeholder="enter description "
            disabled={editingMode.widgtId !== widgetId}
            style={{
              fontSize: Number(descFont.size),
              color: descFont.color ? descFont.color : "white",
              textDecorationLine: descFont.isUnderlined ? "underline" : "none",
              fontWeight: descFont.isBold ? "bold" : "normal",
              fontStyle: descFont.isItalic ? "italic" : "normal",
              fontFamily:
                descFont.family === "default"
                  ? universalStyles.font
                  : descFont.family
                  ? descFont.family
                  : "sans-serif",
            }}
          />
          {/* {editingMode.widgtId == widgetId && (
            <div
              onClick={() => {
                setEditText(false);
                setEditText2(!isEditText2);
              }}
              className="editbtn"
            >
              <img
                style={{ height: 25 }}
                src={Assets.editicon}
                alt="Edit Image"
              />
            </div>
          )} */}
        </div>
        {/* <h2 className="maintitle">Group</h2>
        <div className="datetimegroup">
          <h3>4-12-21</h3>
          <h3>7PM</h3>
        </div> */}
        {/* <div className="textbottomgroup">
          <p>
            Dont Miss Your Next<br></br>
            Redefine Group Meeting!
          </p>
        </div> */}
      </div>
      {editingMode.widgtId == widgetId && !isEditText && !isEditText2 && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          styles={{
            // top: height !== 8 ? "-10%" : "-5%"
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
          }}
          color={color}
          setColor={setColor}
          //
          setPickerColors={setPickerColors}
          pickerColors={pickerColors}
          //
          showFileBox={showFileBox}
          fileBox={fileBox}
          link={link}
          setLink={setLink}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setColorFromPicker={setColorFromPicker}
          colorFromPicker={colorFromPicker}
          // imageRepeat={imageRepeat}
          // setImgRepeat={setImgRepeat}
        />
      )}
      {(isEditText || isEditText2) && (
        <TextToolbar
          font={isEditText ? font : descFont}
          setFont={isEditText ? setFont : setDescFont}
          styles={{
            // top: height == 8 ? "-5%" : "-10%",
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
          }}
        />
      )}
      {editingMode.widgtId == widgetId && fileBox && (
        <FileDrop
          height={height}
          width={width}
          styles={{}}
          borderRadius={border === "round" ? 20 : border === "sharp" ? 0 : null}
          setImage={setImage}
          setImgFile={setImgFile}
        />
      )}
    </div>
  );
};

export default GroupWidget2;
