import React, { useState, useRef, useEffect } from "react";
import Assets from "../../assets/images";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../utills/requests";
import {
  getStyling,
  setEdit,
  setSideBar,
  submitRequest,
  updateTracker,
} from "../../store/slices/bodyStyling";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { setPageId, setPageType } from "../../store/slices/loginSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Cross, X } from "lucide-react";

function Topbar() {
  const dispatch = useDispatch();
  // const pages = useSelector((state) => state.bodyStyling.styling.pages);
  const pages = useSelector((state) => state.login.pages);
  const changeTracker = useSelector((state) => state.bodyStyling.changeTracker);
  const order = useSelector((state) => state.bodyStyling.homePageOrder);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const loader = useSelector((state) => state.bodyStyling.submitLoader);
  const widgetsStyles = useSelector((state) => state.bodyStyling.widgetsStyles);
  const sideBarState = useSelector((state) => state.bodyStyling.sideBarState);
  const pageId = useSelector((state) => state.login.pageId);
  const pageType = useSelector((state) => state.login.pageType);

  const [localPageId, setLocalPageId] = useState("");

  const location = useLocation();
  const { pathname } = location;

  const mappingMobileRoutes = {
    "/home": "/mobile-home",
    "/watch": "/mobile-watch",
    "/giving": "/mobile-giving",
    "/team": "/mobile-team",
    "/group": "/mobile-group",
    "/serve": "/mobile-serve",
    "/kids": "/mobile-kids",
    "/userGiving": "/mobileUserGiving",
  };

  const mappingWebRoutes = {
    "/mobile-home": "/home",
    "/mobile-watch": "/watch",
    "/mobile-giving": "/giving",
    "/mobile-team": "/team",
    "/mobile-group": "/group",
    "/mobile-serve": "/serve",
    "/mobile-kids": "/kids",
    "/mobileUserGiving": "/userGiving",
  };

  const mappingRouteIds = {
    1: "home",
    2: "watch",
    3: "giving",
    4: "group",
    5: "team",
    6: "serve",
    7: "kids",
    8: "userGiving",
    9: "mobile-home",
    10: "mobile-watch",
    11: "mobile-giving",
    12: "mobile-group",
    13: "mobile-team",
    14: "mobile-serve",
    15: "mobile-kids",
    16: "mobileUserGiving",
  };

  useEffect(() => {
    if (pathname.includes("mobile")) {
      dispatch(setPageType("mobile"));
    } else {
      dispatch(setPageType("web"));
    }
  }, [pathname]);

  const ref = useRef(null);

  const [localPages, setLocalPages] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const screenWidth = document.body.clientWidth;
    const data = JSON.parse(JSON.stringify(order));
    data["universalStyles"] = { ...universalStyles, screen_width: screenWidth };
    data["widgetStyles"] = widgetsStyles;
    dispatch(submitRequest(true));
    try {
      const resp = await postRequest({
        endpoint: "/edit-current-styles",
        payload: data,
      });
      toast.success(resp.data.msg, { id: "changesMade" });
      dispatch(updateTracker(false));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
    } catch (err) {
      toast.error(
        err.response === undefined
          ? "Something went wrong"
          : err.response.data.message,
        { id: "updateErr" }
      );
    } finally {
      dispatch(submitRequest(false));
    }
  };

  useEffect(() => {
    if (pages.length > 0) {
      if (pageType === "web") {
        setLocalPages(pages.slice(0, 8));
      } else {
        setLocalPages(pages.slice(8));
      }
    }
  }, [pages]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setPageType("web"));
    navigate("/");
  };
  const goBack = () => {
    if (sideBarState && changeTracker) {
      dispatch(getStyling());
      dispatch(updateTracker(false));
    }
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.remove("hide");
    sidebaroption2.classList.remove("hide");
    widgetMain.classList.remove("show");
    localStorage.removeItem("customId");
    dispatch(setSideBar(false));
    dispatch(setEdit({}));
    localStorage.removeItem("widgetName");
  };

  const handleToggle = (type, path) => {
    if (type === "web") {
      navigate(mappingWebRoutes[path]);
    } else {
      navigate(mappingMobileRoutes[path]);
    }
    dispatch(setPageType(type));
  };

  const navigatePage = (pageId) => {
    dispatch(setPageId(pageId));
    navigate(`/${mappingRouteIds[pageId]}`);
  };

  return (
    <>
      <div className="maintopbar">
        <div className="maintopbarInnerLeft">
          <div className="logomain">
            <img src={Assets.logo} alt="Logo Main" />
          </div>
        </div>
        <div className="maintopbarInnerRight">
          <div className="topbarrightcol topbarrightcol-1">
            <div className="topbarrightcolinner">
              <div
                className="backbtn"
                onClick={() => goBack()}
                style={{
                  cursor: sideBarState ? "pointer" : "unset",
                  opacity: sideBarState ? 1 : 0.2,
                }}
              >
                <img src={Assets.backarrow} alt="Back Arrow" />
                <span>Back</span>
              </div>
              <div className="pageDropdown">
                <span>page:</span>
                <select
                  value={pageId}
                  className="watchSelect"
                  onChange={(e) => {
                    if (changeTracker) {
                      setLocalPageId(e.target.value);
                      ref.current.open();
                      return;
                    } else {
                      navigatePage(e.target.value);
                    }

                    // close widget size side bar
                    const sidebaroption =
                      document.querySelector(".sidebaroption");
                    const sidebaroption2 =
                      document.querySelector(".sidebaroption2");
                    const widgetMain =
                      document.querySelector(".custom-widgetMain");
                    sidebaroption.classList.remove("hide");
                    sidebaroption2.classList.remove("hide");
                    widgetMain.classList.remove("show");
                    localStorage.removeItem("customId");
                    dispatch(setSideBar(false));
                    dispatch(setEdit({}));
                    localStorage.removeItem("widgetName");
                  }}
                >
                  {localPages.map((page) => {
                    return (
                      <option key={page.id} id={page.id} value={page.id}>
                        {page.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-2">
            <div className="topbarrightcolinner">
              <ul>
                <li
                  onClick={() => handleToggle("web", pathname)}
                  className={` ${pageType == "web" ? "active" : ""}`}
                >
                  <img src={Assets.laptop} alt="Laptop" />
                </li>
                <li
                  onClick={() => handleToggle("mobile", pathname)}
                  className={` ${pageType == "mobile" ? "active" : ""}`}
                >
                  <img src={Assets.smartphone} alt="Smart Phone" />
                </li>
              </ul>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-3">
            <div className="topbarrightcolinner">
              <div className="backbtn undoBtn">
                <img src={Assets.undoico} alt="Undo Icon" />
                <span>Undo</span>
              </div>
              <div className="defaultButtons">
                {/* <a href="#" className="ligthButton">
                  Preview
                </a> */}
                <button onClick={handleLogout} className="ligthButton">
                  Logout
                </button>
                {/* <a onClick={handleSubmit} href="#" className="BlueButton">
                  Submit
                </a> */}

                <button
                  className="BlueButton"
                  onClick={handleSubmit}
                  disabled={!changeTracker}
                  style={{ opacity: !changeTracker ? 0.6 : 1 }}
                >
                  {loader ? (
                    <Circles
                      height="20"
                      width="50"
                      color="#4b6cb7"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        lockScroll={true}
        contentStyle={{
          border: "unset",
          padding: 0,
          borderRadius: 10,
        }}
        ref={ref}
        modal={true}
        trigger={<div style={{ marginLeft: "unset" }} />}
        position="center center"
      >
        {(close) => (
          <div
            className="confirmation-dialog"
            style={{ backgroundColor: "black", borderRadius: 10, padding: 20 }}
          >
            <X
              onClick={close}
              color="grey"
              style={{
                position: "absolute",
                top: 4,
                right: 4,
                cursor: "pointer",
              }}
            />
            <p
              style={{
                fontSize: 20,
                color: "#ffffff",
                textAlign: "center",
                marginTop: 6,
                paddingInline: 10,
              }}
            >
              Current changes will be discarded?
              <br /> Would you like to save?
            </p>
            <div className="d-flex align-items-center justify-content-around">
              <button
                onClick={() => {
                  ref.current.close();
                  handleSubmit();
                }}
                style={{
                  fontSize: 20,
                  color: "grey",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  ref.current.close();
                  navigatePage(localPageId);
                  dispatch(updateTracker(false));
                }}
                style={{
                  fontSize: 20,
                  color: "red",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                No, Discard
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
}

export default Topbar;
