import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../utills/requests";
import { toast } from "react-hot-toast";
import { setPageId, setPages } from "./loginSlice";

const initialState = {
  isLoading: false,
  error: null,
  styling: [],
  homePageOrder: [],
  universalStyles: {},
  changeTracker: false,
  submitLoader: false,
  isEdit: {},
  widgetsStyles: [],
  sideBarState: false,
  stopDrag: 'no',
};

const slice = createSlice({
  name: "styling",
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },

    stopLoading(state, action) {
      state.isLoading = action.payload;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    setStyling(state, action) {
      state.styling = action.payload;
    },
    updateHomePageOrder(state, action) {
      state.homePageOrder = action.payload;
    },
    updateUniversalStyles(state, action) {
      state.universalStyles = action.payload;
    },
    updateTracker(state, action) {
      state.changeTracker = action.payload;
    },
    submitRequest(state, action) {
      state.submitLoader = action.payload;
    },
    emptyState(state) {
      state.styling = [];
    },
    setEdit(state, action) {
      state.isEdit = action.payload;
    },
    updateWigetsStyles(state, action) {
      const isObjectExists = state.widgetsStyles.some(
        (item) => item.widget_id === action.payload.widget_id
      );
      if (isObjectExists) {
        const updatedArr = state.widgetsStyles.map((item) =>
          item.widget_id === action.payload.widget_id
            ? { ...item, ...action.payload }
            : item
        );
        state.widgetsStyles = updatedArr;
      } else {
        state.widgetsStyles = [...state.widgetsStyles, action.payload];
      }
    },
    updateStyling(state, action) {
      state.styling = state.styling.map((resource) =>
        resource.id === action.payload.id ? action.payload : resource
      );
    },
    setSideBar(state, action) {
      state.sideBarState = action.payload;
    },
    emptyWidgetStyles(state) {
      state.widgetsStyles = [];
    },
    setStopDrag(state, action) {
      state.stopDrag = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
const actions = slice.actions;

export const {
  updateHomePageOrder,
  updateUniversalStyles,
  updateTracker,
  submitRequest,
  emptyState,
  setEdit,
  updateWigetsStyles,
  setStyling,
  // setPageId,
  setSideBar,
  stopLoading,
  startLoading,
  emptyWidgetStyles,
  setStopDrag,
} = actions;

export const getStyling = (id) => async (dispatch) => {
  dispatch(actions.startLoading(true));
  dispatch(submitRequest(true));
  try {
    const res = await getRequest({ endpoint: `/current-styles?page_id=${id}` });
    dispatch(emptyWidgetStyles());
    dispatch(actions.setStyling(res));
    dispatch(actions.stopLoading(false));
    // dispatch(submitRequest(false));
    dispatch(updateUniversalStyles(res.universal_styles));
    dispatch(setPages(res.pages));
    dispatch(setPageId(id));
  } catch (err) {
    dispatch(actions.stopLoading());
    toast.error(
      err.response === undefined
        ? "Something went wrong"
        : err.response.data.message,
      { id: "styleErr" }
    );
  } finally {
    dispatch(submitRequest(false));
  }
};
