import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";

const LineChart = () => {
  const pageType = useSelector((state) => state.login.pageType);

  const option = {
    title: {
      text: "YOUR GIVING HISTORY",
      textStyle: {
        color: "#ffffff", // Change text color inside the tooltip
        fontSize: pageType === "mobile" ? 14 : 24,
      },
      left: "2%",
    },
    tooltip: {
      show: false,
      trigger: "axis",
      backgroundColor: "#000",
      textStyle: {
        color: "#ffffff", // Change text color inside the tooltip
      },
    },
    legend: {
      show: false, // Enable the legend
      data: ["Payment"], // Legend item names should match the 'name' in series
      left: "center", // Positioning of the legend (can be 'left', 'right', 'center')
      top: "bottom", // Vertical positioning (can be 'top', 'bottom')
      textStyle: {
        color: "#fff", // Text color for the legend
      },
      padding: [0, 0, 0, 0],
    },
    grid: {
      left: pageType === "mobile" ? 2 : "3%",
      right: pageType === "mobile" ? 2 : "4%",
      bottom: pageType === "mobile" ? 2 : "3%",
      top:40,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      axisLabel: {
        color: "#fff", // Change X-axis label color
        fontSize: pageType === "mobile" ? 12 : 16,
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true, // Ensure grid lines are displayed
        lineStyle: {
          color: "#ccc", // Color of the grid lines
          type: "dashed", // Line style, can be 'solid', 'dashed', or 'dotted'
        },
      },
      axisLabel: {
        color: "#fff", // Change X-axis label color
        fontSize: pageType === "mobile" ? 12 : 16,
      },
    },
    series: [
      {
        name: "Payment",
        type: "line",
        smooth: true,
        data: [30, 120, 150, 80, 230, 120, 200, 150, 80, 70, 110, 130],
        itemStyle: {
          color: "#fff",
        },
        lineStyle: {
          width: 3,
        },
        areaStyle: {
          opacity: 0.1,
        },
        symbolSize: 10, // Increase the size of the intersecting dot
        symbol: "circle",
      },
    ],
  };

  return (
    <ReactEcharts option={option} style={{ height: "100%", width: "100%" }} />
  );
};

export default LineChart;
