import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Send } from "lucide-react";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
  updateWigetsStyles,
} from "../../../store/slices/bodyStyling";

const SermonWidget = ({ widgetId, deleteWidget, itemStyles }) => {
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);

  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  useEffect(() => {
    if (itemStyles) {
      setDefaultCorners(itemStyles.defaultCorners);
      setBorder(itemStyles.border);
    }
  }, [itemStyles]);

  useEffect(() => {
    if (universalStyles.corners && itemStyles.defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, itemStyles]);

  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  const sermons = [
    { id: 1, name: "Blessed", img: Assets.sermon1 },
    { id: 2, name: "Stride, Not strive", img: Assets.sermon2 },
  ];

  const series = [
    { id: 3, name: "Nicholes series", img: Assets.sermon3 },
    { id: 4, name: "Believe", img: Assets.sermon4 },
    { id: 5, name: "Andy's series", img: Assets.sermon5 },
    { id: 6, name: "Purity", img: Assets.sermon6 },
  ];

  const handleEditClick = (e) => {
    dispatch(updateTracker(true))

    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(
        setEdit({
           
        })
      );
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      localStorage.setItem("customId", widgetId);
      localStorage.setItem("widgetName", "sermon");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
    } else {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(
        setEdit({})
      );
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
    }
    e.stopPropagation();
  };

  let widgetData = { widget_id: widgetId.toString() };
  useEffect(() => {
    widgetData["background_image"] = null;
    widgetData["background_color"] = null;
    widgetData["border"] = border ? border : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = null;
    widgetData["image_position"] = null;
    widgetData["image_repeat"] = null;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = null;
    widgetData["button_text"] = null;
    widgetData["button_text_color"] = null;
    widgetData["button_link"] = null;
    widgetData["button_font_style"] = null;
    widgetData["button_font_size"] = null;
    widgetData["button_font"] = null;

    widgetData["text_alignmnet"] = null;
    widgetData["title"] = null;
    widgetData["title_color"] = null;
    widgetData["title_font"] = null;
    widgetData["title_font_size"] = null;
    widgetData["title_font_style"] = null;

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = null;

    widgetData["button_alignmnet"] = null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    widgetData["picker_colors"] = null;
    dispatch(updateWigetsStyles(widgetData));
  }, [border, defaultCorners]);

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          e.stopPropagation();
        }
      }}
      className={`d-flex ${editingMode.widgtId == widgetId ? "edit-mode" : ""}`}
      style={{
        backgroundColor: "#151515",
        height: "100%",
        borderRadius: 20,
        padding: 10,
        columnGap: 4,
        overflow: "hidden",
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        // flexWrap:'wrap'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
    >
      <div className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
       onMouseEnter={() => {
        dispatch(setStopDrag("yes"));
      }}
      onMouseLeave={() => {
        if (editingMode.widgtId !== widgetId) {
          dispatch(setStopDrag("no"));
        }
      }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(
                setEdit({
                })
              );
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId, "sermon");
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/*1st  */}
      <div className="col-md-6">
        <p
          style={{
            color: "#ffffff",
            fontSize: 16,
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Sermons
        </p>
        <div
          className="d-flex flex-column"
          style={{
            rowGap: 10,
            marginTop: 6,
            // width: "min-content"
          }}
        >
          {sermons.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "#232323",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                  // flexWrap: "wrap",
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* 2nd */}
      <div className="col-md-6">
        <p
          style={{
            color: "#ffffff",
            fontSize: 16,
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Sermons Series
        </p>
        <div
          className="d-flex flex-column"
          style={{
            rowGap: 10,
            marginTop: 6,
            // width: "min-content"
          }}
        >
          {series.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: item.id == 3 ? "#007bff" : "#232323",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                  // flexWrap: "wrap",
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SermonWidget;
