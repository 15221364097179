import React from "react";
import { useDraggable } from "@dnd-kit/core";

const DragableTool = ({ title, id, handleCustomStyles }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id.toString(),
    data: { handleCustomStyles },
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ cursor: "pointer" }}
      className="themelayoutinnerbox roundcorner"
    >
      <span>{title}</span>
    </div>
  );
};

export default DragableTool;
