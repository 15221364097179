import React from "react";
import "../../home/styles.css";

const Player = ({borderRadius}) => {
  return (
    <div
      className={"videoDiv"}
      style={{
        borderRadius: borderRadius?borderRadius:0,
        width:'75%',
        height:'100%'
      }}
    />
  );
};

export default Player;
