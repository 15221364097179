import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";

const TextToolbar = ({
  styles,
  belowContent,
  text,
  setText,
  itemIndexTypeCol,
  alignment,
}) => {
  const [picker, setPicker] = useState(false);
  const inputRef = useRef(null);

  const colorPickerRef = useRef(null);
  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
  });

  const handleFontChange = (e) => {
    const selectedFont = e.target.value;
    setText((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            family: selectedFont,
            // isDefaultText: selectedFont === "default" ? 1 : 0,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  const incrementFontSize = () => {
    setText((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            fontSize: Number(item.fontSize) + 1,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  const decrementFontSize = () => {
    setText((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            fontSize: Math.max(item.fontSize - 1, 1),
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  const handleAlignmentChange = (e) => {
    const selectedAlignment = e.target.value;
    setText((prev) => {
      const updatedArray = prev.map((item, index) => {
        if (index === itemIndexTypeCol.index) {
          return {
            ...item,
            direction: selectedAlignment,
          };
        }
        return item;
      });
      return updatedArray;
    });
  };

  return (
    <div
      style={styles}
      className={`accordionToolbar ${belowContent ? "topPointer" : ""}`}
      onClick={(e)=>e.stopPropagation()}
    >
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          <h4>Text Toolbar</h4>
        </div>
        <div className="toolBarRight">
          <div className="d-flex align-tems-center justify-content-center ">
            <button className="counterBtn" onClick={decrementFontSize}>
              -
            </button>
            <input
              ref={inputRef}
              //   onChange={handleInputChange}
              className="fontCounter"
              type="text"
              value={`${text[itemIndexTypeCol.index].fontSize}px`}
            />
            <button className="counterBtn" onClick={incrementFontSize}>
              +
            </button>
          </div>
          <div className="fontList">
            <div className="fillselect">
              <select
                style={{ minWidth: 80 }}
                value={
                  //   text[itemIndexTypeCol.index].isDefaultText == 1
                  //     ? "default"
                  // :
                  text[itemIndexTypeCol.index].family
                }
                onChange={handleFontChange}
              >
                <option value="default">Default</option>
                <option value="gilroymedium">Gilroy</option>
                <option value="frontageregular">Frontage</option>
                <option value="playfair display">Playfair Display</option>
                <option value="rockwellregular">Rockwell</option>
                <option value="Sigmar">
                  Sigmar
                </option>
                <option value="Dancing Script">Dancing Script</option>
                <option value="Eater">Eater</option>
                <option value="Kablammo">Kablammo</option>
                <option value="Splash">Splash</option>
                <option value="Rubik Burned">Rubik Burned</option>
              </select>
            </div>
          </div>
          {alignment && (
            <div className="fontList">
              <div className="fillselect">
                <select
                  style={{ minWidth: 80 }}
                  value={text[itemIndexTypeCol.index].direction}
                  onChange={handleAlignmentChange}
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            </div>
          )}
          <div
            onClick={() => {
              setText((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isBold: !item.isBold,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            className="boldIcon"
            style={{
              backgroundColor: text[itemIndexTypeCol.index].isBold
                ? "dimgrey"
                : "",
              borderRadius: 6,
            }}
          >
            <img src={Assets.boldbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setText((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isItalic: !item.isItalic,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            style={{
              backgroundColor: text[itemIndexTypeCol.index].isItalic
                ? "dimgrey"
                : "",
              borderRadius: 6,
            }}
            className="italicIcon"
          >
            <img src={Assets.italicbutton} alt="Edit Image" />
          </div>
          <div
            onClick={() => {
              setText((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isUnderlined: !item.isUnderlined,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            style={{
              backgroundColor: text[itemIndexTypeCol.index].isUnderlined
                ? "dimgrey"
                : "",
              borderRadius: 6,
            }}
            className="TIcon"
          >
            <img src={Assets.Tbutton} alt="Edit Image" />
          </div>
          <div
            style={{
              backgroundColor: text[itemIndexTypeCol.index].isDefaultColor
                ? "dimgrey"
                : "",
              borderRadius: 4,
              padding: 4,
            }}
            onClick={() => {
              setText((prev) => {
                const updatedArray = prev.map((item, index) => {
                  if (index === itemIndexTypeCol.index) {
                    return {
                      ...item,
                      isDefaultColor: !item.isDefaultColor,
                    };
                  }
                  return item;
                });
                return updatedArray;
              });
            }}
            className="linkIcon"
          >
            <img
              src={Assets.defaultIcon}
              style={{ height: 26, width: 26 }}
              alt="default Image"
            />
          </div>
          <div onClick={() => setPicker(!picker)} className="colorCircle">
            <span
              className="white"
              style={{ backgroundColor: text[itemIndexTypeCol.index].color }}
            />
          </div>
        </div>
      </div>
      {picker && (
        <SketchPicker
          ref={colorPickerRef}
          className="colorPicker"
          color={text[itemIndexTypeCol.index].color}
          onChangeComplete={(color) => {
            setText((prev) => {
              const updatedArray = prev.map((item, index) => {
                if (index === itemIndexTypeCol.index) {
                  return {
                    ...item,
                    color: color.hex,
                    isDefaultColor: 0,
                  };
                }
                return item;
              });
              return updatedArray;
            });
          }}
          // styles={{ default: { picker: { zoom: "125%" } } }}

          // styles={{zIndex:1000}}
        />
      )}
    </div>
  );
};

export default TextToolbar;
