import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import "./styles.css";
//Assets
// import Assets from "../../assets/images";
//Actions
import {
  getStyling,
  setEdit,
  setSideBar,
  setStopDrag,
} from "../../store/slices/bodyStyling";
//Components
import SortableGrid from "../../components/GridLayout/SortableGrid";
import { useDroppable } from "@dnd-kit/core";

function Home(props) {
  const { darkMode } = props;

  const dispatch = useDispatch();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);
  const pageId = useSelector((state) => state.login.pageId);

  useEffect(() => {
    dispatch(getStyling(1));
  }, []);

  // Edit Handle
  const [iseditMode, setIseditMode] = useState(false);
  const edithandleClick = () => {
    setIseditMode(!iseditMode);
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.add("hide");
    sidebaroption2.classList.add("hide");
    widgetMain.classList.add("show");
  };

  const { isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });
  // console.log('IS OVER>',isOver);
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="rightInnerWrap">
        {/* <div className="section active has-sec-1-home has-ptb" id="section1">
          <div className="has-sec1-group inner-sec-has1"></div>
        </div> */}
        <div className="sec2">
          <div className="container">
            {/* <div className="row">
              <VideoWidget/>
              <div className="col-md-6">
                <ConnectWidget edithandleClick={edithandleClick} />
              </div>
              <div className="col-md-6">
                <GivingWidget edithandleClick={edithandleClick} />
              </div>
            </div> */}
            {loading ? (
              <Circles
                height="50"
                width="50"
                // color="#4b6cb7"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              // <ResponsiveGridLayout/>
              <div
                onClick={(e) => {
                  // e.stopPropagation
                  const id = localStorage.getItem("customId");
                  if (id !== null) {
                    localStorage.removeItem("customId");
                    localStorage.removeItem("widgetName");
                    dispatch(setStopDrag('no'))
                    dispatch(
                      setEdit({
                      })
                    );
                    const sidebaroption =
                      document.querySelector(".sidebaroption");
                    const sidebaroption2 =
                      document.querySelector(".sidebaroption2");
                    const widgetMain =
                      document.querySelector(".custom-widgetMain");
                    sidebaroption.classList.remove("hide");
                    sidebaroption2.classList.remove("hide");
                    widgetMain.classList.remove("show");
                    dispatch(setSideBar(false));
                  }
                }}
                ref={setNodeRef}
              >
                <SortableGrid
                  widgetsOrder={data.homepageOrder}
                  edithandleClick={edithandleClick}
                />
              </div>
            )}
          </div>
        </div>
        {/* <div className="sec3">
          <div className="container">
            <div className="row">
              <div
                className="d-flex flex-column col-md-6"
                style={{ rowGap: 30 }}
              >
                <div className="col-md-12">
                  <GroupWidget edithandleClick={edithandleClick} />
                </div>
                <div className="col-md-12">
                  <GroupWidget2 edithandleClick={edithandleClick} />
                </div>
              </div>
              <div className="col-md-6">
                <QuoteWidget edithandleClick={edithandleClick} />
              </div>
            </div>
          </div>
        </div> */}

        {/* COMMENTED OUT YET */}
        {/* <div className="kits">
          <div className="row">
            <div className="col-md-6">
              <div className="widgetToolBar">
                <div className="widgetToolbarInner">
                  <div className="toolBarLeft">
                    <h4>widget TOOLBAR</h4>
                  </div>
                  <div className="toolBarRight">
                    <div className="linkIcon">
                      <img src={Assets.link} alt="Edit Image" />
                    </div>
                    <div className="bucketIcon">
                      <img src={Assets.paintbucket1} alt="Edit Image" />
                    </div>
                    <div className="imageIcon">
                      <img src={Assets.image} alt="Edit Image" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="space"></div>
              <div className="widgetToolBar">
                <div className="widgetToolbarInner">
                  <div className="toolBarLeft">
                    <h4>widget TOOLBAR</h4>
                  </div>
                  <div className="toolBarRight">
                    <div className="fontList">
                      <div className="fillselect">
                        <select>
                          <option value="Gilroy">Gilroy</option>
                          <option value="Frontage">Frontage</option>
                          <option value="Playfair Display">
                            Playfair Display
                          </option>
                          <option value="Rockwell">Rockwell</option>
                        </select>
                      </div>
                    </div>
                    <div className="boldIcon">
                      <img src={Assets.boldbutton} alt="Edit Image" />
                    </div>
                    <div className="italicIcon">
                      <img src={Assets.italicbutton} alt="Edit Image" />
                    </div>
                    <div className="TIcon">
                      <img src={Assets.Tbutton} alt="Edit Image" />
                    </div>
                    <div className="colorCircle">
                      <span className="white"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space"></div>
              <div className="widgetToolBar">
                <div className="widgetToolbarInner">
                  <div className="toolBarLeft">
                    <h4>widget TOOLBAR</h4>
                  </div>
                  <div className="toolBarRight">
                    <div className="linkIcon">
                      <img src={Assets.link} alt="Edit Image" />
                    </div>
                    <div className="colorCircle">
                      <span className="blue"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space"></div>
              <div className="widgetUploadBox">
                <div className="widgetuploadinner">
                  <img src={Assets.uploadplaceholder} alt="Edit Image" />
                </div>
              </div>
              <div className="space"></div>
              <div className="widgetEditBox">
                <div className="widgeteditinner">
                  <div className="editTitle">
                    <h3>Hello World!</h3>
                    <p>
                      The Bible is absolutely true and without error. The bible
                      was written by human authors under the supernatural
                      guidance of the Holy Spirit. The bible is fully relevant
                      to our daily lives, past, present, and future.{" "}
                    </p>
                    <div className="buttonEdit">
                      <a href="javascript:;" className="BlueButton">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="widgetStyles">
          <div className="row">
            <div className="col-md-6">
              <div className="space"></div>
              <div className="widgetEditBox">
                <div className="widgeteditinner">
                  <div className="editTitle">
                    <h3>Widget Style 1</h3>
                    <p>
                      The Bible is absolutely true and without error. The bible
                      was written by human authors under the supernatural
                      guidance of the Holy Spirit. The bible is fully relevant
                      to our daily lives, past, present, and future.{" "}
                    </p>
                    <div className="buttonEdit">
                      <a href="javascript:;" className="BlueButton">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space"></div>
              <div className={darkMode ? "widgetEditBox" : "widgetEditBox"}>
                <div className="widgeteditinner">
                  <div className="editTitle">
                    <h3>Widget Style 3</h3>
                    <p>
                      The Bible is absolutely true and without error. The bible
                      was written by human authors under the supernatural
                      guidance of the Holy Spirit. The bible is fully relevant
                      to our daily lives, past, present, and future.{" "}
                    </p>
                    <div className="buttonEdit">
                      <a href="javascript:;" className="BlueButton">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="space"></div>
              <div className="widgetEditBox">
                <div className="widgeteditinner">
                  <div className="editTitle">
                    <h3>Widget Style 3</h3>
                    <p>
                      The Bible is absolutely true and without error. The bible
                      was written by human authors under the supernatural
                      guidance of the Holy Spirit. The bible is fully relevant
                      to our daily lives, past, present, and future.{" "}
                    </p>
                    <div className="buttonEdit">
                      <a href="javascript:;" className="BlueButton">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="space"></div>
              <div className="widgetEditBox">
                <div className="widgeteditinner">
                  <div className="editTitle">
                    <h3>Widget Style 4</h3>
                    <p>
                      The Bible is absolutely true and without error. The bible
                      was written by human authors under the supernatural
                      guidance of the Holy Spirit. The bible is fully relevant
                      to our daily lives, past, present, and future.{" "}
                    </p>
                    <div className="buttonEdit">
                      <a href="javascript:;" className="BlueButton">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space"></div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Home;
