import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
//Assets
// import Assets from "../../assets/images";
//Actions
import {
  getStyling,
  setEdit,
  setSideBar,
  setStopDrag,
} from "../../store/slices/bodyStyling";
//Components
import { useDroppable } from "@dnd-kit/core";
import MobileTeamGrid from "../../components/GridLayout/MobileTeamGrid";

function MobileTeamPage(props) {
  const { darkMode } = props;

  const dispatch = useDispatch();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);
  const pageType = useSelector((state) => state.login.pageType);

  useEffect(() => {
    dispatch(getStyling(13));
  }, []);

  // Edit Handle
  const [iseditMode, setIseditMode] = useState(false);
  const edithandleClick = () => {
    setIseditMode(!iseditMode);
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.add("hide");
    sidebaroption2.classList.add("hide");
    widgetMain.classList.add("show");
  };

  const { isOver, setNodeRef } = useDroppable({
    id: "watchDroppable",
  });
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div
        className={`${
          pageType === "web" ? "rightInnerWrap" : "rightInnerWrap mobile-width"
        }`}
      >
        <div className="sec2">
          <div className="container">
            {loading ? (
              <Circles
                height="50"
                width="50"
                // color="#4b6cb7"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div
                onClick={(e) => {
                  // e.stopPropagation
                  const id = localStorage.getItem("customId");
                  console.log("ID>>", id);
                  if (id !== null) {
                    localStorage.removeItem("customId");
                    localStorage.removeItem("widgetName");
                    dispatch(setStopDrag("no"));
                    dispatch(setEdit({}));
                    const sidebaroption =
                      document.querySelector(".sidebaroption");
                    const sidebaroption2 =
                      document.querySelector(".sidebaroption2");
                    const widgetMain =
                      document.querySelector(".custom-widgetMain");
                    sidebaroption.classList.remove("hide");
                    sidebaroption2.classList.remove("hide");
                    widgetMain.classList.remove("show");
                    dispatch(setSideBar(false));
                    console.log("PRESSED>>");
                  }
                }}
                ref={setNodeRef}
              >
                <MobileTeamGrid
                  widgetsOrder={data.homepageOrder}
                  edithandleClick={edithandleClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTeamPage;
