import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ component: Component }) => {
  const token = localStorage.getItem("accessToken");

  if (token) {
    return <Navigate to="/home" />;
  }
  return <Component />;
};

export default PublicRoute;
