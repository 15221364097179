export const defaultStyles = {
  1: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "player",
    },
  },
  2: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 1,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "connect",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#1f2738",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#8C2626",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "20",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#778FBE",
        button_text_color: "#b33333",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "SEND",
        widget_link: "connect link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 2,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  3: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 1,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        // background_color: "#893838",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "GIVE NOW",
        widget_link: "giving link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 3,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  4: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 1,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "map",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 4,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  5: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 1,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "serve",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#f57b4f",
        background_image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iaGVpZ2h0OiA1MTJweDsgd2lkdGg6IDUxMnB4OyI+PHBhdGggZD0iTTAgMGg1MTJ2NTEySDB6IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9IjEiPjwvcGF0aD48ZyBjbGFzcz0iI",
        corners: null,
        title: "SERVE",
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: " Dont Miss Your Next Redefine Group Meeting!",
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "serve link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 5,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  6: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 1,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "ourcode",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: "Our Code",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "our code link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 100,
        text_width: 160,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 6,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  7: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "groups",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#b694e0",
        background_image: null,
        corners: null,
        title: "Join a Redefine Group",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "groups link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 60,
        text_width: 560,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 7,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  8: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "eventList",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#8f5d2c",
        background_image: null,
        corners: null,
        title: null,
        title_color: "#34268c",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: "frontageregular",
        description_color: "#cba1a1",
        description_font_style:
          '{"isBold":false,"isUnderlined":false,"isItalic":1,"isDefaultFont":1}',
        description_font_size: "10",
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "eventlist link",
        date_font: "frontageregular",
        date_font_color: "#39c688",
        date_font_style:
          '{"isBold":0,"isUnderlined":0,"isItalic":1,"isDefaultFont":1}',
        date_font_size: "10",
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 8,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  9: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 2,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "watch_player",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  10: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 1,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "chat",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  11: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "notes",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  12: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 1,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "sermon",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
};

export const watchDefaultStyles = {
  2: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 0,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "connect",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#1f2738",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#8C2626",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "20",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#778FBE",
        button_text_color: "#b33333",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "SEND",
        widget_link: "connect link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 2,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  3: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        // background_color: "#893838",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "GIVE NOW",
        widget_link: "giving link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 3,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  4: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "map",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 4,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  5: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "serve",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#f57b4f",
        background_image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iaGVpZ2h0OiA1MTJweDsgd2lkdGg6IDUxMnB4OyI+PHBhdGggZD0iTTAgMGg1MTJ2NTEySDB6IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9IjEiPjwvcGF0aD48ZyBjbGFzcz0iI",
        corners: null,
        title: "SERVE",
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: " Dont Miss Your Next Redefine Group Meeting!",
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "serve link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 5,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  6: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "ourcode",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: "Our Code",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "our code link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 100,
        text_width: 160,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 6,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  7: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 4,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "groups",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#b694e0",
        background_image: null,
        corners: null,
        title: "Join a Redefine Group",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "groups link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 60,
        text_width: 560,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 7,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  8: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 4,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "eventList",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#8f5d2c",
        background_image: null,
        corners: null,
        title: null,
        title_color: "#34268c",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: "frontageregular",
        description_color: "#cba1a1",
        description_font_style:
          '{"isBold":false,"isUnderlined":false,"isItalic":1,"isDefaultFont":1}',
        description_font_size: "10",
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "eventlist link",
        date_font: "frontageregular",
        date_font_color: "#39c688",
        date_font_style:
          '{"isBold":0,"isUnderlined":0,"isItalic":1,"isDefaultFont":1}',
        date_font_size: "10",
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 8,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  // 9: {
  //   id: 4,
  //   widget_id: "set",
  //   page_id: 2,
  //   x: 1,
  //   y: "set",
  //   w: 3,
  //   h: 5,
  //   widgetInfo: {
  //     id: "set",
  //     name: "watch_player",
  //   },
  //   widget_styles: [
  //     {
  //       id: 514,
  //       widget_id: "set",
  //       background_color: null,
  //       background_image: null,
  //       corners: null,
  //       title: null,
  //       title_color: null,
  //       title_font: null,
  //       title_font_style: "null",
  //       title_font_size: null,
  //       description: null,
  //       description_font: null,
  //       description_color: null,
  //       description_font_style: null,
  //       description_font_size: null,
  //       button_background_color: null,
  //       button_text_color: null,
  //       is_custom: 0,
  //       page_id: 2,
  //       width: null,
  //       height: null,
  //       button_text: null,
  //       widget_link: null,
  //       date_font: null,
  //       date_font_color: null,
  //       date_font_style: null,
  //       date_font_size: null,
  //       heading_title: null,
  //       heading_title_color: null,
  //       heading_title_font: null,
  //       heading_title_font_size: null,
  //       heading_title_font_style: "null",
  //       paragraph_title: null,
  //       paragraph_title_color: null,
  //       paragraph_title_font: null,
  //       paragraph_title_font_size: null,
  //       paragraph_title_font_style: "null",
  //       text_alignmnet: null,
  //       button_alignmnet: null,
  //       paragraph_alignmnet: null,
  //       heading_alignmnet: null,
  //       button_link: null,
  //       border: "round",
  //       text_height: null,
  //       text_width: null,
  //       heading_height: null,
  //       heading_width: null,
  //       paragraph_height: null,
  //       paragraph_width: null,
  //       heading_x: null,
  //       heading_y: null,
  //       text_x: null,
  //       text_y: null,
  //       paragraph_x: null,
  //       paragraph_y: null,
  //       cloned_from: 11,
  //       button_x: null,
  //       button_y: null,
  //       defaultCorners: "false",
  //       is_default: 0,
  //       button_font_style: "null",
  //       button_font: null,
  //       button_font_size: null,
  //       image_position: null,
  //       image_repeat: null,
  //     },
  //   ],
  // },
  10: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 1,
    h: 5,
    widgetInfo: {
      id: "set",
      name: "chat",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  11: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 3,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "notes",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  12: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 1,
    y: "set",
    w: 1,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "sermon",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  9: {
    id: 4,
    widget_id: "set",
    page_id: 2,
    x: 0,
    y: "set",
    w: 4,
    h: 9,
    widgetInfo: {
      id: "set",
      name: "watch",
    },
    widget_styles: [
      {
        id: 514,
        widget_id: "set",
        background_color: null,
        background_image: null,
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: "null",
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 2,
        width: null,
        height: null,
        button_text: null,
        widget_link: null,
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "false",
        is_default: 0,
        button_font_style: "null",
        button_font: null,
        button_font_size: null,
        image_position: null,
        image_repeat: null,
      },
    ],
  },
  13: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving-donate",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        // background_color: "#893838",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Giving",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "GIVE NOW",
        widget_link: "giving link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  14: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving-goal",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "Giving",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  15: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 4,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "annual-budget",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Annual Budget",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  16: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 4,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "budgeting-resources",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Annual Budget",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  17: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving-statement",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "View Giving Statment",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  18: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "have-given",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "I have given",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  19: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 4,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "giving-graph",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "graph",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 3,
        width: "",
        height: null,
        button_text: "",
        widget_link: "giving goal link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  20: {
    id: 4,
    widget_id: "set",
    page_id: 4,
    x: 1,
    y: "set",
    w: 4,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "group-list",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "Group Name",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "Group Description",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 4,
        width: "",
        height: null,
        button_text: "",
        widget_link: "group list link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  27: {
    id: 4,
    widget_id: "set",
    page_id: 8,
    x: 1,
    y: "set",
    w: 2,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "guest-giving",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        // background_color: "#893838",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 8,
        width: "",
        height: null,
        button_text: "GIVE NOW",
        widget_link: "giving link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 3,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
};

export const teamDefaultStyles = {
  21: {
    id: 4,
    widget_id: "set",
    page_id: 5,
    x: 1,
    y: "set",
    w: 3,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "songs",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "title",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 5,
        width: "",
        height: null,
        button_text: "",
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "",
        image_repeat: "",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  22: {
    id: 4,
    widget_id: "set",
    page_id: 5,
    x: 1,
    y: "set",
    w: 6,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "calendar",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 5,
        width: "",
        height: null,
        button_text: "",
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "",
        image_repeat: "",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  23: {
    id: 4,
    widget_id: "set",
    page_id: 5,
    x: 1,
    y: "set",
    w: 6,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "team-details",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 5,
        width: "",
        height: null,
        button_text: "",
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "",
        image_repeat: "",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  24: {
    id: 4,
    widget_id: "set",
    page_id: 5,
    x: 1,
    y: "set",
    w: 3,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "team-chat",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 5,
        width: "",
        height: null,
        button_text: "",
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "",
        image_repeat: "",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  25: {
    id: 4,
    widget_id: "set",
    page_id: 5,
    x: 1,
    y: "set",
    w: 2,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "team-member",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 5,
        width: "",
        height: null,
        button_text: "",
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "",
        image_repeat: "",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
};

export const serveDefaultStyles = {
  1: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 6,
    h: 4,
    widgetInfo: {
      id: "set",
      name: "player",
    },
  },
  2: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 3,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "connect",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#1f2738",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#8C2626",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "20",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#778FBE",
        button_text_color: "#b33333",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "SEND",
        widget_link: "connect link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: "null",
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: "null",
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 2,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  3: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 3,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "giving",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        // background_color: "#893838",
        background_color: "linear-gradient(61deg,#f8acac 0,#fc4f4f 100%)",
        background_image: null,
        corners: null,
        title: "Connect",
        title_color: "#ffffff",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: "GIVE NOW",
        widget_link: "giving link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 3,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  4: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 3,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "map",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: null,
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 4,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  5: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 3,
    h: 3,
    widgetInfo: {
      id: "set",
      name: "serve",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#f57b4f",
        background_image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iaGVpZ2h0OiA1MTJweDsgd2lkdGg6IDUxMnB4OyI+PHBhdGggZD0iTTAgMGg1MTJ2NTEySDB6IiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9IjEiPjwvcGF0aD48ZyBjbGFzcz0iI",
        corners: null,
        title: "SERVE",
        title_color: null,
        title_font: null,
        title_font_style: null,
        title_font_size: null,
        description: " Dont Miss Your Next Redefine Group Meeting!",
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "serve link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 5,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  6: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 3,
    h: 6,
    widgetInfo: {
      id: "set",
      name: "ourcode",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "",
        background_image: "",
        corners: null,
        title: "Our Code",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "our code link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 100,
        text_width: 160,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 6,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  7: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 6,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "groups",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#b694e0",
        background_image: null,
        corners: null,
        title: "Join a Redefine Group",
        title_color: "#25bc36",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: null,
        description_color: null,
        description_font_style: null,
        description_font_size: null,
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "groups link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: 60,
        text_width: 560,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 7,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  8: {
    id: 4,
    widget_id: "set",
    page_id: 1,
    x: 1,
    y: "set",
    w: 6,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "eventList",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#8f5d2c",
        background_image: null,
        corners: null,
        title: null,
        title_color: "#34268c",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":true,"isUnderlined":1,"isItalic":1,"isDefaultFont":1}',
        title_font_size: "30",
        description: null,
        description_font: "frontageregular",
        description_color: "#cba1a1",
        description_font_style:
          '{"isBold":false,"isUnderlined":false,"isItalic":1,"isDefaultFont":1}',
        description_font_size: "10",
        button_background_color: null,
        button_text_color: null,
        is_custom: 0,
        page_id: 1,
        width: "",
        height: null,
        button_text: null,
        widget_link: "eventlist link",
        date_font: "frontageregular",
        date_font_color: "#39c688",
        date_font_style:
          '{"isBold":0,"isUnderlined":0,"isItalic":1,"isDefaultFont":1}',
        date_font_size: "10",
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: null,
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 8,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
  26: {
    id: 4,
    widget_id: "set",
    page_id: 4,
    x: 1,
    y: "set",
    w: 2,
    h: 2,
    widgetInfo: {
      id: "set",
      name: "team-list",
    },
    widget_styles: [
      {
        id: 9,
        widget_id: "set",
        background_color: "#fff",
        background_image: null,
        corners: null,
        title: "Group Name",
        title_color: "#000",
        title_font: "frontageregular",
        title_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        title_font_size: "22",
        description: "Group Description",
        description_font: "frontageregular",
        description_color: "#000",
        description_font_style:
          '{"isBold":false,"isUnderlined":0,"isItalic":0,"isDefaultFont":1}',
        description_font_size: "18",
        button_background_color: "#ffffff",
        button_text_color: "#fb5656",
        is_custom: 0,
        page_id: 6,
        width: "",
        height: null,
        button_text: "",
        widget_link: "group list link",
        date_font: null,
        date_font_color: null,
        date_font_style: null,
        date_font_size: null,
        heading_title: null,
        heading_title_color: null,
        heading_title_font: null,
        heading_title_font_size: null,
        heading_title_font_style: null,
        paragraph_title: null,
        paragraph_title_color: null,
        paragraph_title_font: null,
        paragraph_title_font_size: null,
        paragraph_title_font_style: null,
        text_alignmnet: null,
        button_alignmnet: null,
        paragraph_alignmnet: null,
        heading_alignmnet: null,
        button_link: "button link",
        border: "round",
        text_height: null,
        text_width: null,
        heading_height: null,
        heading_width: null,
        paragraph_height: null,
        paragraph_width: null,
        heading_x: null,
        heading_y: null,
        text_x: null,
        text_y: null,
        paragraph_x: null,
        paragraph_y: null,
        cloned_from: 11,
        button_x: null,
        button_y: null,
        defaultCorners: "true",
        is_default: 0,
        image_position: "100% 100%",
        image_repeat: "repeat",
        button_font_style: null,
        button_font_size: null,
        button_font: null,
      },
    ],
  },
};
