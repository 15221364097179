import React, { useState, useRef } from "react";
import "./styles.css";
import Assets from "../../assets/images";
import useOutsideClick from "../../utills/outerClick";
import { ColorPicker } from "react-color-gradient-picker";
import "react-color-gradient-picker/dist/index.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MapPinned, Video } from "lucide-react";

const WidgetTool = ({
  setColor,
  color,
  showFileBox,
  fileBox,
  styles,
  imageDisable,
  link,
  setLink,
  linkStyles,
  pickerStyles,
  isDefault,
  setDefault,
  imagePosition,
  setImagePosition,
  colorFromPicker,
  setColorFromPicker,
  isColumnOrRow,
  editMap,
  mapModalVisible,
  setMapModalVisible,
  //
  setPickerColors,
  pickerColors,
  showVideoIcon = false,
  showVideoBox,
  videoBox,
  setBgVideoLocalFile,
  setBgVideoFile,
}) => {
  const [picker, setPicker] = useState(false);
  const [showInput, setInput] = useState(false);
  const ref = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorPickerRef = useRef(null);
  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
  });
  const handleImagePosition = (e) => {
    setImagePosition(e.target.value);
  };

  const [imagePicker, setImagePicker] = useState(false);

  const [colorType, setColorType] = useState("solid");

  const gradient = {
    points: [
      {
        left: 0,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
      },
      {
        left: 100,
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1,
      },
    ],
    degree: 0,
    type: "linear",
    style:
      "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
  };

  const defaultSolidColor = {
    red: 255,
    green: 0,
    blue: 0,
    alpha: 1,
  };

  const [miniToolbar, setMiniToolbar] = useState(false);

  return (
    <div
      style={{ ...styles, top: miniToolbar ? -75 : -100 }}
      className="mainToolBar"
    >
      <div className="widgetToolbarInner">
        <div className="toolBarLeft">
          {!showInput ? (
            <h4>widget TOOLBAR</h4>
          ) : (
            <input
              style={linkStyles}
              placeholder="enter  link"
              className="btnInput"
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          )}
        </div>
        <div className="toolBarRight">
          <div
            onClick={() => setInput(!showInput)}
            className="linkIcon"
            style={{
              backgroundColor: showInput ? "dimgrey" : "",
              borderRadius: 4,
            }}
          >
            <img src={Assets.link} alt="Edit Image" />
          </div>
          {miniToolbar && (
            <div
              className="d-flex align-items-center"
              style={{
                position: "absolute",
                transform: !isColumnOrRow
                  ? "translate(-50%, -50%)"
                  : "translate(-200%, -50%)",
                top: -20,
                backgroundColor: "#383939",
                padding: 4,
                borderRadius: 6,
              }}
            >
              {!isColumnOrRow && (
                <div className="fontList">
                  <div style={{ cursor: "pointer" }} className="fillselect">
                    <p
                      onClick={() => setDefault(Number(1))}
                      style={{
                        color: "#ffffff",
                        fontSize: 13,
                        padding: 0,
                        margin: 0,
                        marginTop: 3,
                        paddingInline: 8,
                        borderRadius: 4,
                        backgroundColor:
                          isDefault == 1 ? "gray" : "transparent",
                      }}
                    >
                      Default
                    </p>
                  </div>
                </div>
              )}
              <div className="fontList">
                <div style={{ cursor: "pointer" }} className="fillselect">
                  <p
                    onClick={handleClick}
                    style={{
                      color: "#ffffff",
                      fontSize: 13,
                      padding: 0,
                      margin: 0,
                      marginTop: 3,
                      paddingInline: 8,
                      borderRadius: 4,
                      backgroundColor: isDefault == 0 ? "gray" : "transparent",
                    }}
                  >
                    Custom
                  </p>
                </div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  selected={colorType === "solid" ? true : false}
                  onClick={() => {
                    setDefault(0);
                    setPicker(true);
                    setColorType("solid");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "solid" ? "dimgrey" : "transparent",
                  }}
                >
                  solid
                </MenuItem>
                <MenuItem
                  selected={colorType === "linear" ? true : false}
                  onClick={() => {
                    setDefault(0);
                    setPicker(true);
                    setColorType("linear");
                    handleClose();
                  }}
                  style={{
                    color: "#ffffff",
                    // backgroundColor:
                    //   colorType === "linear" ? "dimgrey" : "transparent",
                  }}
                >
                  gradient
                </MenuItem>
              </Menu>
            </div>
          )}
          <div
            onClick={() => {
              // setPicker(true);
              setMiniToolbar(!miniToolbar);
            }}
            className="bucketIcon"
          >
            <img src={Assets.paintbucket1} alt="Edit Image" />
          </div>
          {/* Video */}
          {showVideoIcon && (
            <div
              style={{
                backgroundColor: videoBox ? "dimgrey" : "",
                borderRadius: 4,
                padding: 4,
              }}
              onClick={() => {
                showVideoBox(!videoBox);
              }}
              className="imageIcon"
            >
              <Video size={30} color={!videoBox ? "#7f7f7f" : "#000"} />
            </div>
          )}
          {/*  */}
          {!imageDisable && (
            <>
              <div
                style={{
                  backgroundColor: fileBox ? "dimgrey" : "",
                  borderRadius: 4,
                  padding: 4,
                }}
                onClick={() => {
                  // setImagePicker(!imagePicker);
                  showFileBox(!fileBox);
                }}
                className="imageIcon"
              >
                <img src={Assets.image} alt="Edit Image" />
              </div>
              <div className="fontList">
                <div className="fillselect">
                  <select
                    style={{ minWidth: 80 }}
                    value={imagePosition}
                    onChange={handleImagePosition}
                  >
                    <option value="cover">stretch-fill</option>
                    {/* <option value="contain">stretch-fit</option> */}
                    <option value="100% 100%">stretch-fit</option>
                  </select>
                </div>
              </div>
              {editMap && (
                <div
                  style={{
                    backgroundColor: mapModalVisible ? "dimgrey" : "",
                    borderRadius: 4,
                    padding: 4,
                  }}
                  onClick={() => {
                    setMapModalVisible(!mapModalVisible);
                  }}
                  className="imageIcon"
                >
                  <MapPinned size={24} color="#fff" style={{ opacity: 0.5 }} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {picker && (
        <div ref={colorPickerRef}>
          <ColorPicker
            onStartChange={(e) => {
              setColorFromPicker && setColorFromPicker(true);
              setColor(e);
              isDefault == 1 && setDefault(0);
            }}
            onChange={(e) => {
              setColorFromPicker && setColorFromPicker(true);
              setColor(e);
              isDefault == 1 && setDefault(0);
            }}
            onEndChange={(e) => {
              setColorFromPicker && setColorFromPicker(false);
              setColor(e);
              isDefault == 1 && setDefault(0);
              if (!setPickerColors) return;
              if (colorType === "linear") {
                setPickerColors((prev) => {
                  return { ...prev, gradient: e };
                });
              } else {
                setPickerColors((prev) => {
                  return { ...prev, color: e };
                });
              }
              setBgVideoLocalFile &&
                setBgVideoLocalFile({ base64: "no_video", url: null });
              setBgVideoFile && setBgVideoFile(null);
            }}
            gradient={(pickerColors && pickerColors.gradient) || gradient}
            color={(pickerColors && pickerColors.color) || defaultSolidColor}
            isGradient={colorType === "linear" ? true : false}
          />
        </div>
      )}
    </div>
  );
};

export default WidgetTool;
