import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/home/Home";
import Watch from "./pages/watch/Watch";
import Login from "./pages/login/Login";

import { getStyling } from "./store/slices/bodyStyling";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "./components/Topbar";
import Sidebar from "./components/sidebar/Sidebar";

import { Routes, Route, useLocation } from "react-router-dom";
import { DarkModeContext } from "./context/DarkModeContext";

function App() {
  const { darkMode, handleLightMode, handleDarkMode, borderCorners } =
    useContext(DarkModeContext);

  const user = useSelector((state) => state.login.userData);
  const path = useLocation();
  const token = localStorage.getItem("accessToken");

  return path.pathname !== "/" && token ? (
    // token ? (
    <div>
      <Topbar />
      <div className="mainWrapper">
        <Sidebar
          handleLightMode={handleLightMode}
          handleDarkMode={handleDarkMode}
          themeMode={darkMode}
          borderCorners={borderCorners}
        />
        <Routes>
          <Route path="/home" element={<Home darkMode={darkMode} />} />
          <Route path="/watch" element={<Watch darkMode={darkMode} />} />
        </Routes>
      </div>
    </div>
  ) : (
    <div>
      <Routes>
        <Route path="/" element={<Login darkMode={darkMode} />} />
      </Routes>
    </div>
  );
}

export default App;
