import logo from "./logo.svg";
import backarrow from "./backarrow.svg";
import laptop from "./laptop.svg";
import smartphone from "./smartphone.svg";
import undoico from "./undoico.svg";
import darktheme from "./darktheme.svg";
import lighttheme from "./lighttheme.svg";
import dropdownarrow from "./dropdownarrow.svg";
import playimg from "./playimg.png";
import deletebtn from "./deletebtn.svg";
import editicon from "./editicon.svg";
import orangeImage from "./orangeImage.svg";
import image from "./image.svg";
import link from "./link.svg";
import paintbucket1 from "./paint_bucket_1.svg";
import boldbutton from "./bold-button.svg";
import italicbutton from "./italicbutton.svg";
import uploadplaceholder from "./uploadplaceholder.svg";
import Tbutton from "./Tbutton.svg";
import editText from "./editText4.png";
import dragIcon from "./move.png";
import defaultIcon from "./default.png";
import placeholder2 from "./placeholder2.png";
import sermon1 from "./sermon1.jpg";
import sermon2 from "./sermon2.jpg";
import sermon3 from "./sermon3.jpg";
import sermon4 from "./sermon4.jpg";
import sermon5 from "./sermon5.jpg";
import sermon6 from "./sermon6.png";
// widget icons for white theme
import ColumnIcon from "../icons/whiteTheme/Column.png";
import RowIcon from "../icons/whiteTheme/Row.png";
import ConnectIcon from "../icons/whiteTheme/Connect.png";
import CustomIcon from "../icons/whiteTheme/Custom.png";
import EventIcon from "../icons/whiteTheme/EventList.png";
import GivingIcon from "../icons/whiteTheme/Giving.png";
import MapIcon from "../icons/whiteTheme/Map.png";
import OurCodeIcon from "../icons/whiteTheme/OurCode.png";
import PlayerIcon from "../icons/whiteTheme/Player.png";
import ServeIcon from "../icons/whiteTheme/Serve.png";
import GroupIcon from "../icons/whiteTheme/Groups.png";
//
import BlackColumnIcon from "../icons/blackTheme/Column.png";
import BlackRowIcon from "../icons/blackTheme/Row.png";
import BlackConnectIcon from "../icons/blackTheme/Connect.png";
import BlackCustomIcon from "../icons/blackTheme/Custom.png";
import BlackEventIcon from "../icons/blackTheme/EventList.png";
import BlackGivingIcon from "../icons/blackTheme/Giving.png";
import BlackMapIcon from "../icons/blackTheme/Map.png";
import BlackOurCodeIcon from "../icons/blackTheme/OurCode.png";
import BlackPlayerIcon from "../icons/blackTheme/Player.png";
import BlackServeIcon from "../icons/blackTheme/Serve.png";
import BlackGroupIcon from "../icons/blackTheme/Groups.png";

import DollarIcon from "../images/dollar.png";
import GroupBanner from "../images/group-banner.png";
import ProfilePlaceholder from "../images/placeholderimage.png";
import Email from "../images/email.svg";
import Chat from "../images/chat.svg";
import Calender from "../images/calender.png";
import Event1 from "../images/event-1.png";
import Speaker from "../images/speaker.png";

const Assets = {
  Speaker,
  Event1,
  Calender,
  Chat,
  Email,
  ProfilePlaceholder,
  GroupBanner,
  DollarIcon,
  BlackColumnIcon,
  BlackConnectIcon,
  BlackCustomIcon,
  BlackEventIcon,
  BlackGroupIcon,
  BlackPlayerIcon,
  BlackServeIcon,
  BlackRowIcon,
  BlackOurCodeIcon,
  BlackMapIcon,
  BlackGivingIcon,
  GroupIcon,
  ColumnIcon,
  RowIcon,
  ConnectIcon,
  GivingIcon,
  ServeIcon,
  PlayerIcon,
  MapIcon,
  EventIcon,
  CustomIcon,
  OurCodeIcon,
  sermon1,
  sermon2,
  sermon3,
  sermon4,
  sermon5,
  sermon6,
  placeholder2,
  logo,
  backarrow,
  laptop,
  smartphone,
  undoico,
  darktheme,
  lighttheme,
  dropdownarrow,
  playimg,
  editicon,
  deletebtn,
  orangeImage,
  image,
  link,
  paintbucket1,
  boldbutton,
  uploadplaceholder,
  italicbutton,
  Tbutton,
  editText,
  dragIcon,
  defaultIcon,
};
export default Assets;
